import React, { useEffect, useMemo, useState } from 'react'
import { Stack, Typography, Tooltip, Box } from '@mui/material'
import { ButtonTertiary, CardWithColorAndIcon, LoadingSkeleton } from 'components-new'
import CreditOverrideForm from './widgets/CreditOverrideForm'
import { CreditPolicyModels } from '../CreditPolicy/EnterpriseCreditPolicy.model'
import { CreditOverrideCompanyData } from './widgets/CreditOverrideForm/CreditOverrideForm.types'
import {
  getOverride,
  getPendingOrUnderReviewOverride,
} from './widgets/CreditOverrideForm/airtableCreditOverrideRequests'
import styles from '../CreditPolicy/EnterpriseCreditPolicy.styles'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import { EnterpriseEvaluation } from 'services/api/endpoints/risikaAPI/evaluationPolicy'
import { useUserSettingsField } from 'services/queries'
import { CurrencyObj } from 'services/types'
import { AvailableCurrencies } from 'globalTypes'
import CreditOverrideFormGeneral from './widgets/CreditOverrideForm/CreditOverrideFormGeneral'
import CreditOverrideSkeleton from './CreditOverride.skeleton'
import { WidgetWrapper } from '../WidgetWrapper/WidgetWrapper'

const RequestOverrideButton = ({
  isDisabled,
  isNoPolicyActive,
  handleOpenForm,
}: {
  isDisabled: boolean
  isNoPolicyActive: boolean
  handleOpenForm: () => void
}) => {
  if (isDisabled) {
    return (
      <Tooltip
        title="A request for this company is already under review. You will be able to place a new request once it is reviewed."
        placement="bottom"
      >
        <Stack width={1}>
          <ButtonTertiary data-cy="request-override-button-disabled" disabled={true}>
            <FormattedMessage id={intl.mainRecommendation('request-override')} />
          </ButtonTertiary>
        </Stack>
      </Tooltip>
    )
  } else if (isNoPolicyActive) {
    return (
      <Tooltip
        title="You need an active policy to be able to place a new request"
        placement="bottom"
      >
        <Stack width={1}>
          <ButtonTertiary data-cy="request-override-button-disabled" disabled={true}>
            <FormattedMessage id={intl.mainRecommendation('request-override')} />
          </ButtonTertiary>
        </Stack>
      </Tooltip>
    )
  } else {
    return (
      <ButtonTertiary data-cy="request-override-button" onClick={handleOpenForm}>
        <FormattedMessage id={intl.mainRecommendation('request-override')} />
      </ButtonTertiary>
    )
  }
}

export type CreditOverrideProps = {
  widgetNameDND: string
  isReArrange: boolean
  companyBasicsData: CreditOverrideCompanyData
  evaluation: EnterpriseEvaluation
  risikaRecommendation: string
  risikaScore: string
  userName: string
  userEmail: string
  companyId: number
  currency: AvailableCurrencies
  isLoading?: boolean
  isActive?: boolean
  setIsActive?: (key: string) => void
}

const CreditOverride = ({
  widgetNameDND,
  isReArrange,
  companyBasicsData,
  evaluation,
  risikaRecommendation,
  risikaScore,
  userName,
  companyId,
  userEmail,
  currency,
  isLoading,
  isActive = true,
  setIsActive,
}: CreditOverrideProps) => {
  const [open, setOpen] = useState(false)
  const [overrideData, setOverrideData] = useState<any>(null)
  const [isDisabled, setIsDisabled] = useState(false)
  const reactIntl = useIntl()
  const policyId = evaluation?.details?.policy_id
  const {
    data: selectedCurrency,
    isLoading: isCurrencyLoading,
    isSuccess: isCurrencyFetched,
  } = useUserSettingsField<CurrencyObj>('selected_currency')

  useEffect(() => {
    const localId = companyBasicsData?.localId
    if (!localId) return

    const getData = async () => {
      try {
        const isPendingOrUnderReview = await getPendingOrUnderReviewOverride({
          localId,
        })
        if (!selectedCurrency) return
        const policyOverrideData = await getOverride({ localId, selectedCurrency })
        setOverrideData(policyOverrideData)
        setIsDisabled(isPendingOrUnderReview)
      } catch (error) {
        console.log('Error', error)
      }
    }
    if (isCurrencyFetched && !isCurrencyLoading) {
      getData()
    }
  }, [
    companyBasicsData?.localId,
    evaluation,
    isCurrencyFetched,
    isCurrencyLoading,
    selectedCurrency,
  ])

  const creditPolicyModel = useMemo(() => {
    if (!overrideData) return null
    const explanationBasedOnOutcome = {
      'Credit approved':
        'The credit limit for this company has been changed by the Finance team.',
      'Credit rejected':
        'The credit limit for this company has been changed by the Finance team. The company can’t be offered credit.',
      'Manual check':
        'The credit limit for this company has been changed by the Finance team. This company requires additional checks. ',
    }
    const getOutcome = (overrideOutcome: string) => {
      switch (overrideOutcome) {
        case 'Credit approved':
          return {
            ...CreditPolicyModels.find((item) => item.name === 'approve'),
            explanation: explanationBasedOnOutcome[overrideOutcome],
          }
        case 'Credit rejected':
          return {
            ...CreditPolicyModels.find((item) => item.name === 'reject'),
            explanation: explanationBasedOnOutcome[overrideOutcome],
          }
        case 'Manual check':
          return {
            ...CreditPolicyModels.find((item) => item.name === 'manual_check'),
            explanation: explanationBasedOnOutcome[overrideOutcome],
          }
        default:
          return {
            ...CreditPolicyModels.find((item) => item.name === 'default'),
            explanation: '',
          }
      }
    }
    return getOutcome(overrideData.overriddenOutcome)
  }, [overrideData])
  const handleOpenForm = () => setOpen(true)
  const handleCloseForm = () => setOpen(false)

  return (
    <LoadingSkeleton SkeletonComponent={CreditOverrideSkeleton} isLoading={isLoading}>
      <WidgetWrapper
        isReArrange={isReArrange}
        widgetName={widgetNameDND}
        isActive={!!isActive}
        setIsActive={setIsActive}
      >
        <Box sx={styles.wrapper}>
          <Typography variant="h4" sx={styles.title}>
            <FormattedMessage id={intl.mainRecommendation('credit-override')} />
          </Typography>

          {creditPolicyModel && overrideData ? (
            <>
              <CardWithColorAndIcon
                icon={creditPolicyModel.icon!}
                color={creditPolicyModel.color!}
              >
                <Stack>
                  <Typography variant="subtitle1">
                    {overrideData?.overriddenOutcome}
                  </Typography>
                  <Typography variant="body2">{creditPolicyModel.explanation}</Typography>
                  {creditPolicyModel.variant === 'positive' && (
                    <Typography variant="body2" sx={styles.creditMax}>
                      Credit max:{' '}
                      <b>
                        {`${reactIntl.formatNumber(
                          Math.floor(overrideData?.overriddenCreditLimit || 0)
                        )} ${overrideData.currency}`}
                      </b>
                    </Typography>
                  )}
                </Stack>
              </CardWithColorAndIcon>
            </>
          ) : (
            <Typography variant="body2" sx={styles.noOverrideText}>
              <FormattedMessage id={intl.mainRecommendation('no-override-available')} />
            </Typography>
          )}
          <RequestOverrideButton
            isDisabled={isDisabled}
            isNoPolicyActive={evaluation ? !evaluation?.details?.policy_id : false}
            handleOpenForm={handleOpenForm}
          />
          {companyId === 5732 ? (
            <CreditOverrideForm
              open={open}
              onClose={handleCloseForm}
              companyBasicsData={companyBasicsData}
              policyOutcome={{
                outcome: evaluation?.details?.outcome_name!,
                creditLimit: evaluation?.credit_policy_limit!,
                policyId: policyId!,
              }}
              risikaScore={risikaScore}
              risikaRecommendation={risikaRecommendation}
              userName={userName}
              userEmail={userEmail}
              currency={currency}
              disableRequestButton={() => setIsDisabled(true)}
            />
          ) : (
            // @ts-expect-error - Mike, is policyOutcome prop required?
            <CreditOverrideFormGeneral
              open={open}
              onClose={handleCloseForm}
              companyBasicsData={companyBasicsData}
              risikaScore={risikaScore}
              risikaRecommendation={risikaRecommendation}
              userName={userName}
              userEmail={userEmail}
              currency={currency}
              disableRequestButton={() => setIsDisabled(true)}
            />
          )}
        </Box>
      </WidgetWrapper>
    </LoadingSkeleton>
  )
}

export default CreditOverride
