import { useState, useEffect } from 'react'
import ActionPanelSearch from '../ActionPanelSearch'
import ActionPanelRuleList from '../ActionPanelRuleList'
import { Stack } from '@mui/material'
import { ButtonTertiary } from 'components-new'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useBuilder } from '../../../../BuilderContext'
import { useHistory } from 'react-router-dom'
import {
  ActionPanelVariables,
  BuilderNode,
  PolicyBuilderParams,
} from '../../../../PolicyBuilder.types'
import { handleCreatePolicy } from '../../../../PolicyBuilder.controller'
import { postCreditPolicy } from 'services/api'
import { navigation } from 'services/navigation'
import { ActionPanelRuleListWithSearchPropsType } from '../../ActionPanel.types'
import { findVariableFromLabel } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/BuilderContext/BuilderContext.controller'
import { useBuilderParams } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.hooks'
import { APIKeyType, VariantType } from 'types/queries'

const ActionPanelRuleListWithSearch = ({
  handleClose,
}: ActionPanelRuleListWithSearchPropsType) => {
  const [searchText, setSearchText] = useState('')

  const { state, actions } = useBuilder()

  const history = useHistory()
  const params = useBuilderParams() as PolicyBuilderParams | undefined

  useEffect(() => {
    if (state.actionPanelData.open) {
      setSearchText('')
    }
  }, [state.actionPanelData.open])

  const selectedNode = state.nodes.find(
    (node) => node.id === state.actionPanelData.nodeId
  ) as BuilderNode

  const addOrEditNode = async (label: ActionPanelVariables) => {
    actions.setActionPanelContent(label)
    const variable = findVariableFromLabel(label)
    if (!variable) return
    const apiKey = variable?.template?.[0]?.template_data?.api_key
    const variant = variable?.template?.[0]?.template_data?.variant
    if (state.nodes.length === 0) {
      if (params) {
        // new empty node creation
        const nodes = [
          {
            id: '1',
            type: 'rule',
            data: {
              label,
              builderData: {
                parentId: null,
                childrenIds: [],
                variant,
              },
              apiData: {
                category: apiKey,
              },
            },
          } as unknown, // High-end TS skills
        ]
        const newPolicy = await handleCreatePolicy({
          params,
          enabled: false,
          state: {
            ...state,
            nodes: nodes as BuilderNode[], // Even Higher-end TS skills
          },
          saveFunction: postCreditPolicy,
        })
        return history.push(
          navigation.creditPolicyBuilder({
            country: params.country,
            name: params.name,
            description: params.description,
            policyId: newPolicy.policy_id,
            createActiveRule: false,
          })
        )
      }
    }
    return actions.createEmptyNode({
      nodeId: selectedNode.id,
      nodeData: { label, variant: variant as VariantType, apiKey: apiKey as APIKeyType },
    })
  }

  return (
    <Stack
      sx={{
        height: '100%',
      }}
    >
      <ActionPanelSearch text={searchText} setText={setSearchText} />
      <ActionPanelRuleList
        title=""
        onRuleSelect={addOrEditNode}
        filterText={searchText}
      />
      <Stack
        paddingX={5}
        paddingY={4}
        alignItems="flex-start"
        sx={{ backgroundColor: 'grey.50', marginTop: 'auto' }}
      >
        <ButtonTertiary
          data-cy="action-panel-cancel-button"
          onClick={() => handleClose(false)}
          sx={{ marginBottom: 0, marginTop: 'auto' }}
        >
          <FormattedMessage id={intl.creditPolicyNew('cancel')} />
        </ButtonTertiary>
      </Stack>
    </Stack>
  )
}

export default ActionPanelRuleListWithSearch
