import { useIntl } from 'react-intl'
import { useFraudIndicators } from '../../FraudIndicatorsProvider'
import intl from 'localization/components'
import SectionContainer from './SectionContainer'

const FraudIndicatorsContent = () => {
  const { selectedCountry } = useFraudIndicators()
  const reactIntl = useIntl()

  const titles = {
    address: reactIntl.formatMessage({
      id: intl.fraudIndicators('address'),
    }),
    financial: reactIntl.formatMessage({
      id: intl.fraudIndicators('financial'),
    }),
  }

  const sections = {
    denmark: [
      {
        title: titles.address,
        children: (
          <>
            {/* MULTIPLE COMPANIES FROM THE SAME ADDRESS */}
            {/* FREQUENCY OF ADDRESS CHANGES */}
          </>
        ),
      },
      {
        title: titles.financial,
        children: (
          <>
            {/* REVERSE AUDITOR BANKRUPTCY CHECK */}
            {/* PROFIT VS EMPLOYESS ABNORMALITIES */}
            {/* FINANCIAL STATEMENT ABNORMALITIES YEAR OVER */}
          </>
        ),
      },
    ],
    sweden: [
      {
        title: titles.address,
        children: (
          <>
            {/* MULTIPLE COMPANIES FROM THE SAME ADDRESS */}
            {/* FREQUENCY OF ADDRESS CHANGES */}
          </>
        ),
      },
      {
        title: titles.financial,
        children: (
          <>
            {/* REVERSE AUDITOR BANKRUPTCY CHECK */}
            {/* PROFIT VS EMPLOYESS ABNORMALITIES */}
            {/* FINANCIAL STATEMENT ABNORMALITIES YEAR OVER */}
          </>
        ),
      },
    ],
    finland: [
      {
        title: titles.address,
        children: (
          <>
            {/* MULTIPLE COMPANIES FROM THE SAME ADDRESS */}
            {/* FREQUENCY OF ADDRESS CHANGES */}
          </>
        ),
      },
      {
        title: titles.financial,
        children: (
          <>
            {/* REVERSE AUDITOR BANKRUPTCY CHECK */}
            {/* PROFIT VS EMPLOYESS ABNORMALITIES */}
            {/* FINANCIAL STATEMENT ABNORMALITIES YEAR OVER */}
          </>
        ),
      },
    ],
    norway: [
      {
        title: titles.address,
        children: (
          <>
            {/* MULTIPLE COMPANIES FROM THE SAME ADDRESS */}
            {/* FREQUENCY OF ADDRESS CHANGES */}
          </>
        ),
      },
      {
        title: titles.financial,
        children: (
          <>
            {/* REVERSE AUDITOR BANKRUPTCY CHECK */}
            {/* PROFIT VS EMPLOYESS ABNORMALITIES */}
            {/* FINANCIAL STATEMENT ABNORMALITIES YEAR OVER */}
          </>
        ),
      },
    ],
  }

  switch (selectedCountry) {
    case 'dk':
      return (
        <SectionContainer
          dataCy={'fraud-indicators-danish'}
          sections={sections.denmark}
        />
      )
    case 'se':
      return (
        <SectionContainer
          dataCy={'fraud-indicators-swedish'}
          sections={sections.sweden}
        />
      )
    case 'no':
      return (
        <SectionContainer
          dataCy={'fraud-indicators-norwegian'}
          sections={sections.norway}
        />
      )
    case 'fi':
      return (
        <SectionContainer
          dataCy={'fraud-indicators-finnish'}
          sections={sections.finland}
        />
      )
  }
}

export default FraudIndicatorsContent
