import { useState, useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import { Card, CardContent, Typography, Divider, Box, Stack } from '@mui/material'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { navigation } from 'services/navigation'
import { Hide } from 'components'
import { useDispatch } from 'react-redux'
import { authLogin, authSsoCheckEmail } from 'services/api/helpers/authService'
import { loginUserSuccess } from 'store_deprecated/auth/actions'
import { useSnackbar } from 'notistack'
import { useAuth0 } from '@auth0/auth0-react'
import { isEmail } from 'utils-new/is-email'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { Mixpanel } from 'services/helpers/mixpanel'
import { ButtonPrimary, ButtonTertiary, InputField } from 'components-new'
import { useSupportContext } from 'components-new/support-and-chat/SupportContext'

const NewUserLink = () => (
  <Stack direction="row" gap={2}>
    <Typography variant="subtitle1">
      <FormattedMessage id={intl.auth('new-user')} />
    </Typography>
    <Typography
      variant="link1"
      component={Link}
      to={{ pathname: 'https://risika.com/free-trial' }}
      target="_blank"
    >
      <FormattedMessage id={intl.auth('create-an-account')} />
    </Typography>
  </Stack>
)

function parseQueryString(queryString) {
  if (queryString != null) {
    const parts = queryString
      .replace('?', '')
      .split('&')
      .map((part) => part.split('='))

    const partObj = {}

    parts.forEach((part) => {
      const [key, value] = part
      partObj[key] = value
    })

    return partObj
  }

  return null
}

const Login = () => {
  const history = useHistory()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const {
    loginWithRedirect,
    isAuthenticated: isAuthenticatedUsingAuth0,
    getAccessTokenSilently,
  } = useAuth0()
  const { setSupportDisplay, isSupportPromptDisplay } = useSupportContext()

  useEffect(() => {
    if (isAuthenticatedUsingAuth0) {
      getAccessTokenSilently().then((accessToken) => {
        history.push(navigation.auth.sso(), { accessToken })
      })
    }
  }, [getAccessTokenSilently, history, isAuthenticatedUsingAuth0])

  useEffect(() => {
    const query = parseQueryString(search)
    if (!isAuthenticatedUsingAuth0 && query?.auth === 'sso') {
      loginWithRedirect()
    }
  }, [isAuthenticatedUsingAuth0, loginWithRedirect, search])

  if (!isSupportPromptDisplay) {
    setSupportDisplay(true)
  }

  const location = useLocation()

  const [email, setEmail] = useState(location?.state?.email ?? '')
  const [password, setPassword] = useState('')
  const [isUsingSso, setIsUsingSso] = useState(false)
  const [errorMessageEmail, setErrorMessageEmail] = useState('')
  const [errorMessagePassword, setErrorMessagePassword] = useState('')
  const [wrongCredentialsMessage, setWrongCredentialsMessage] = useState('')

  // Checks email syntax when the email is wrong
  useEffect(() => {
    if (email && errorMessageEmail) {
      if (!isEmail(email)) {
        setErrorMessageEmail(<FormattedMessage id={intl.auth('invalid-email')} />)
      } else {
        setErrorMessageEmail('')
      }
    } else {
      setErrorMessageEmail('')
    }
  }, [email, password]) // password is needed for an additional check

  useEffect(() => setErrorMessagePassword(''), [password, email])

  useEffect(() => {
    if (isEmail(email)) {
      authSsoCheckEmail(email).then(setIsUsingSso)
    } else {
      setIsUsingSso(false)
    }
  }, [email])

  const handleEmailChange = (value) => {
    setEmail(value)
  }

  const handlePasswordChange = (value) => {
    setPassword(value)
  }

  const handleLogin = (event) => {
    event.preventDefault()

    const emailLowerCase = email.toLowerCase()

    authLogin(emailLowerCase, password)
      .then((data) => {
        Mixpanel.identify(data.user.id)
        dispatch(loginUserSuccess(data))
      })
      .catch((error) => {
        if (error?.response?.data?.code === 'account_deactivated') {
          enqueueSnackbar(<FormattedMessage id={intl.auth('account-deactivated')} />, {
            variant: 'error',
          })
          history.push(navigation.auth.accountDeactivated(), { emailLowerCase })
        } else if (error?.response?.status === 404) {
          setWrongCredentialsMessage(
            <FormattedMessage id={intl.auth('wrong-email-or-password')} />
          )
          setErrorMessagePassword(<FormattedMessage id={intl.auth('invalid-password')} />)
          setErrorMessageEmail(<FormattedMessage id={intl.auth('invalid-email')} />)
        } else if (error?.response?.data?.error?.name === 'companyTrialExpired') {
          enqueueSnackbar(error?.response?.data?.error?.message, { variant: 'error' })
        } else if (error?.response?.data?.error?.name === 'companyRevoked') {
          enqueueSnackbar(error?.response?.data?.error?.message, { variant: 'error' })
        } else if (error?.name === 'QuotaExceededError') {
          // When LocalStorage limit is reached, we clear it and attempt log them in again.
          localStorage.clear()
          authLogin(emailLowerCase, password)
            .then((data) => {
              dispatch(loginUserSuccess(data))
            })
            .catch(() => {
              // no-op
            })

          Sentry.captureException(error, {
            user: {
              email: emailLowerCase,
            },
            extra: {
              local_storage_size: JSON.stringify(window?.localStorage)?.length,
            },
            tags: {
              type: 'login-issue-local-storage-cleared',
            },
          })
        } else {
          Sentry.captureException(error, {
            user: {
              email: emailLowerCase,
            },
            extra: {
              local_storage_size: JSON.stringify(window?.localStorage)?.length,
            },
            tags: {
              type: 'login-issue',
            },
          })
        }
      })
  }

  const handlePasswordClick = () => {
    if (email) {
      if (!isEmail(email)) {
        setErrorMessageEmail(<FormattedMessage id={intl.auth('invalid-email')} />)
      } else {
        setErrorMessageEmail('')
      }
    } else {
      setErrorMessageEmail('')
    }
  }

  return (
    <Stack py={7} px={6} width={400} borderRadius={1} bgcolor="common.white">
      {location?.state?.sessionExpired ? (
        <Card elevation={0}>
          <CardContent>
            <Typography variant="h6">
              <FormattedMessage id={intl.auth('session-expired')} />
            </Typography>
          </CardContent>
        </Card>
      ) : null}
      <Stack gap={4}>
        <Typography variant="h3">
          <FormattedMessage id={intl.auth('login-header')} />
        </Typography>
        <NewUserLink />
      </Stack>

      {wrongCredentialsMessage && (
        <Box
          py={2}
          px={4}
          mt={4}
          sx={{
            backgroundColor: 'error.100',
            border: '1px solid',
            borderColor: 'error.500',
            borderRadius: 1,
          }}
        >
          <Typography variant="body2" sx={{ color: 'error.700' }}>
            {wrongCredentialsMessage}
          </Typography>
        </Box>
      )}

      <Stack component="form" onSubmit={handleLogin} mt={wrongCredentialsMessage ? 5 : 7}>
        <InputField
          id="email"
          data-cy="email"
          type="email"
          autoFocus
          value={email}
          handleChange={handleEmailChange}
          label={<FormattedMessage id={intl.auth('email-label')} />}
          error={!!errorMessageEmail}
          errorMessage={errorMessageEmail}
        />
        <Hide when={isUsingSso}>
          <Stack gap={2} mt={5} mb={6}>
            <InputField
              id="password"
              data-cy="password"
              value={password}
              handleChange={handlePasswordChange}
              type="password"
              label={<FormattedMessage id={intl.auth('password-label')} />}
              error={!!errorMessagePassword}
              errorMessage={errorMessagePassword}
              clickIn={handlePasswordClick}
            />
            <Box>
              <Typography
                component={Link}
                to={navigation.auth.forgotPassword()}
                variant="link2"
              >
                <FormattedMessage id={intl.auth('forgot-password')} />
              </Typography>
            </Box>
          </Stack>
          <ButtonPrimary type="submit" data-cy="login-button">
            <FormattedMessage id={intl.auth('log-in')} />
          </ButtonPrimary>
        </Hide>
      </Stack>

      <Hide when={isUsingSso}>
        {/* Initial height of this container is 24px. If you want to move the text up give it a few pixels more in height. */}
        <Stack direction="row" my={5} alignItems="center" gap={1}>
          <Box width={1}>
            <Divider
              sx={{ backgroundColor: (theme) => `${theme.palette.grey[500]} !important` }}
            />
          </Box>
          <Typography sx={{ color: 'grey.600', alignSelf: 'start' }} variant="subtitle2">
            Or
          </Typography>
          <Box width={1}>
            <Divider
              sx={{ backgroundColor: (theme) => `${theme.palette.grey[500]} !important` }}
            />
          </Box>
        </Stack>
      </Hide>
      <ButtonTertiary
        // startIcon={<FontAwesomeIcon icon={faMicrosoft} />}
        onClick={loginWithRedirect}
        sx={{ color: 'grey.800' }}
      >
        <FormattedMessage id={intl.auth('sso-login')} />
      </ButtonTertiary>
    </Stack>
  )
}

export default Login
