import { useQuery } from 'react-query'
import { paginatedMonitoringList } from 'services/api'
import _ from 'lodash'
import { SortBy } from 'pages/risk-monitoring/widgets/CreditAutomatorMonitoringTable/context/CreditAutomatorTableContext.types'

export const MOINTORING_LIST = 'paginatedMonitoringLists'

type Parameters = {
  sort_by: SortBy | null
  filter_on: any
  page?: number
  limit?: number
  dates: {
    from: string
    to: string
  }
}

export function usePaginatedMonitoringList(
  listId: number,
  parameters: Parameters,
  pageNumber: number
) {
  return useQuery(
    [MOINTORING_LIST, listId, pageNumber, parameters],
    () => paginatedMonitoringList(listId, parameters),
    {
      enabled: !_.isEmpty(parameters.filter_on),
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )
}
