import { useState, useEffect, ReactNode } from 'react'
import { classes, getHeaderCellBorder } from './Table.styles'
import {
  HandleCheckRowFunction,
  SelectedType,
  TableColumn,
  TableRowData,
} from './Table.types'
import { shallowCloneArray } from 'utils-new/general'
import {
  Stack,
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material'
import TableBodyCell from './elements/TableBodyCell'
import TableHeaderCell from './elements/TableHeaderCell'
import TablePaginationToolbar from './elements/TablePaginationToolbar'
import ExpandableRow from './elements/ExpandableRow/ExpandableRow'
import { TableProps } from './Table.types'
import Changes from './elements/Changes'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useUpdateUserSettingsField, useUserSettingsField } from 'services/queries'
import { reorderHeaderCells } from './Table.controller'
import { MonitoringTableEcpOrderType } from 'services/types/backendAPI'
import { useCreditAutomatorTableContext } from 'pages/risk-monitoring/widgets/CreditAutomatorMonitoringTable/context'
import { usePrevious } from 'services/hooks/usePrevious'

// This is not a reusable table. We need to either refactor it or move it to the automator folder.
const Table = ({
  tableData,
  entireData,
  formatData,
  columnsDefinition,
  page,
  perPageLimit,
  setPerPageLimit,
  setSortBy,
  setPage,
  getSelected,
  refetch = () => {},
  expandable = false,
  isTableLoading = false,
  enableReorder = false,
  listId,
}: TableProps) => {
  const [selected, setSelected] = useState<SelectedType[]>([])
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false)
  const { state, actions } = useCreditAutomatorTableContext()

  const [headerCells, setHeaderCells] = useState<TableColumn[]>(
    columnsDefinition.filter((item) => item.key !== 'id')
  )

  const prevListId = usePrevious({ listId })

  useEffect(() => {
    if (tableData.page_count !== 1) {
      actions.updatePageCount(tableData.page_count)
    }
    if (prevListId?.listId !== listId) {
      actions.updatePageCount(tableData.page_count)
    }
  }, [tableData.page_count, listId, prevListId?.listId])

  const { data } = useUserSettingsField<MonitoringTableEcpOrderType>(
    'monitoring_table_ecp_order'
  )
  const { mutate: updateUserSettingsField } =
    useUpdateUserSettingsField<MonitoringTableEcpOrderType>({})

  useEffect(() => {
    if (!enableReorder) return
    if (data?.length && !isTableLoading) {
      const newState = data
        ?.map((item) => item.key)
        .map((key) => columnsDefinition.find((cell) => cell.key === key))
      setHeaderCells(newState as TableColumn[])
    }
  }, [columnsDefinition, data, isTableLoading])

  useEffect(() => getSelected(selected), [selected])

  useEffect(() => {
    if (!selected.length) setIsSelectedAll(false)
  }, [selected])

  useEffect(() => {
    setSelected([])
    setIsSelectedAll(false)
  }, [tableData])

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return // Column dropped outside the list

    const newColumnOrder = reorderHeaderCells(
      headerCells,
      result.source.index,
      result.destination.index
    )

    const data = newColumnOrder.map((cell) => ({
      key: cell.key,
      visible: true,
    })) as MonitoringTableEcpOrderType

    setHeaderCells(newColumnOrder)
    updateUserSettingsField({ field: 'monitoring_table_ecp_order', data })
  }

  const isIndeterminate: boolean = Boolean(
    selected.length && selected.length < tableData.data.length
  )

  const handlePage = (nextPage: number) => {
    if (setPage) {
      setPage(nextPage)
    }
  }

  const handlePageLimit = (newLimit: number) => {
    setPerPageLimit?.(newLimit)
  }

  const handleSortBy = (rawField: string, direction: string) => {
    const fieldColumn = columnsDefinition.filter((item) => item.key === rawField)
    const field = fieldColumn[0].sub_key
      ? `${fieldColumn[0].sub_key}.${fieldColumn[0].key}`
      : fieldColumn[0].key
    setPage?.(1)

    setSortBy?.({ field, direction })
  }

  const handleCheck: HandleCheckRowFunction = (id, country, original) => {
    const isIdAlreadyThere = selected.findIndex((x) => x.id === id)
    const newSelected = shallowCloneArray(selected)
    if (isIdAlreadyThere === -1) {
      newSelected.push({ id, country, original })
    } else {
      newSelected.splice(isIdAlreadyThere, 1)
    }
    setSelected(newSelected)
  }

  const toggleAllCheckboxes = (checkAll: boolean) => {
    if (checkAll) {
      const companiesOnCurrentPage = entireData.data.map((company) => {
        return {
          id: company?.local_organization_id?.id,
          country: company?.local_organization_id?.country,
          original: company,
        }
      })
      setSelected(companiesOnCurrentPage)
    } else {
      setSelected([])
    }
  }
  const TableRowWrapper = (props: {
    children: ReactNode
    content: ReactNode
    colSpan: number
    dataCy: string
  }) => {
    const { content, colSpan, dataCy, ...tableRowProps } = props
    return expandable ? (
      <ExpandableRow {...props} />
    ) : (
      <TableRow data-cy={dataCy} {...tableRowProps} />
    )
  }

  return (
    <Paper component={Stack} elevation={1}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="columns" direction="horizontal">
          {(provided) => (
            <TableContainer elevation={0} sx={{ paddingX: 2 }} component={Paper}>
              <MuiTable aria-label="collapsible table">
                <TableHead ref={provided.innerRef} {...provided.droppableProps}>
                  <TableRow sx={classes.table.tableRow}>
                    {expandable && <TableCell sx={classes.table.headerCell} />}
                    {tableData.data.length > 0 &&
                      headerCells.map((cell: TableColumn, i, arr) => (
                        <Draggable
                          isDragDisabled={!enableReorder || cell.key === 'checkbox'}
                          key={cell.key}
                          draggableId={cell.key}
                          index={i}
                        >
                          {(provided) => (
                            <TableCell
                              sx={{
                                flexGrow: cell.width,
                                borderRight: getHeaderCellBorder(i, arr.length),
                              }}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <TableHeaderCell
                                headCell={cell}
                                handleSortBy={handleSortBy}
                                toggleAllCheckboxes={toggleAllCheckboxes}
                                isIndeterminate={isIndeterminate}
                                isSelectedAll={isSelectedAll}
                                setIsSelectedAll={setIsSelectedAll}
                              />
                            </TableCell>
                          )}
                        </Draggable>
                      ))}
                  </TableRow>
                </TableHead>
                {tableData.data.length > 0 && (
                  <TableBody>
                    {formatData(tableData.data, columnsDefinition).map(
                      (item: TableRowData) => (
                        <TableRowWrapper
                          dataCy={`table-row-${item.id}`}
                          key={item.company_name}
                          colSpan={columnsDefinition.length}
                          content={
                            <Changes
                              changes={item.changes}
                              localId={{ id: item.id, country: item.country }}
                            />
                          }
                        >
                          {headerCells.map((cell) => (
                            <TableCell sx={classes.table.tableCell} key={cell.key}>
                              <TableBodyCell
                                key={cell.key}
                                headCell={cell}
                                item={item}
                                handleCheck={handleCheck}
                                selected={selected}
                                refetch={refetch}
                              />
                            </TableCell>
                          ))}
                        </TableRowWrapper>
                      )
                    )}
                  </TableBody>
                )}
              </MuiTable>
            </TableContainer>
          )}
        </Droppable>
      </DragDropContext>
      <TablePaginationToolbar
        page={page ?? 1}
        totalPages={state.pageCount || 1}
        handlePage={handlePage}
        perPageLimit={perPageLimit ?? 25}
        handlePageLimit={handlePageLimit}
      />
    </Paper>
  )
}

export default Table
