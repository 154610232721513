import {
  COMPANY_LIST,
  NEW_MONITORING_LIST,
  TOLERANCE_INPUT,
} from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.model'
import intl from '../components'

const message = {
  [intl.advancedFilters('above')]: 'Över',
  [intl.advancedFilters('below')]: 'Nedan',
  [intl.advancedFilters('company-type')]: 'Affärs Typ',
  [intl.advancedFilters('count')]:
    '{count plural =0 {Inga företag hittades} one {{count} företag hittades} other {{count} företag hittades}}',
  [intl.advancedFilters('employee-count')]: 'Antal anställda',
  [intl.advancedFilters('equity')]: 'Rättvisa',
  [intl.advancedFilters('financial-data')]: 'Finansiella data',
  [intl.advancedFilters('hide-ad-protected')]: 'Ta bort annonsskyddad',
  [intl.advancedFilters('industry-group')]: 'Industrigrupp',
  [intl.advancedFilters('industry-specific')]: 'Specifik branschgrupp',
  [intl.advancedFilters('municipality')]: 'Kommun',
  [intl.advancedFilters('region')]: 'Regionen',
  [intl.advancedFilters('reset-search')]: 'Återställ sökning',
  [intl.advancedFilters('risika-score')]: 'Risika poäng',
  [intl.advancedFilters('search-company-type')]: 'Sök efter företagstyp',
  [intl.advancedFilters('search-industry-group')]: 'Sök efter branschgrupp',
  [intl.advancedFilters('search-industry-specific')]: 'Sök efter specifik branschgrupp',
  [intl.advancedFilters('search-municipality')]: 'Sök på kommun',
  [intl.advancedFilters('search-region')]: 'Sök efter region',
  [intl.advancedFilters('select-columns')]: 'Välj kolumner att exportera',
  [intl.advancedFilters('show-inactive')]: 'Visa inaktiv',
  [intl.advancedFilters('title')]: 'Filtrering',
  [intl.advancedFilters('illegal-character')]:
    '{count, plural, one {Ugyldigt tegn} other {Ugyldige tegn}} "{char}"! Tillåtna tecken är "0-9", "-" och ","',
  [intl.advancedFilters('advanced-zipcodes-helper-text')]:
    'Tillåtet format: 1000, 2000-2500',
  [intl.advancedFilters('values-or-ranges')]: 'Skriv enstaka värden eller intervall',
  [intl.advancedFilters('show-all')]:
    '{count, plural, one {Göm {count} industri} other {Göm {count} branscher}}',
  [intl.advancedFilters('hide-all')]:
    '{count, plural, one {Skjul {count} branche} other {Skjul {count} brancher}}',
  [intl.advancedFilters('search-for-industry')]: 'Sök efter en bransch',
  [intl.advancedFilters('no-industries-selected')]: 'Inga branscher valda',

  [intl.advancedSearch('add-filters')]: 'Välj filter',
  [intl.advancedSearch('all-filters')]: 'Alla filter',
  [intl.advancedSearch('apply-filters')]: 'Använd filter',
  [intl.advancedSearch('assets')]: 'Gör det möjligt',
  [intl.advancedSearch('auditor')]: 'Revisor',
  [intl.advancedSearch('between')]: 'Mellan',
  [intl.advancedSearch('cash')]: 'Likvidatorernas',
  [intl.advancedSearch('company-info')]: 'Företagsinformation',
  [intl.advancedSearch('company_status')]: 'Företagsstatus',
  [intl.advancedSearch('company_type')]: 'Affärs typ',
  [intl.advancedSearch('credit')]: 'Kreditera',
  [intl.advancedSearch('tree-industry')]: 'Branschkoder',
  [intl.advancedSearch('current_financial_assets')]: 'Värdepapper',
  [intl.advancedSearch('date_of_incorporation')]: 'Inarbetningsdatum',
  [intl.advancedSearch('employees')]: 'Antal anställda (intervall)',
  [intl.advancedSearch('employees_number')]: 'Antal anställda',
  [intl.advancedSearch('enter-percentage')]: 'Skriv värdet i procent (%)',
  [intl.advancedSearch('enter-value')]: 'Skriv värdet',
  [intl.advancedSearch('equity')]: 'Rättvisa',
  [intl.advancedSearch('excludes')]: 'Utesluta',
  [intl.advancedSearch('financial-data-section')]: 'Redovisningsinformation',
  [intl.advancedSearch('financial-data-select-all')]: 'Välj all redovisningsinformation',
  [intl.advancedSearch('financial-numbers')]: 'Bokföringssiffror',
  [intl.advancedSearch('financial-ratios')]: 'Nyckelfigurer',
  [intl.advancedSearch('fixed_assets')]: 'Anläggningstillgångar',
  [intl.advancedSearch('general-data-section')]: 'Allmän företagsinformation',
  [intl.advancedSearch('general-data-select-all')]: 'Välj all allmän företagsinformation',
  [intl.advancedSearch('gross_result')]: 'Bruttovinst',
  [intl.advancedSearch('has_auditor')]: 'Ha en revisor',
  [intl.advancedSearch('has_email')]: 'Har e-post',
  [intl.advancedSearch('has_phone_number')]: 'Har ett telefonnummer',
  [intl.advancedSearch('has_webpage')]: 'Ha en hemsida',
  [intl.advancedSearch('hide_advertisement_protected')]: 'Välj bort reklamskyddad',
  [intl.advancedSearch('hide_holding_company')]: 'Avmarkera holdingbolag',
  [intl.advancedSearch('includes')]: 'Omfatta',
  [intl.advancedSearch('industry_group')]: 'Industrigrupp',
  [intl.advancedSearch('liquidity')]: 'Kassalikviditeten',
  [intl.advancedSearch('load-filters')]: 'Ladda filter',
  [intl.advancedSearch('save-filters')]: 'Sparade filter',
  [intl.advancedSearch('load-filters-title')]: 'Använd ett av dina sparade filter',
  [intl.advancedSearch('match')]: 'Match',
  [intl.advancedSearch('municipality')]: 'Kommun',
  [intl.advancedSearch('no-filters')]: 'Inga filter valda',
  [intl.advancedSearch('no-saved-filters')]: 'Du har inga sparade filter',
  [intl.advancedSearch('over')]: 'Över',
  [intl.advancedSearch('placeholder-auditor')]: 'Skriv CVR eller revisorns namn',
  [intl.advancedSearch('profit_loss')]: 'Årsresultat',
  [intl.advancedSearch('range-from')]: 'Från',
  [intl.advancedSearch('range-to')]: 'Till',
  [intl.advancedSearch('region')]: 'Regionen',
  [intl.advancedSearch('reset')]: 'Återställa',
  [intl.advancedSearch('results-time-elapsed')]:
    '{count, plural, one {# resultat} other {# resultater}} på {time, plural, other {#}} sekunder',
  [intl.advancedSearch('return_on_assets')]: 'Avkastning',
  [intl.advancedSearch('save')]: 'Spara',
  [intl.advancedSearch('save-filter-label')]: 'Namnge ditt filter',
  [intl.advancedSearch('save-filter-title')]: 'Spara ditt filter för framtida bruk',
  [intl.advancedSearch('score')]: 'Risika score',
  [intl.advancedSearch('search-filter')]: 'Sök efter ditt filter',
  [intl.advancedSearch('select-all')]: 'Välj alla',
  [intl.advancedSearch('share_capital')]: 'Företagskapital',
  [intl.advancedSearch('show_inactive')]: 'Visa inaktiv',
  [intl.advancedSearch('snackbar-delete-filters-failed')]:
    'Det gick inte att ta bort ditt filter',
  [intl.advancedSearch('snackbar-delete-filters-success')]: 'Ditt filter har tagits bort',
  [intl.advancedSearch('snackbar-saved-filters-failed')]:
    'Det uppstod ett problem med att spara ditt filter',
  [intl.advancedSearch('snackbar-saved-filters-success')]: 'Ditt filter har sparats',
  [intl.advancedSearch('snackbar-update-filters-failed')]:
    'Det uppstod ett problem med att uppdatera ditt filter',
  [intl.advancedSearch('snackbar-update-filters-success')]: 'Ditt filter har uppdaterats',
  [intl.advancedSearch('duration')]: 'Varaktighet',
  [intl.advancedSearch('period')]: 'Period',
  [intl.advancedSearch('solidity')]: 'Grad av soliditet',
  [intl.advancedSearch('specific_industry')]: 'Specifik branschgrupp',
  [intl.advancedSearch('status_valid_from')]: 'Företagsstatus giltig från',
  [intl.advancedSearch('total_debt')]: 'Total skuld',
  [intl.advancedSearch('under')]: 'Nedan',
  [intl.advancedSearch('zipcode')]: 'Postnummer',
  [intl.advancedSearch('set-to-today')]: 'Inställd för idag',
  [intl.annualReportInformation('approvalDate')]:
    'Datum för godkännande av årsredovisningen',
  [intl.annualReportInformation('averageNumberOfEmployees')]:
    'Genomsnittligt antal anställda',
  [intl.annualReportInformation('classOfReportingEntity')]: 'Redovisningsklass',
  [intl.annualReportInformation('company_name')]: 'Revisor',
  [intl.annualReportInformation('description')]: 'Revisorbeteckning',
  [intl.annualReportInformation('get-auditor-information')]: 'Få revisorsinformation',
  [intl.annualReportInformation('ifrs')]: 'IFRS',
  [intl.annualReportInformation('name')]: 'Revisorns namn',
  [intl.annualReportInformation('no_information')]: 'Ingen information',
  [intl.annualReportInformation('show_less')]: 'Visa mindre',
  [intl.annualReportInformation('show_more')]: 'Visa mer',
  [intl.annualReportInformation('title')]: 'Redovisningsinformation',
  [intl.annualReportInformation('type_of_assistance')]: 'Statement Type',
  [intl.auditorInfo('auditors_report')]: 'Revisionsberättelse',
  [intl.auditorInfo('auditors_report_on_extended_review')]:
    'Utlåtande om utökad granskning',
  [intl.auditorInfo('auditors_reports_(other_non-assurance_reports)')]:
    'Andra osäkrade uttalanden',
  [intl.auditorInfo('company_name')]: 'Revisionsbyrå',
  [intl.auditorInfo('name_of_auditor')]: 'Revisor',
  [intl.auditorInfo('no_auditor_assistance')]: 'Ingen revisor',
  [intl.auditorInfo('the_independent_auditors_reports_(review)')]:
    'Den oberoende revisorns uttalanden (granskning)',
  [intl.auditorInfo('type_of_assistance')]: 'Statement Type',
  [intl.auth('account-already-verified')]: 'Ditt konto har redan verifierats',
  [intl.auth('account-deactivated')]: 'Ditt konto har blockerats',
  [intl.auth('account-deactivated-subtitle')]:
    'Du borde ha fått ett e-postmeddelande med instruktioner om hur du återaktiverar det.',
  [intl.auth('account-deactivated-title')]: 'Ditt konto har blockerats',
  [intl.auth('account-verified-failed')]:
    'Vi hade några problem med att verifiera ditt konto',
  [intl.auth('account-verified-failed-subtitle')]:
    'Om du skriver till oss i chatten hjälper vi dig att få tillgång direkt.',
  [intl.auth('account-verified-failed-title')]: 'Problem med verifiering',
  [intl.auth('account-verified-success')]:
    'Ditt konto har verifierats och du kan nu logga in',
  [intl.auth('already-have-an-account')]: 'Har du redan ett konto?',
  [intl.auth('change-password-failed')]:
    'Det verkar som att det uppstod ett problem med att ändra ditt lösenord',
  [intl.auth('change-password-success')]: 'Du kan nu logga in med ditt nya lösenord!',
  [intl.auth('change-password-title')]: 'Skapa ett nytt lösenord',
  [intl.auth('company-already-exists')]: 'Företaget har redan skapats',
  [intl.auth('company-label')]: 'Företag',
  [intl.auth('company-placeholder')]: 'Sök efter ditt företag',
  [intl.auth('confirm-new-password-label')]: 'Bekräfta nytt lösenord',
  [intl.auth('confirm-new-password-placeholder')]: 'Bekräfta ditt nya lösenord',
  [intl.auth('contact-us')]: 'Kontakta oss:',
  [intl.auth('do-not-think-email-received')]:
    'Kan du inte se e-postmeddelandet i din inkorg?',
  [intl.auth('email')]: 'E-post',
  [intl.auth('email-already-exists')]:
    'Denna e-postadress är redan registrerad. Kontakta oss på contact@risika.dk',
  [intl.auth('email-label')]: 'E-post',
  [intl.auth('email-placeholder')]: 'mig@virksomd.dk',
  [intl.auth('email-sent-to')]: 'Vi skickade mailet till',
  [intl.auth('email-should-have-been-resend')]:
    'Du borde ha fått ett nytt e-postmeddelande',
  [intl.auth('finalize-account')]: 'Komplett konto',
  [intl.auth('first-name-label')]: 'Förnamn',
  [intl.auth('first-name-placeholder')]: 'Ditt namn',
  [intl.auth('forgot-password')]: 'Glömt ditt lösenord?',
  [intl.auth('forgot-password-email-subtitle')]:
    'Fick du inte e-postmeddelandet? Kolla din skräppostmapp',
  [intl.auth('forgot-password-email-title')]:
    'Vi har skickat ett e-postmeddelande till dig för att återställa ditt lösenord',
  [intl.auth('forgot-password-failed')]:
    'Ursäkta, det verkar som att det har uppstått ett problem',
  [intl.auth('forgot-password-subtitle')]:
    'Ange din e-postadress så skickar vi instruktioner om hur du ändrar ditt lösenord',
  [intl.auth('forgot-password-title')]: 'Återställ ditt lösenord',
  [intl.auth('get-me-in')]: 'Få in mig',
  [intl.auth('get-started-free')]: 'Kom igång gratis',
  [intl.auth('go-back')]: 'Lämna tillbaka',
  [intl.auth('go-back-to-log-in')]: 'Gå tillbaka för att logga in',
  [intl.auth('invalid-local-id')]: 'Vänligen använd ett giltigt CVR-nummer',
  [intl.auth('last-name-label')]: 'Efternamn',
  [intl.auth('last-name-placeholder')]: 'Ditt efternamn',
  [intl.auth('log-in')]: 'Logga in',
  [intl.auth('login-header')]: 'Logga in på ditt konto',
  [intl.auth('login-to-your-account')]: 'Logga in på ditt konto →',
  [intl.auth('min-password-length')]: 'Minst 8 tecken',
  [intl.auth('new-email-sent')]: 'Vi har skickat ett nytt e-postmeddelande',
  [intl.auth('new-password-label')]: 'Nytt lösenord',
  [intl.auth('new-password-placeholder')]: 'Ditt nya lösenord',
  [intl.auth('no-account-yet')]: 'Har du inget konto än?',
  [intl.auth('password-label')]: 'Lösenord',
  [intl.auth('password-placeholder')]: 'Ditt lösenord',
  [intl.auth('personal-information')]: 'Personlig information',
  [intl.auth('phone')]: 'Telefon',
  [intl.auth('phone-number-label')]: 'Telefonnummer',
  [intl.auth('please-use-work-mail')]: 'Använd din jobbmail',
  [intl.auth('register-account')]: 'Skapa konto',
  [intl.auth('register-account-subtitle')]: 'Alla fält är obligatoriska',
  [intl.auth('register-header')]: 'Skapa ditt konto',
  [intl.auth('resend-email')]: 'Skicka nytt mail',
  [intl.auth('send-email')]: 'skicka epost',
  [intl.auth('session-expired')]: 'Din session har löpt ut, vänligen logga in igen.',
  [intl.auth('showcase-subtitle')]:
    'Risika skapar trygghet i handeln mellan skandinaviska företag genom rättvisa och precisa kreditbedömningar.',
  [intl.auth('showcase-title')]: 'Den enkla vägen till ett hälsosamt företag',
  [intl.auth('sso-login')]: 'Logga in med SSO',
  [intl.auth('terms-of-service')]: 'villkoren',
  [intl.auth('understand-and-accept')]: 'Jag förstår och accepterar',
  [intl.auth('user-registered-subtitle')]:
    'Vi har skickat ett e-postmeddelande till dig för att verifiera ditt konto. Så fort du har gjort det kan du börja med Risika.',
  [intl.auth('user-registered-title')]: 'Verifiera ditt konto',
  [intl.auth('user-with-email-exists')]:
    'Det finns redan en användare med den e-postadressen',
  [intl.auth('work-email-label')]: 'Jobbmail',
  [intl.auth('wrong-email-or-password')]:
    'Kombinationen av e-postadress och lösenord som du angav matchar inte något konto. Försök igen eller återställ ditt lösenord',
  [intl.auth('your-company')]: 'Ditt bolag',
  [intl.auth('new-user')]: 'Ny användare?',
  [intl.auth('create-an-account')]: 'Skapa ett konto',
  [intl.auth('back-to-sign-in')]: 'Tillbaka till Logga in',
  [intl.auth('send-instructions')]: 'Skicka instruktioner',
  [intl.auth('send-instructions-again')]: 'Skicka instruktioner igen',
  [intl.auth('save-password')]: 'Spara lösenord',
  [intl.auth('invalid-email')]: 'Ange en giltig e-postadress',
  [intl.auth('invalid-password')]: 'Ange ett giltigt lösenord',
  [intl.auth('passwords-do-not-match')]: 'Lösenorden stämmer inte överens',

  [intl.companyHierarchy('BENEFICIAL_OWNER_AND_LEGAL_OWNER')]: 'Laglig och verklig ägare',
  [intl.companyHierarchy('LEGAL_OWNER')]: 'Juridisk ägare',
  [intl.companyHierarchy('BENEFICIAL_OWNER')]: 'Verklig ägare',
  [intl.companyHierarchy('monitor')]: 'Bevaka',
  [intl.companyHierarchy('equity')]: 'Rättvisa',
  [intl.companyHierarchy('local-id')]: 'Företags ID',
  [intl.companyHierarchy('risika-score')]: 'Risika score',
  [intl.companyHierarchy('role')]: 'Roll',
  [intl.companyHierarchy('CEO')]: 'VD',
  [intl.companyHierarchy('profit-loss')]: 'Årets resultat',
  [intl.companyHierarchy('accessories')]: 'Val',
  [intl.companyHierarchy('collapse-all')]: 'Dölj',
  [intl.companyHierarchy('coming-soon')]: 'Kommer snart',
  [intl.companyHierarchy('company_information')]: 'Företagsinformation',
  [intl.companyHierarchy('d3-child-name')]: 'Dotterbolag till',
  [intl.companyHierarchy('detailed-view')]: 'Detaljerad översikt',
  [intl.companyHierarchy('equity:')]: 'Rättvisa:',
  [intl.companyHierarchy('expand-all')]: 'Utöka alla {number} företag',
  [intl.companyHierarchy('expand-all-d3')]: 'bygga ut',
  [intl.companyHierarchy('print')]: 'Skriv ut',
  [intl.companyHierarchy('expand-all')]: 'Expandera alla',
  [intl.companyHierarchy('collapse-all')]: 'Dölj alla',
  [intl.companyHierarchy('header')]: 'Grupphierarki',
  [intl.companyHierarchy('list-view')]: 'LISTVY',
  [intl.companyHierarchy('tree-view')]: 'TRÄDSTRUKTUR',
  [intl.companyHierarchy('owners')]: '{number} ägare {toggled}',
  [intl.companyHierarchy('subsidiaries')]:
    '{number} {number, select, 1 {dotterbolag} other {Dotterbolag}} {toggled}',
  [intl.companyHierarchy('display-props')]: 'Visa egenskaper',
  [intl.companyHierarchy('listed-in')]: 'Listad i:',
  [intl.companyHierarchy('local-id:')]: 'Företags ID:',
  [intl.companyHierarchy('monitor-all')]: 'Övervaka alla',
  [intl.companyHierarchy('name')]: 'Namn',
  [intl.companyHierarchy('no-credit-score')]: 'Företaget har ingen kreditvärdighet',
  [intl.companyHierarchy('no-legal-owners')]: 'Inga lagliga ägare',
  [intl.companyHierarchy('no-real-owners')]: 'Inga riktiga ägare',
  [intl.companyHierarchy('ownership')]: 'Ägarandelar',
  [intl.companyHierarchy('profit_loss')]: 'Årets resultat',
  [intl.companyHierarchy('profit_loss:')]: 'Årets resultat:',
  [intl.companyHierarchy('relations')]: 'Företagsroller',
  [intl.companyHierarchy('save-view-description')]:
    'Här kan du spara hierarkivyn för den aktuella sessionen. När du loggar ut kommer alla dina sparade vyer att försvinna.',
  [intl.companyHierarchy('save-view-title')]: 'Spara hierarkivy',
  [intl.companyHierarchy('select-list')]: 'Välj en lista',
  [intl.companyHierarchy('sort_by')]: 'Sortera efter',
  [intl.companyHighlights('click-for-more')]: 'klicka för mer',
  [intl.companyHighlights('title')]: 'Företagsinformation',
  [intl.companyHighlights('title-with-currency')]: 'Bokföringssiffror ({currency})',
  [intl.companyHighlights('profit-loss')]: 'Årets resultat',
  [intl.companyHighlights('company-info')]: 'Information om företaget',
  [intl.fraudIndicators('title')]: 'Bedrägeriindikatorer',
  [intl.fraudIndicators('address')]: 'Adress',
  [intl.fraudIndicators('financial')]: 'Finansiell',
  [intl.fraudIndicators('enabled')]: 'Aktiverad',
  [intl.fraudIndicators('disabled')]: 'Inaktiverad',
  [intl.companyInfo('ad-protection')]: 'Reklamskydd',
  [intl.companyInfo('ad-protection-allowed')]:
    'Företaget har gått med på att bli kontaktad angående reklam',
  [intl.companyInfo('ad-protection-denied')]:
    'Företaget har vägrat att bli kontaktad angående reklam',
  [intl.companyInfo('add-internal-id-label')]: 'Internt ID',
  [intl.companyInfo('add-internal-id-title')]: 'Lägg till internt ID',
  [intl.companyInfo('address')]: 'Adress',
  [intl.companyInfo('address-changes-count')]: 'Adressändringar',
  [intl.companyInfo('auditors')]: 'Revisor',
  [intl.companyInfo('bank')]: 'Bank',
  [intl.companyInfo('company-id')]: 'Företags ID',
  [intl.companyInfo('company-info-re-arrange')]:
    'Skräddarsy den allmänna företagsinformationen efter dina behov genom att dra fälten nedan!',
  [intl.companyInfo('company-name')]: 'Företagsnamn',
  [intl.companyInfo('company-name-changes-count')]: 'Företagsnamn ändras',
  [intl.companyInfo('company-name-changes-count-explanation')]:
    'Detta kan skilja sig från ändrade företagsnamn. Att kunna validera faktiska företagsnamnsändringar hjälper oss att identifiera mönster som kan indikera risk',
  [intl.companyInfo('company-name-required')]: 'Företagsnamn *',
  [intl.companyInfo('company-type')]: 'Affärs Typ',
  [intl.companyInfo('date-of-establishment')]: 'Inarbetningsdatum',
  [intl.companyInfo('date-of-incorporation')]: 'Grundades före 1900',
  [intl.companyInfo('ean')]: 'EAN nummer',
  [intl.companyInfo('edit-internal-id-label')]: 'Internt ID',
  [intl.companyInfo('edit-internal-id-title')]: 'Redigera internt ID',
  [intl.companyInfo('email')]: 'E-post',
  [intl.companyInfo('employees')]: 'Anställdas',
  [intl.companyInfo('financial_year')]: 'Budgetår',
  [intl.companyInfo('founders_auditors')]: 'Grundarnas',
  [intl.companyInfo('fte')]: 'Ärs arbete',
  [intl.companyInfo('industry')]: 'Företag',
  [intl.companyInfo('internal-id')]: 'Internt ID',
  [intl.companyInfo('invalid')]: 'Ogiltig',
  [intl.companyInfo('land_value')]: 'Grundvärde',
  [intl.companyInfo('listed')]: 'Listad',
  [intl.companyInfo('number-of-employees')]: 'Antal anställda',
  [intl.companyInfo('phone')]: 'Telefon',
  [intl.companyInfo('powers-to-bind')]: 'Ritningsregel',
  [intl.companyInfo('production-units')]: 'Produktionsenheter',
  [intl.companyInfo('property_id')]: 'Husnummer',
  [intl.companyInfo('property_type')]: 'Egenskapstyp',
  [intl.companyInfo('property_value')]: 'Fastighetsvärde',
  [intl.companyInfo('purpose')]: 'Ändamål',
  [intl.companyInfo('registered-for-vat')]: 'Momsregistrerad',
  [intl.companyInfo('secondary-names')]: 'Smeknamn',
  [intl.companyInfo('share-capital')]: 'Företagskapital',
  [intl.companyInfo('share_capital')]: 'Företagskapital',
  [intl.companyInfo('source')]: 'Källa',
  [intl.companyInfo('tinglysning')]: 'Fastighetsregistret',
  [intl.companyInfo('title')]: 'Allmän företagsinformation',
  [intl.companyInfo('valid-from')]: 'Giltig från',
  [intl.companyInfo('valid-to')]: 'Giltig till',
  [intl.companyInfo('valuation_date')]: 'Bedömningsdatum',
  [intl.companyInfo('webpage')]: 'Hemsida',
  [intl.companyInfo('number-of-employees-data-display')]:
    '{date, select, undefined {N/A} other {{date}}}: {employeesInterval} {employeesNumber, select, undefined {{fte, select, undefined {} other {(}}} other {({employeesNumber} anställda}}{fte, select, undefined {{employeesNumber, select, undefined {} other {)}}} other {{employeesNumber, select, undefined {} other {, }}{fte} Ärs arbete)}}',
  [intl.companyInfo('address-changes-count-explanation')]:
    'Detta kan skilja sig från registrerade adressändringar. Att kunna validera faktiska adressändringar hjälper oss att identifiera mönster som kan indikera bedräglig aktivitet',

  [intl.companyRoles('advanced-view')]: 'Avancerad',
  [intl.companyRoles('analysis-of-bankruptcies')]: 'Konkursanalys',
  [intl.companyRoles('before-1987')]: 'Före 1987',
  [intl.companyRoles('board-of-directors')]: 'Styrelse',
  [intl.companyRoles('get-overview')]: 'Få överblick',
  [intl.companyRoles('hide-historical-data')]: 'Dölj historiska data',
  [intl.companyRoles('historical-management')]: 'Historisk förvaltning',
  [intl.companyRoles('history-hide')]: 'Dölj historik',
  [intl.companyRoles('history-show')]: 'Visa historik',
  [intl.companyRoles('legal-owners')]: 'Juridiska ägares',
  [intl.companyRoles('minimize-table')]: 'Enkel översikt',
  [intl.companyRoles('month')]: '1 månad',
  [intl.companyRoles('months')]: '{months} månader',
  [intl.companyRoles('no-board-of-directors')]: 'Ingen tavla hittades',
  [intl.companyRoles('no-information')]: 'Informationen är inte tillgänglig',
  [intl.companyRoles('no-management')]: 'Ingen ledning hittades',
  [intl.companyRoles('no-owners')]: 'Inga ägare',
  [intl.companyRoles('other-positions')]: ' se mer...',
  [intl.companyRoles('real-owners')]: 'Verkliga ägares',
  [intl.companyRoles('see-hierarchy')]: 'Se grupphierarki',
  [intl.companyRoles('see-historical-data')]: 'Se historiska data',
  [intl.companyRoles('shares')]: 'aktie',
  [intl.companyRoles('show-all')]: 'Visa allt',
  [intl.companyRoles('show-full-table')]: 'Fullständig översikt',
  [intl.companyRoles('show-less')]: 'Visa mindre',
  [intl.companyRoles('simple-view')]: 'Enkel',
  [intl.companyRoles('subsidiaries')]: 'Dotterbolag',
  [intl.companyRoles('title')]: 'Förvaltning',
  [intl.companyRoles('title-owners')]: 'Ägarnas',
  [intl.companyRoles('total')]: 'Totalt:',
  [intl.companyRoles('total-owners')]: 'Totala ägare',
  [intl.companyRoles('year')]: '1 år',
  [intl.companyRoles('years')]: '{years} år ',
  [intl.companyRoles('title-map')]: 'Karta',
  [intl.confirmAction('agree')]: 'Bekräfta',
  [intl.confirmAction('cancel')]: 'Avbryt',
  [intl.confirmAction('confirm')]: 'Bekräfta',
  [intl.confirmAction('delete')]: 'Radera',
  [intl.confirmAction('save')]: 'Spara',
  [intl.country('de')]: 'Tyskland',
  [intl.country('dk')]: 'Danmark',
  [intl.country('fi')]: 'Finland',
  [intl.country('no')]: 'Norge',
  [intl.country('se')]: 'Sverige',
  [intl.country('uk')]: 'STORBRITANNIEN',
  [intl.creditCheck('auditor')]: 'Revisorinformation',
  [intl.creditCheck('boundary-line-1')]:
    'Ett problem uppstod när din begäran bearbetades. Försök igen senare!',
  [intl.creditCheck('boundary-line-2')]:
    'Om det inte fungerar, försök att logga ut och in igen',
  [intl.creditCheck('boundary-line-3')]:
    'Vi har underrättats om problemet och håller på att lösa det',
  [intl.creditCheck('beneficial_owner')]: 'Verkliga ägares',
  [intl.creditCheck('cant-score-comapny')]:
    'Vi kunde inte göra mål på det här företaget!',
  [intl.creditCheck('check-use-fail')]: 'Ingen information för valt företag',
  [intl.creditCheck('check-use-success')]: 'Du använde en kreditupplysning',
  [intl.creditCheck('credit-check-re-arrange')]:
    'Anpassa din vy genom att flytta fälten nedan',
  [intl.creditCheck('from')]: 'Från',
  [intl.creditCheck('generate-report')]: 'Ladda ner PDF-rapport',
  [intl.creditCheck('highlights')]: 'Anteckningar',
  [intl.creditCheck('historical-scores')]: 'Se historiska resultat',
  [intl.creditCheck('monitor-company')]: 'Övervaka affärer',
  [intl.creditCheck('name')]: 'Namn',
  [intl.creditCheck('no-company-redirect')]:
    'Inget företag valt, returnerar dig till "sökföretag"',
  [intl.creditCheck('no-data')]:
    'Grupphierarkin är ännu inte tillgänglig för det här landet',
  [intl.creditCheck('no-subsidiaries')]: 'Bolaget har inga dotterbolag',
  [intl.creditCheck('otherPosition')]: 'Andra roller',
  [intl.creditCheck('ownership')]: 'Ägarandelar',
  [intl.creditCheck('pep')]: 'PEP-lista',
  [intl.creditCheck('period')]: 'Årsredovisningsperiod',
  [intl.creditCheck('position')]: 'Roll',
  [intl.creditCheck('report-button')]: 'Dela rapport',
  [intl.creditCheck('select-list')]: 'Välj en lista',
  [intl.creditCheck('seniority')]: 'Anciennitet',
  [intl.creditCheck('share')]: 'Dela med sig',
  [intl.creditCheck('share-internal-header')]: 'Användare från din organisation',
  [intl.creditCheck('share-report')]: 'Dela PDF-rapport',
  [intl.creditCheck('share-report-description')]:
    'Här kan du skicka en kreditupplysning till en valfri e-postadress eller till en användare i din organisation.',
  [intl.creditCheck('share-report-message')]:
    'Lägg till ett meddelande tillsammans med rapporten',
  [intl.creditCheck('share-specific-email-header')]:
    'Skicka till ett valfritt e-postmeddelande',
  [intl.creditCheck('shares')]: 'aktier',
  [intl.creditCheck('specialRights')]: 'Särskilda anmärkningar',
  [intl.creditCheck('stop-monitor-company')]: 'Sluta bevaka',
  [intl.creditCheck('subheader-placeholder')]: 'E-post på mottagare',
  [intl.creditCheck('title')]: 'Kreditupplysning',
  [intl.creditCheck('to')]: 'Till',
  [intl.creditCheck('use-check-for-auditor-title')]:
    'Är du säker på att du vill använda en kreditkontroll?',
  [intl.creditCheck('use-credit')]: 'Använd kreditupplysning',
  [intl.creditCheck('votingRights')]: 'Rösträtt',
  [intl.creditPolicy("AUDITOR'S REPORT ON EXTENDED REVIEW")]:
    'Utlåtande om utökad granskning',
  [intl.creditPolicy("AUDITOR'S REPORT")]: 'Revisionsberättelse',
  [intl.creditPolicy("AUDITOR'S REPORTS (OTHER NON-ASSURANCE REPORTS)")]:
    'Andre erklæringer uden sikkerhed',
  [intl.creditPolicy("THE INDEPENDENT AUDITOR'S REPORTS (REVIEW)")]:
    'Den oberoende revisorns uttalanden (granskning)',
  [intl.creditPolicy('COMPILATION OF FINANCIAL STATEMENTS')]:
    'Sammanställning av finansiella rapporter',
  [intl.creditPolicy('1')]: '1',
  [intl.creditPolicy('1-and-more')]: '1 och fler',
  [intl.creditPolicy('1-year')]: '1 år',
  [intl.creditPolicy('10-and-more')]: '10',
  [intl.creditPolicy('2')]: '2',
  [intl.creditPolicy('2-and-more')]: '2 och fler',
  [intl.creditPolicy('2-year')]: '2 år',
  [intl.creditPolicy('3')]: '3',
  [intl.creditPolicy('3-and-more')]: '3 och fler',
  [intl.creditPolicy('3-year')]: '3 år',
  [intl.creditPolicy('4')]: '4',
  [intl.creditPolicy('4-and-more')]: '4 och fler',
  [intl.creditPolicy('5')]: '5',
  [intl.creditPolicy('6')]: '6',
  [intl.creditPolicy('7')]: '7',
  [intl.creditPolicy('8')]: '8',
  [intl.creditPolicy('5-and-more')]: '5 och fler',
  [intl.creditPolicy('6-and-more')]: '6 och fler',
  [intl.creditPolicy('7-and-more')]: '7 och mer',
  [intl.creditPolicy('8-and-more')]: '8 och mer',
  [intl.creditPolicy('9-and-more')]: '9 och uppåt',
  [intl.creditPolicy('NO AUDITOR ASSISTANCE')]: 'Ingen revisor',
  [intl.creditPolicy('POLICY_AGE')]: 'Företaget är för ungt',
  [intl.creditPolicy('POLICY_AUDITOR_ASSISTANCE')]:
    'Revisionsberättelsen är inte tillåten',
  [intl.creditPolicy('POLICY_NUMBER_OF_EMPLOYEES')]: 'Antal anställda',
  [intl.creditPolicy('POLICY_BANKRUPTCIES')]: 'För många konkurser',
  [intl.creditPolicy('POLICY_BANKRUPTCIES_LONG')]: 'För många konkurser',
  [intl.creditPolicy('POLICY_COMPANY_TYPE')]: 'Avvisad företagstyp',
  [intl.creditPolicy('POLICY_DISTANCE_TO_CEO')]: 'Stort avstånd från VD',
  [intl.creditPolicy('POLICY_ENTITY_ID')]: 'Avvisad person',
  [intl.creditPolicy('POLICY_INDUSTRY')]: 'Bransch avvisad',
  [intl.creditPolicy('POLICY_LOCAL_ID')]: 'Avvisat företag',
  [intl.creditPolicy('POLICY_AUDITOR_HAS_ID')]: 'Revisor utan MNE-nummer',
  [intl.creditPolicy('POLICY_NEGATIVE_EQUITY')]: 'Negativt eget kapital',
  [intl.creditPolicy('POLICY_PHONE_NUMBER_REGISTRY')]: 'Telefonnummer ej registrerat',
  [intl.creditPolicy('POLICY_PREPAID_PHONE_NUMBER')]: 'Rösttidskortsnummer',
  [intl.creditPolicy('POLICY_RISIKA_SCORE')]: 'Låg kreditpoäng',
  [intl.creditPolicy('POLICY_VAT')]: 'MOMS',
  [intl.creditPolicy('above-1-year')]: 'över 1 år',
  [intl.creditPolicy('above-2-year')]: 'över 2 år',
  [intl.creditPolicy('above-3-year')]: 'över 3 år',
  [intl.creditPolicy('above-4-year')]: 'över 4 år',
  [intl.creditPolicy('activate-advanced-view')]:
    'Detta är en avancerad funktion. Kontakta oss om du vill prova!',
  [intl.creditPolicy('age-limitation-information')]:
    'Välj den lägsta företagsålder som du vill tillåta i din kreditpolicy',
  [intl.creditPolicy('age-limitation-title')]: 'Lägsta företagsålder',
  [intl.creditPolicy('allow-phone-number')]: 'Tillåta',
  [intl.creditPolicy('are-you-sure')]:
    'Det verkar som att du har gjort några ändringar i din kreditpolicy. Är du säker på att du vill avsluta utan att spara?',
  [intl.creditPolicy('are-you-sure-dialog')]:
    'Den här åtgärden inaktiverar kreditpolicyn för hela din organisation. Är du säker på att du vill fortsätta?',
  [intl.creditPolicy('are-you-sure-dialog-clear-field')]:
    'Denna åtgärd tar bort alla poster i det här fältet. Är du säker på att du vill fortsätta?',
  [intl.creditPolicy('auditor-assistance-information')]:
    'Välj den lägsta nivå av uttalande från revisorn som du vill tillåta i din kreditpolicy',
  [intl.creditPolicy('auditor-assistance-title')]: 'Ej godkända deklarationstyper',
  [intl.creditPolicy('auditor-placeholder')]: 'Välj många',
  [intl.creditPolicy('auditors_report')]: 'Revisionsberättelse',
  [intl.creditPolicy('bankruptcy-short-limit-information')]:
    'Hur många relaterade konkurser under de senaste 10 åren kommer din kreditpolicy att tillåta?',
  [intl.creditPolicy('bankruptcy-long-limit-information')]:
    'Hur många relaterade konkurser under de senaste 50 åren kommer din kreditpolicy att tillåta?',
  [intl.creditPolicy('bankruptcy-short-limit-title')]:
    'Maximalt antal relaterade konkurser (10 år)',
  [intl.creditPolicy('bankruptcy-long-limit-title')]:
    'Maximalt antal relaterade konkurser (50 år)',
  [intl.creditPolicy('company-does-not-comply-with-policy')]:
    'Företaget följer inte kreditpolicyn',
  [intl.creditPolicy('company-types-information')]:
    'Uteslut företagstyper i din kreditpolicy',
  [intl.creditPolicy('company-types-title')]: 'Osorterade företagstyper',
  [intl.creditPolicy('copied-success')]: 'Politik kopierad framgångsrikt',
  [intl.creditPolicy('copy-policy')]: 'Kopiera policy',
  [intl.creditPolicy('copy-policy-title')]: 'Välj landet och fälten du vill kopiera!',
  [intl.creditPolicy('distance-to-ceo-information')]:
    'VD:s avstånd i km från företagsadress',
  [intl.creditPolicy('distance-to-ceo-title')]: 'Avstånd till VD',
  [intl.creditPolicy('enter-number')]: 'Ange avstånd (km)',
  [intl.creditPolicy('dk')]: 'Danmark',
  [intl.creditPolicy('dont-allow-phone-number')]: 'Inte tillåta',
  [intl.creditPolicy('exclude-companies-information')]:
    'Uteslut specifika företag från din kreditpolicy',
  [intl.creditPolicy('exclude-companies-title')]:
    'Uteslut specifika företag från din kreditpolicy',
  [intl.creditPolicy('exclude-persons-information')]:
    'Uteslut specifika personer från din kreditpolicy',
  [intl.creditPolicy('exclude-persons-title')]: 'Utesluta människor',
  [intl.creditPolicy('exclude-vat-information')]:
    'Måste ett företag vara momsregistrerat eller måste det bara ligga under den tillåtna gränsen för ett land?',
  [intl.creditPolicy('exclude-vat-title')]:
    'Uteslut företag som inte är momsregistrerade',
  [intl.creditPolicy('less-than-1')]: 'mindre än 1',
  [intl.creditPolicy('more-than-3')]: '3 och fler',
  [intl.creditPolicy('more-than-4')]: '4 och fler',
  [intl.creditPolicy('more-than-3-years')]: 'Mer än 3 år',
  [intl.creditPolicy('more-than-4-years')]: 'Mer än 4 år',
  [intl.creditPolicy('more-than-5')]: '5 och fler',
  [intl.creditPolicy('name_of_auditor')]: 'Revisor',
  [intl.creditPolicy('negative-equity-information')]:
    'Välj det antal år utan negativt eget kapital som du vill tillåta i din kreditpolicy',
  [intl.creditPolicy('negative-equity-title')]: 'Förra året utan negativt eget kapital',
  [intl.creditPolicy('no')]: 'Norge',
  [intl.creditPolicy('no-conflicts')]: 'Inga konflikter med kreditpolicyn.',
  [intl.creditPolicy('none')]: 'Ingen',
  [intl.creditPolicy('off')]: 'Tillåta',
  [intl.creditPolicy('phone-number-registry-information')]:
    'Är telefonnumret registrerat i telefonboken',
  [intl.creditPolicy('phone-number-registry-title')]: 'Registrerat telefonnummer',
  [intl.creditPolicy('policy-not-available-for-non-admin-users')]:
    'Kreditpolicyn är endast tillgänglig för administratörer i din organisation. Om du tycker att du borde kunna ändra kreditpolicyn, kontakta din administratör eller oss på contact@risika.dk.',
  [intl.creditPolicy('policy-off')]: 'Kreditpolicy inaktiverad',
  [intl.creditPolicy('policy-on')]: 'Kreditpolicy aktiverad',
  [intl.creditPolicy('policy-switch-failed')]: 'Kunde inte ändra kreditpolicy',
  [intl.creditPolicy('policy-switch-off-success')]:
    'Din kreditpolicy för {country} är inaktiverad',
  [intl.creditPolicy('policy-switch-on-success')]:
    'Din kreditpolicy för {country} är aktiverad',
  [intl.creditPolicy('prepaid-phone-number-information')]:
    'Få ett meddelande om ett företag skapas med ett rösttidskortnummer',
  [intl.creditPolicy('prepaid-phone-number-title')]: 'Tillåt sändningskortsnummer',
  [intl.creditPolicy('risika-score-information')]:
    'Välj den lägsta Risika-poängen som du vill tillåta i din kreditpolicy',
  [intl.creditPolicy('risika-score-title')]: 'Lägsta Risika-poäng',
  [intl.creditPolicy('save-failed')]:
    'Det gick inte att spara kreditpolicyn för {country}. Försök igen senare!',
  [intl.creditPolicy('save-success')]: 'Sparad kreditpolicy för {country}',
  [intl.creditPolicy('se')]: 'Sverige',
  [intl.creditPolicy('search')]: 'Sök och lägg till',
  [intl.creditPolicy('select')]: 'Välj',
  [intl.creditPolicy('select-many')]: 'välj många',
  [intl.creditPolicy('should-be')]: 'Inte tillåta',
  [intl.creditPolicy('should-be-and-exceeded')]:
    'Endast om det är över den lokala momsgränsen',
  [intl.creditPolicy('specific-address-title')]: 'Exkludera adresser',
  [intl.creditPolicy('specific-industry-information')]:
    'Uteslut specifika branscher i din kreditpolicy',
  [intl.creditPolicy('specific-industry-title')]: 'Sortera ut branscher',
  [intl.creditPolicy('too-many-values')]:
    'Värden för {country} överskrider gränsen. Lade till endast 500 företag!',
  [intl.creditPolicy('type_of_assistance')]: 'Statement Type',
  [intl.creditPolicy('uk')]: 'STORBRITANNIEN',
  [intl.creditPolicy('up-to-1')]: '1 och mindre',
  [intl.creditPolicy('up-to-2')]: '2 och under',
  [intl.creditPolicy('up-to-3')]: '3 och under',
  [intl.creditPolicy('up-to-4')]: '4 och under',
  [intl.creditPolicy('up-to-5')]: '5 och under',
  [intl.creditPolicy('auditor-id')]: 'Revisorn måste ha ett identifikationsnummer (MNE)',
  [intl.creditPolicy('auditor-id-information')]:
    'Tillåt endast godkända revisorer med MNE-nummer. MNE-numret är ett unikt offentligt identifikationsnummer för alla godkända revisorer.',

  // [intl.creditPolicyNew('company_information')]: 'Company information',
  [intl.creditPolicyNew('status')]: 'Företagets status',
  // [intl.creditPolicyNew('risk_indicators')]: 'Risk indicators',
  // [intl.creditPolicyNew('fraud_indicators')]: 'Fraud indicators',
  // [intl.creditPolicyNew('people_and_company_search')]: 'People and Company search',
  // [intl.creditPolicyNew('financial_indicators')]: 'Financial indicators',
  [intl.creditPolicyNew('payment_history')]: 'Betalningshistorik',
  [intl.creditPolicyNew('advanced')]: 'Avancerad',
  [intl.creditPolicyNew('outcome')]: 'Resultat',
  // Action panel rules
  [intl.creditPolicyNew('company_type')]: 'Typ av företag',
  [intl.creditPolicyNew(COMPANY_LIST)]: 'Lista över företag',
  [intl.creditPolicyNew(COMPANY_LIST + '-node-text')]: 'Lista för övervakning {listId}',
  [intl.creditPolicyNew(NEW_MONITORING_LIST)]: 'Skapa en lista',
  [intl.creditPolicyNew('new-monitoring-list-name')]: 'Navn',
  [intl.creditPolicyNew('new-monitoring-list-add')]: 'Lägg till företag i din lista',
  [intl.creditPolicyNew('new-monitoring-list-placeholder')]: 'Ge din lista ett namn',
  [intl.creditPolicyNew('industry_type')]: 'Typ av bransch',
  [intl.creditPolicyNew('number_of_employees')]: 'Antal anställda',
  [intl.creditPolicyNew('years_of_existence')]:
    'Antal år som företaget funnits/företagets ålder',
  [intl.creditPolicyNew('credit-policy-page-title')]: 'Kreditpolicyer',
  [intl.creditPolicyNew('delete-rule-are-you-sure')]:
    'Er du sikker på at du vil slette denne regel?',
  [intl.creditPolicyNew('delete-rule-explanation')]: 'den er knyttet til.',
  [intl.creditPolicyNew('delete-rule-do-not-show-again')]:
    'Visa inte det här meddelandet igen',
  [intl.creditPolicyNew('company_type_node_content')]: 'Är {companyList}',
  [intl.creditPolicyNew('audit-node-text')]: 'Företaget har granskats',
  [intl.creditPolicyNew('prepaid-phone-node-text')]: 'Förbetalt kontantkort registrerat',
  [intl.creditPolicyNew('approve-credit-max-node-text-credit-max')]: 'Kredit max:',
  [intl.creditPolicyNew('approve-credit-max-node-text-not-lower-than')]: 'Inte lägre än:',
  [intl.creditPolicyNew('approve-credit-max-node-text-not-greater-than')]:
    'Inte större än:',
  [intl.creditPolicyNew('approve-credit-max-node-text')]: 'Använd Risikas rekommendation',
  [intl.creditPolicyNew('is')]: 'Är',
  [intl.creditPolicyNew('is-not')]: 'Är inte',
  [intl.creditPolicyNew('number-of-employees')]: 'Antal anställda',
  [intl.creditPolicyNew('risika-score-node-title')]: 'Risika Score',
  [intl.creditPolicyNew('company_vat')]:
    'I kombination med andra faktorer som företagets ålder, ledningens senioritet eller omsättning kan företagets moms vara en bra indikator på bedrägeri. Om de inte är undantagna bör de flesta företag vara registrerade för moms. Om de inte är det kan de utgöra en risk på grund av sin storlek eller så kan det vara en indikation på att de döljer en del av sin verksamhet.',
  [intl.creditPolicyNew('drag-drop-policy')]: 'Dra en fil hit (JSON)',
  [intl.creditPolicyNew('cancel')]: 'Avbryt',
  [intl.creditPolicyNew('get-started')]: 'Kom igång',
  [intl.creditPolicyNew('export')]: 'Exportera',
  [intl.creditPolicyNew('duplicate')]: 'Duplicera',
  [intl.creditPolicyNew('edit')]: 'Redigera',
  [intl.creditPolicyNew('edit-policy-settings')]: 'Redigera policyinställningar',
  [intl.creditPolicyNew('delete')]: 'Radera',
  [intl.creditPolicyNew('print')]: 'Skriv ut',
  [intl.creditPolicyNew('share')]: 'Dela',
  [intl.creditPolicyNew('rename')]: 'Byt namn',
  [intl.creditPolicyNew('export-success')]: 'Kreditpolicyn {policyName} har exporterats',
  [intl.creditPolicyNew('export-error')]: 'Något gick fel :(',
  [intl.creditPolicyNew('duplicate-success')]: 'Kreditpolicyn duplicerades framgångsrikt',
  [intl.creditPolicyNew('duplicate-error')]: 'Något gick fel :(',
  [intl.creditPolicyNew('delete-success')]: 'Kreditpolicyn raderades framgångsrikt',
  [intl.creditPolicyNew('delete-error')]: 'Något gick fel :(',
  [intl.creditPolicyNew('search-action')]: 'Sök efter en åtgärd',
  [intl.creditPolicyNew('deactivate-and-publish')]: 'Inaktivera och publicera',
  [intl.creditPolicyNew('save-as-draft')]: 'Spara som utkast',
  [intl.creditPolicyNew('test')]: 'Test',
  [intl.creditPolicyNew('test-policy-title')]: 'Test',
  [intl.creditPolicyNew('test-policy-side-panel-label')]: 'Your lists',

  [intl.creditPolicyNew('select-a-list-to-test-policy-on')]:
    'Select a list to test this policy on',
  [intl.creditPolicyNew('your-policy-will-remain-unpublished')]:
    'Your policy will remain' +
    ' unpublished' +
    ' and' +
    ' you' +
    ' will' +
    ' still' +
    ' be' +
    ' able' +
    ' to make changes\n' +
    '            to it.',
  [intl.creditPolicyNew('test-and-download-button')]: 'Test and download results',
  [intl.creditPolicyNew('company_information')]: 'Företagsinformation',
  [intl.creditPolicyNew('distance-to-ceo-node-title')]: 'Avstånd till CEO',
  [intl.creditPolicyNew('distance_to_ceo')]: 'Avstånd till CEO',
  [intl.creditPolicyNew('distance-to-ceo')]: 'Avstånd till CEO',
  [intl.creditPolicyNew('status')]: 'Företagsstatus',
  [intl.creditPolicyNew('risk_indicators')]: 'Riskindikatorer',
  [intl.creditPolicyNew('fraud_indicators')]: 'Bedrägeriindikatorer',
  [intl.creditPolicyNew('people_and_company_search')]: 'Person- och företagssökning',
  [intl.creditPolicyNew('financial_indicators')]: 'Finansiella indikatorer',
  [intl.creditPolicyNew('payment_history')]: 'Betalningshistorik',
  [intl.creditPolicyNew('advanced')]: 'Avancerad',
  [intl.creditPolicyNew('outcome')]: 'Utfall',
  [intl.creditPolicyNew('company_type')]: 'Företagstyp',
  [intl.creditPolicyNew('new-monitoring-list-name')]: 'Namn',
  [intl.creditPolicyNew('new-monitoring-list-add')]: 'Lägg till företag i din lista',
  [intl.creditPolicyNew('new-monitoring-list-label')]: 'Namn på listan',
  [intl.creditPolicyNew('industry_type')]: 'Bransch',
  [intl.creditPolicyNew('number_of_employees')]: 'Antal anställda',
  [intl.creditPolicyNew('years_of_existence')]: 'År i existens/Företagets ålder',
  [intl.creditPolicyNew('ownership_structure')]: 'Ägarstruktur',
  [intl.creditPolicyNew('policy-card-last-updated')]:
    'av {ownerId} senast uppdaterad den: {date}',
  [intl.creditPolicyNew('risika_score')]: 'Risika score',
  [intl.creditPolicyNew('only-one-active-policy-title')]:
    'Du kan bara ha en aktiv policy per land',
  [intl.creditPolicyNew('audit')]: 'Revision',
  [intl.creditPolicyNew('connected_bankruptcies')]: 'Anknutna konkurser',
  [intl.creditPolicyNew('distance_to_ceo')]: 'Avstånd till VD',
  [intl.creditPolicyNew('prepaid_phone')]: 'Förbetalt kontantkort',
  [intl.creditPolicyNew('people_search')]: 'Person sök',
  [intl.creditPolicyNew('company_search')]: 'Företag sök',
  [intl.creditPolicyNew('revenue')]: 'Intäkter',
  [intl.creditPolicyNew('debt')]: 'Skuld',
  [intl.creditPolicyNew('equity')]: 'Eget kapital',
  [intl.creditPolicyNew('relative_debt_change')]: 'Relativ skuldändring',
  [intl.creditPolicyNew('ebitda')]: 'EBITDA',
  [intl.creditPolicyNew('ebit')]: 'EBIT',
  [intl.creditPolicyNew('short_term_tax_payable')]: 'Kortfristig skatteskyldig',
  [intl.creditPolicyNew('payment_remarks')]: 'Betalningsanmärkningar',
  [intl.creditPolicyNew('invoice_data')]: 'Fakturadata',
  [intl.creditPolicyNew('debt_to_equity_ratio')]: 'Skuld till eget kapital förhållande',
  [intl.creditPolicyNew('solidity_ratio')]: 'Soliditetsgrad',
  [intl.creditPolicyNew('return_on_assets')]: 'Avkastning på tillgångar (ROA)',
  [intl.creditPolicyNew('return_on_equity')]: 'Avkastning på eget kapital',
  [intl.creditPolicyNew('asset_turnover')]: 'Tillgångsomslag',
  [intl.creditPolicyNew('approve_credit')]: 'Godkänn kredit',
  [intl.creditPolicyNew('dropdown_multi_select')]: '{companyList}',
  [intl.creditPolicyNew('manual_check')]: 'Manuell kontroll',
  [intl.creditPolicyNew('reject_credit')]: 'Avvisa kredit',

  [intl.creditPolicyNew('publish')]: 'Spara och publicera',
  [intl.creditPolicyNew('edit-an-active-policy-edit-a-copy')]: 'Edit a copy',
  [intl.creditPolicyNew('edit-an-active-policy-edit-this-version')]: 'Edit this version',

  [intl.creditPolicyNew('error-loading-policies')]:
    'Sorry we can’t load your policies at the' + ' moment, please try again later.',
  [intl.creditPolicyNew('error-loading-policy')]:
    'Sorry we can’t load your policy at the moment, please try again later.',
  [intl.creditPolicyNew('error-loading-ecp-builder-actions')]:
    "Sorry we can't load this panel at the moment, please try again later.",

  [intl.creditPolicyNew('edit-an-active-policy-body')]:
    'You are trying to edit an active policy, which could impact the performance of your policy. {lineBreak} {lineBreak} For this reason, we will temporarily deactivate your policy whilst you’re making changes. If you would like your policy to remain active, edit a copy instead.',

  [intl.creditPolicyNew('save-incomplete-policy-body')]:
    "We've noticed some branches in your policy appear to be empty. In order for Risika to apply your credit policy accurately, it's important to ensure that each branch is set with an outcome. This will help to make sure that your policy addresses every possible scenario.",

  [intl.creditPolicyNew('save-incomplete-policy-title')]:
    'Make sure your policy is complete before publishing it',

  [intl.creditPolicyNew('pro_tips_title')]: 'Proffstips',
  [intl.creditPolicyNew('tips_equity')]:
    'företaget har tillräckligt med tillgångar för att täcka sina skulder. Om negativt',
  [intl.creditPolicyNew('tips_company_type')]:
    'kræve andre kreditbetingelser end børsnoterede selskaber.',
  [intl.creditPolicyNew('tips_prepaid_phone')]:
    'Forhåndsbetalte kontantkort kan være en indikator på at et selskap driver med svindelaktiviteter. Vi anbefaler bare å sjekke forhåndsbetalte telefoner for private selskaper.',
  [intl.creditPolicyNew('tips_number_of_employees')]:
    'Användning av antalet anställda kan ge en indikation på företagets storlek och ekonomiska hälsa - och därmed dess potentiella motståndskraft mot finanskriser.',
  [intl.creditPolicyNew('tips_audit')]: 'der har gennemgået en revision',
  [intl.creditPolicyNew('tips_industry_type')]:
    'Använd branschtyp för att tillämpa dina kreditpolicyregler på företag i olika branscher. Till exempel kan företagstyper inom tillverkning kräva olika kreditvillkor än företag inom finansiella tjänster.',
  [intl.creditPolicyNew('tips_status')]:
    'Inaktive selskaper er selskaper som har opphørt å eksistere eller er oppløst.',
  [intl.creditPolicyNew('tips_company_list')]: 'før du kan bruge den i din politik.',
  [intl.creditPolicyNew('tips_approve_credit')]: 'Godkend kredit sætter kreditgrænsen',
  [intl.creditPolicyNew('tips_manual_check')]:
    'hvor meget kredit eller ikke du vil give dem.',
  [intl.creditPolicyNew('tips_reject_credit')]:
    'Avvist kreditt er en kreditt som har blitt avvist av kredittretningslinjene.',
  [intl.creditPolicyNew('something-went-wrong-credit-policy')]: 'kontakt kundesupport.',
  [intl.creditPolicyNew('back-to-credit-policy')]: 'Tillbaka till kreditpolicy',
  [intl.creditPolicyNew('invalid-country-message')]: 'Du måste ange ett giltigt land',
  [intl.creditPolicyNew('invalid-country-message')]:
    'Vi kunde inte spara din policy. Försök igen.',
  [intl.creditPolicyNew('we-where-not-able-to-save-your-policy')]: 'Spara och aktivera',
  [intl.creditPolicyNew('save-and-activate')]: 'Börja skapa din första regel',
  [intl.creditPolicyNew('create-you-first-rule-button')]: 'Börja skapa din första regel',
  [intl.creditPolicyNew('description-text')]: 'Beskrivning (valfritt)',
  [intl.creditPolicyNew('description-only')]:
    'Avvisa alltid inaktiva företag och konkurser',
  [intl.creditPolicyNew('always-reject-inactive-companies')]: 'Kom igång',
  [intl.creditPolicyNew('get-started-text')]: 'Redigera policyinställningar',
  [intl.creditPolicyNew('edit-policy-settings')]: 'Redigera policyinställningar',
  [intl.creditPolicyNew('active-companies-only')]: 'Endast aktiva företag',
  [intl.creditPolicyNew('equity_node_content')]: 'select',
  [intl.creditPolicyNew('already-active-dialog')]:
    'The policy "{activeName}" is already active in ' +
    '{country, select,' +
    'DK {Denmark}' +
    'SE {Sweden}' +
    'NO {Norway}' +
    'other {this country}}' +
    '. In order to publish "{currentName}", the existing policy will need to be deactivated' +
    ' temporarily.',
  [intl.creditPolicyNew('status-node-text')]: '',
  [intl.creditPolicyNew('industry_type_node_content')]: 'Er {industryList}',
  [intl.creditPolicyNew('number-of-employees-node-text')]:
    '{tolerance, select, greater_than {Greater than} equals {Equal to} less_than {Less than}' +
    ' between {Between}' +
    ' other' +
    ' {...}}' +
    ' {max, plural, =0 {{min}} =1 {{min} and {max}} other {{min} and {max}}}',

  [intl.creditPolicyNew(TOLERANCE_INPUT)]:
    '{tolerance}' +
    ' {max, plural, =0 {{min}} =1 {{min} and {max}} other {{min} and {max}}}',
  [intl.creditPolicyNew('risika-score-node-text')]:
    '{tolerance, select, greater_than {Greater than} equals {Equal to} less_than {Less than}' +
    ' between {Between}' +
    ' other' +
    ' {...}' +
    ' {max, plural, =0 {{min}} =1 {{min} and {max}} other {{min} and {max}}}',

  [intl.creditPolicyNew('reject-credit-node-text')]:
    'false {No notes available} other {{description}}}',
  [intl.creditPolicyNew('manual-check-node-text')]:
    'false {No notes available} other {{description}}}',
  [intl.creditPolicyNew('adjust-credit-max-node-text')]:
    '{creditMax} {percentage}% av Risikas' + ' rekommendation {lineBreak}',
  [intl.creditPolicyNew('adjust-credit-max-node-text-currency')]:
    '  {limit} {amount} {currency}',
  [intl.creditPolicyNew('approve-with-value-node-text')]:
    'Godkend med værdi {value} {currency}',
  [intl.creditPolicyNew('COMPANY_LIST')]: 'Företagslista',
  [intl.creditPolicyNew('COMPANY_LIST + -node-text')]: 'Bevakningslista {listId}',
  [intl.creditPolicyNew('NEW_MONITORING_LIST')]: 'Skapa en lista',
  [intl.creditPolicyNew('tips_ + COMPANY_LIST')]:
    'der er tilknyttet +  virksomheden i spørgsmålet',
  [intl.creditPolicyNew('tips_risika_score')]: '',
  [intl.creditPolicyNew('\n +')]: '',
  [intl.creditPolicyNew('For companies that have a low level of financial data')]: '',
  [intl.creditPolicyNew('\n +')]: '',
  [intl.creditPolicyNew('For companies that have insufficient financial data')]:
    'so no score or assessment is provided.',
  [intl.creditPolicyNew('')]: '',
  [intl.creditPolicyNew('industry-type')]: 'Bransch',
  [intl.creditPolicyNew('back-to-home')]: 'Tillbaka till startsidan',
  [intl.creditPolicyNew('companies-in-country')]: 'Företag i {country}',
  [intl.creditPolicyNew('credit-policy-builder-history-title')]: 'Kreditpolicybyggare',
  [intl.creditPolicyNew('company-type')]: 'Företagstyp',
  [intl.creditPolicyNew('risika-score')]: 'Risika Score',
  [intl.creditPolicyNew('cvr-number')]: 'Organisationsnummer',
  [intl.creditPolicyNew('prepaid-phone')]: 'Kontantkort',
  [intl.creditPolicyNew('distance-to-ceo')]: 'Avstånd till VD',
  [intl.creditPolicyNew('audit')]: 'Revision',
  [intl.creditPolicyNew('equity')]: 'Eget kapital',
  [intl.creditPolicyNew('accept-credit')]: 'Acceptera kredit',
  [intl.creditPolicyNew('manual-check')]: 'Manuell kontroll',
  [intl.creditPolicyNew('reject-credit')]: 'Avvisa kredit',
  [intl.creditPolicyNew('condition')]: 'Villkor',
  [intl.creditPolicyNew('type')]: 'Typ',
  [intl.creditPolicyNew('tolerance')]: 'Tolerans',
  [intl.creditPolicyNew('delete-rule')]: 'Radera en regel',
  [intl.creditPolicyNew('delete-rule-button-label')]: 'Radera',
  [intl.creditPolicyNew('delete-this-rule')]: 'Radera denna regel',
  [intl.creditPolicyNew('import-a-policy-button')]: 'Importera en policy',
  [intl.creditPolicyNew('create-a-policy-button')]: 'Skapa en ny policy',
  [intl.creditPolicyNew('terms')]: 'Villkor',
  [intl.creditPolicyNew('method')]: 'Metod',
  [intl.creditPolicyNew('credit-definition')]: 'Kreditdefinition',
  [intl.creditPolicyNew('define_credit_max')]: 'Definiera kreditmax',
  [intl.creditPolicyNew('define_payment_terms')]: 'Definiera betalningsvillkor',
  [intl.creditPolicyNew('use_risika_recommendation')]: 'Använd Risika rekommendation',
  [intl.creditPolicyNew('set_your_own')]: 'Definiera ditt eget',
  [intl.creditPolicyNew('set_your_own_value')]: 'Sätt ditt eget värde',
  [intl.creditPolicyNew('percentage_of_risika_score')]: 'Procent av Risika Score',
  [intl.creditPolicyNew('method')]: 'Metod',
  [intl.creditPolicyNew('value')]: 'Värde',
  [intl.creditPolicyNew('not_greater_than')]: 'Inte större än',
  [intl.creditPolicyNew('not_lower_than')]: 'Inte lägre än',
  [intl.creditPolicyNew('set-limit')]: 'Ange gräns (valfritt)',
  [intl.creditPolicyNew('create-first-policy')]: 'Börja skapa din första policy',
  [intl.creditPolicyNew('create-first-policy-cta')]:
    'Skapa en ny policy från grunden eller ',
  [intl.creditPolicyNew('import')]: 'importera',
  [intl.creditPolicyNew('existing-one')]: ' en befintlig',
  [intl.creditPolicyNew('you-have-no-policies')]:
    ' Ditt team har för närvarande inga policys',

  [intl.creditPolicyNew('is')]: 'Är',
  [intl.creditPolicyNew('is-not')]: 'Är inte',
  [intl.creditPolicyNew('equity')]: 'Inklusive utdelning',
  [intl.creditPolicyNew('equity_without_dividend')]: 'Exklusive utdelning',
  [intl.creditPolicyNew('greater-than')]: 'Större än',
  [intl.creditPolicyNew('greater_than')]: 'Större än',
  [intl.creditPolicyNew('equal-to')]: 'Lika med',
  [intl.creditPolicyNew('equals')]: 'Lika med',
  [intl.creditPolicyNew('less-than')]: 'Lägre än',
  [intl.creditPolicyNew('less_than')]: 'Lägre än',
  [intl.creditPolicyNew('between')]: 'Mellan',
  [intl.creditPolicyNew('select')]: 'Välj',
  [intl.creditPolicyNew('value')]: 'Värde',
  [intl.creditPolicyNew('prepaid-phone-status')]: 'Status för förbetalt kontantkort',
  [intl.creditPolicyNew('registered-prepaid-phone')]: 'Registrerad kontantkortstelefon',
  [intl.creditPolicyNew('no-prepaid-phone-registered')]:
    'Ingen kontantkortstelefon registrerad',
  [intl.creditPolicyNew('no-data-yet')]: 'Fortfarande ingen data...',
  [intl.creditPolicyNew('distance-to-ceo-node-title')]: 'Avstånd till VD',
  [intl.creditPolicyNew('equity-node-title')]: 'Eget kapital',
  [intl.creditPolicyNew('prepaid-phone-node-title')]: 'Kontantkortstelefon',
  [intl.creditPolicyNew('something-wrong-with-node-data')]: 'Ingen eller skadade data',
  [intl.creditPolicyNew('no-companies-in-list')]: 'Inga företag i listan',
  [intl.creditPolicyNew('action-panel-cancel-button')]: 'Avbryt',
  [intl.creditPolicyNew('action-panel-confirm-button')]: 'Bekräfta',
  [intl.creditPolicyNew('manual-check-description-label')]:
    'Lägg till anteckningar (valfritt)',
  [intl.creditPolicyNew('reject-credit-description-label')]:
    'Lägg till anteckningar (valfritt)',
  [intl.creditPolicyNew('company-list-description-label')]: 'Välj en lista',
  [intl.creditPolicyNew('company-list-create-new')]: 'Skapa en ny lista',
  [intl.creditPolicyNew('search-multi-select-apply')]: 'Tillämpa',
  [intl.creditPolicyNew('search-multi-select-clear-all')]: 'Rensa alla',
  // ------

  [intl.creditPolicyNew('choose-file')]: 'Välj fil',
  [intl.creditPolicyNew('tips_status')]:
    'Inaktiva företag är företag som har upphört, upplösts, är i konkurs eller har tvingats stänga... Det rekommenderas att utesluta sådana företag från din försäkring för att säkerställa att du inte beviljar dem kredit.',
  [intl.creditPolicyNew('select-action')]: 'Välj åtgärd',
  [intl.creditPolicyNew('search-for-an-action')]: 'Sök efter en åtgärd',
  [intl.creditPolicyNew('are-you-sure-you-want-to-delete-this-policy')]:
    'Är du säker på att du vill ta bort policyn "{name}"?',
  [intl.creditPolicyNew('delete-policy-description')]:
    'De regler som fastställs i denna policy kommer att tas bort permanent och du kommer att sluta få varningar från de företag som den gäller..',
  [intl.creditPolicyNew('delete-policy-action')]: 'Skriv <b>{name}</b> för att bekräfta:',
  [intl.creditPolicyNew('created-on')]: 'Skapad den',
  [intl.creditPolicyNew('last-updated')]: 'Senast uppdaterad',
  [intl.creditPolicyNew(TOLERANCE_INPUT)]:
    '{tolerance}' +
    ' {max, plural, =0 {{min}} =1 {{min} och {max}} other {{min} och {max}}}',
  [intl.creditPolicyNew('industry_code_node_content')]: 'Är {industryList}',

  [intl.creditPolicyNew('connected-bankruptcies-node-content')]:
    'I den sista ' +
    '{category, plural, =0 {10} other {50}} år är ' +
    '{tolerance}' +
    ' {max, plural, =0 {{min}} =1 {{min} and {max}} other {{min} och {max}}}',

  [intl.creditPolicyNew('equity-node-content')]:
    'Eget kapital ' +
    '{category, plural, =0 {inklusive} other {exklusive}} utdelning är ' +
    '{tolerance}' +
    ' {max, plural, =0 {{min}} =1 {{min} and {max}} other {{min} och {max}}}',

  [intl.creditPolicyNew('restriction-modal-title')]:
    'Uppgradera till Pro och lås upp avancerade funktioner',
  [intl.creditPolicyNew('restriction-modal-list-1')]:
    'Anpassa din kreditpolicy med ytterligare 12 variabler',
  [intl.creditPolicyNew('restriction-modal-list-2')]:
    'Få åtkomst till data i 3 nordiska länder',
  [intl.creditPolicyNew('restriction-modal-list-3')]:
    'Övervaka upp till 500 företag per månad',
  [intl.creditPolicyNew('restriction-modal-paragraph')]:
    'Prata med en försäljningsrepresentant för att diskutera dina behov och hitta den bästa lösningen för ditt företag',

  [intl.creditPolicyNew('please-select')]: 'Vänligen välj',
  [intl.creditPolicyNew('none')]: 'Ingen',
  [intl.creditPolicyNew('1')]: '1',
  [intl.creditPolicyNew('2')]: '2',
  [intl.creditPolicyNew('3')]: '3',
  [intl.creditPolicyNew('4')]: '4',
  [intl.creditPolicyNew('5')]: '5',
  [intl.creditPolicyNew('6')]: '6',
  [intl.creditPolicyNew('7')]: '7',
  [intl.creditPolicyNew('8')]: '8',
  [intl.creditPolicyNew('9')]: '9',
  [intl.creditPolicyNew('10')]: '10',
  [intl.creditPolicyNew('1-year')]: '1 år',
  [intl.creditPolicyNew('2-years')]: '2 år',
  [intl.creditPolicyNew('3-years')]: '3 år',
  [intl.creditPolicyNew('4-years')]: '4 år',
  [intl.creditPolicyNew('5-years')]: '5 år',
  [intl.creditPolicyNew('6-years')]: '6 år',
  [intl.creditPolicyNew('7-years')]: '7 år',
  [intl.creditPolicyNew('8-years')]: '8 år',
  [intl.creditPolicyNew('9-years')]: '9 år',
  [intl.creditPolicyNew('10-years')]: '10 år',
  [intl.creditPolicyNew('1-and-more')]: '1 och fler',
  [intl.creditPolicyNew('2-and-more')]: '2 och fler',
  [intl.creditPolicyNew('pro_tips_title')]: 'Proffstips',
  [intl.creditPolicyNew('3-and-more')]: '3 och fler',
  [intl.creditPolicyNew('4-and-more')]: '4 och fler',
  [intl.creditPolicyNew('5-and-more')]: '5 och fler',
  [intl.creditPolicyNew('6-and-more')]: '6 och fler',
  [intl.creditPolicyNew('7-and-more')]: '7 och mer',
  [intl.creditPolicyNew('8-and-more')]: '8 och mer',
  [intl.creditPolicyNew('9-and-more')]: '9 och uppåt',
  [intl.creditPolicyNew('10-and-more')]: '10 och uppåt',

  [intl.creditPolicyNew('less-than-year')]: 'Mindre än ett år gammalt',
  [intl.creditPolicyNew('1-year-old')]: '1 år gammalt',
  [intl.creditPolicyNew('2-year-old')]: '2 år gammalt',
  [intl.creditPolicyNew('3-year-old')]: '3 år gammalt',
  [intl.creditPolicyNew('4-year-old')]: '4 år gammalt',
  [intl.creditPolicyNew('5-year-old')]: '5 år gammalt',

  [intl.creditPolicyNew('search-multi-select-apply')]: 'Tillämpa',
  [intl.creditPolicyNew('search-multi-select-clear-all')]: 'Avmarkera alla',
  [intl.creditPolicyNew('select')]: 'Välj',
  [intl.creditPolicyNew('cancel')]: 'Avbryt',
  [intl.creditPolicyNew('prepaid-phone')]: 'Förbetald telefon',

  [intl.creditPolicyNew('company-type')]: 'Företagstyp',

  [intl.creditPolicyNew('not-registered')]:
    'Exkludera alla företag som inte är momsregistrerade',
  [intl.creditPolicyNew('should-be-registered')]:
    'Exkludera endast företag som borde vara momsregistrerade',
  [intl.creditPolicyNew('company-type-desc')]:
    'Lista företagstyper du vill avvisa från din policy',
  [intl.creditPolicyNew('company-years-title')]: 'Företagets ålder',
  [intl.creditPolicyNew('company-years-desc')]:
    'Välj den lägsta åldern för ett företag som krävs för att vara inkluderad i policyn',
  [intl.creditPolicyNew('industry-type-title')]: 'Branschtyp',
  [intl.creditPolicyNew('industry-type-desc')]:
    'Uppge branschtyper du vill exkludera från din policy',
  [intl.creditPolicyNew('number-of-employees-title')]: 'Antal anställda',
  [intl.creditPolicyNew('number-of-employees-desc')]:
    'Företaget har färre anställda än vad som accepteras i din kreditpolicy',
  [intl.creditPolicyNew('risika-score-title')]: 'Risika score',
  [intl.creditPolicyNew('risika-score-desc')]:
    'Fastställ den minsta Risika score som krävs för att ett företag ska godkännas enligt din policy',
  [intl.creditPolicyNew('bankruptcy-short-limit-desc')]:
    'Välj det maximala antalet sammanlänkade konkurser under de senaste 10 åren som tillåts enligt din policy',
  [intl.creditPolicyNew('bankruptcy-long-limit-desc')]:
    'Välj det maximala antalet sammanlänkade konkurser under de senaste 50 åren som tillåts enligt din policy',
  [intl.creditPolicyNew('auditor-id-title')]: 'Revisionsbyrå ID-nummer',
  [intl.creditPolicyNew('auditor-id-label')]:
    'Avvisa företag där revisionsbyrån inte kan uppge ID-nummer',
  [intl.creditPolicyNew('distance-to-ceo-desc')]:
    'Fastställ tröskelavståndet för VD:s närhet till företagets huvudkontor för att vara berättigad till policyskydd',
  [intl.creditPolicyNew('people-id-title')]: 'Specifika personer',
  [intl.creditPolicyNew('people-id-desc')]:
    'Välj specifika personer du vill undanta från din policy',
  [intl.creditPolicyNew('company-id-title')]: 'Specifikt företag',
  [intl.creditPolicyNew('company-id-desc')]:
    'Välj företagen du vill exkludera från din policy',
  [intl.creditPolicyNew('equity-desc')]:
    'Ange det minsta antalet år ett företag har upprätthållit positivt eget kapital för att uppfylla din kreditpolicy',
  [intl.creditPolicyNew('company-vat-title')]: 'Företagsmoms',
  [intl.creditPolicyNew('company-vat-desc')]:
    'Välj momskriterierna som ett företag måste uppfylla för att godkännas enligt din policy',
  [intl.creditPolicyNew('company-type-placeholder')]: 'Sök efter en företagstyp',
  [intl.creditPolicyNew('industry-type-placeholder')]: 'Sök efter en branschtyp',
  [intl.creditPolicyNew('search-company-type-placeholder')]: 'Sök efter företag',
  [intl.creditPolicyNew('search-people-placeholder')]: 'Sök efter personer',

  [intl.creditPolicy('POLICY_DEBT')]: 'Skuld',
  [intl.creditPolicyNew('debt-desc')]:
    'Specificera det högsta beloppet på utestående skuld som ett företag kan ha för att kvalificera sig enligt din policy',
  [intl.creditPolicy('POLICY_SHORT_TERM_DEBT')]: 'Kortfristig skuld',
  [intl.creditPolicyNew('short-term-debt-desc')]:
    'Specificera det högsta beloppet på kortfristig skuld som ett företag kan ha för att kvalificera sig enligt din policy',
  [intl.creditPolicy('POLICY_PROFIT_LOSS')]: 'Resultat efter skatt',
  [intl.creditPolicyNew('result-after-tax-desc')]:
    'Ange den minsta nivån av post-skatt vinst som krävs för att ett företag ska uppfylla kriterierna för att vara berättigat till din policy',
  [intl.creditPolicy('POLICY_PAYMENT_REMARKS_COUNT')]: 'Antal betalningsanmärkningar',
  [intl.creditPolicyNew('payment-remarks-desc')]:
    'Ange det högsta antalet aktiva betalningsanmärkningar som ett företag kan ha för att kvalificera sig för kredit',
  [intl.creditPolicy('POLICY_PAYMENT_REMARKS_TOTAL_AMOUNT')]:
    'Utestående belopp på betalningsanmärkningar',
  [intl.creditPolicyNew('amount-due-desc')]:
    'Definiera det högsta utestående beloppet på aktiva betalningsanmärkningar som ett företag kan ha för att vara berättigat till kredit',
  [intl.creditPolicy('POLICY_PAYMENT_REMARKS_CREDITORS_COUNT')]:
    'Antal borgenärer som är kopplade till betalningsanmärkningar',
  [intl.creditPolicyNew('number-of-creditors-desc')]:
    'Ange det högsta antalet borgenärer som ett företag kan ha aktiva betalningsanmärkningar med för att uppfylla kraven för att vara berättigat till kredit',
  [intl.creditPolicy('POLICY_PAYMENT_REMARKS_MAX_REPAYMENT_TIME')]:
    'Tid för att återbetala aktiva betalningsanmärkningar',
  [intl.creditPolicyNew('average-repayment-duration-desc')]:
    'Sätt en begränsning för hur länge betalningsanmärkningar kan vara aktiva innan ett företag inte längre är berättigat enligt din policy',
  [intl.creditPolicyNew('audit-level-title')]: 'Revisionsnivå',
  [intl.creditPolicyNew('audit-level-desc')]:
    'Fastställ den nödvändiga revisionsnivån för ett företag att godkännas för kredit',
  [intl.creditPolicyNew('reject_prepaid_phone')]:
    'Avvisa företag med en förbetald telefon',
  [intl.creditPolicyNew('reject_registered_phone')]:
    'Avvisa företag som inte har ett offentligt registrerat telefonnummer',
  [intl.creditPolicyNew('tips_company_vat')]:
    'Tillsammans med andra faktorer såsom Företagets ålder, Ledningens erfarenhet eller Omsättning, kan Företagsmoms vara en bra indikator på bedrägeri. Om inte befriad, bör de flesta företag vara momsregistrerade. Om de inte är det kan de utgöra en risk på grund av sin storlek eller det kan vara en indikation på att de döljer en del av sin verksamhet.',
  [intl.creditPolicyNew('tips_payment_remarks')]:
    'Vår data täcker de senaste fyra åren och spårar uteslutande aktiva betalningsanmärkningar. Det innebär att betalningsanmärkningar räknas som sådana tills hela det förfallna beloppet har betalats. Vi samlar in betalningsanmärkningar från Intrum, Visma och Kredinor. Du kan läsa mer om detta i vår sekretesspolicy',
  [intl.creditPolicyNew('dk_tips_distance_to_ceo')]:
    'I Danmark har företag vars VD bor mer än 25 km bort från det registrerade huvudkontoret en genomsnittlig nödlidanderate på 2,83%.',
  [intl.creditPolicyNew('pro_tips_title')]: 'Proffstips',

  [intl.creditPolicyNew('always-reject-inactive-companies')]: 'Reject Inactive companies',
  [intl.creditPolicyNew('get-started-text')]: 'Get Started',
  [intl.creditPolicyNew('active-companies-only')]: 'Active companies only',
  [intl.creditPolicyNew('save-changes')]: 'Spara ändringar',
  [intl.creditPolicyNew('inactive')]: 'Inaktiv',
  [intl.creditPolicyNew('active')]: 'Aktiv',
  [intl.creditPolicyNew('policy-published')]: 'Publicerad',
  [intl.creditPolicyNew('policy-draft')]: 'Utkast',
  [intl.creditPolicyNew('company_information')]: 'Företagsinformation',
  [intl.creditPolicyNew('risk_indicators')]: 'Riskindikatorer',
  [intl.creditPolicyNew('fraud_indicators')]: 'Bedrägeriindikatorer',
  [intl.creditPolicyNew('people_and_company_search')]:
    'Sökning efter personer och företag',
  [intl.creditPolicyNew('financial_indicators')]: 'Finansiella indikatorer',
  [intl.creditPolicyNew('save_modal_title')]: 'Spara ändringar i din policy',
  [intl.creditPolicyNew('save_modal_text')]:
    'Det verkar som att du har gjort några ändringar i din kreditpolicy. Är du säker på att du vill lämna utan att spara?',
  [intl.creditPolicyNew('activation_modal_title')]: 'Aktivera en policy',
  [intl.creditPolicyNew('activation_modal_text')]:
    'Du är på väg att aktivera denna policy. Reglerna som den innehåller kommer nu att informera om kreditrekommendationer och dessa ändringar kommer att påverka hela din organisation. Är du säker på att du vill fortsätta?',
  [intl.creditPolicyNew('deactivation_modal_title')]: 'Inaktivera en policy',
  [intl.creditPolicyNew('deactivation_modal_text')]:
    'Du är på väg att inaktivera denna policy. Reglerna i din policy kommer inte längre att gälla och denna ändring kommer att påverka hela din organisation. Är du säker på att du vill fortsätta?',

  [intl.creditPolicyNew('equity')]: 'Eget kapital',
  [intl.creditPolicyNew('pro_tips_title')]: 'Proffstips',

  [intl.creditPolicyNew('node-between-2-nodes-text-1')]:
    'Du lägger till en åtgärd i mitten av en befintlig serie åtgärder',
  [intl.creditPolicyNew('node-between-2-nodes-text-2')]:
    'Välj den gren som resten av åtgärderna ska fortsätta på',
  [intl.creditPolicyNew('node-between-2-nodes-option-yes')]: '"Ja"-gren',
  [intl.creditPolicyNew('node-between-2-nodes-option-no')]: '"Nej"-gren',
  [intl.creditPolicyNew('upgrade-advanced-fraud-indicators')]:
    'Uppgradera för att låsa upp avancerade bedrägeriförebyggande indikatorer',
  [intl.creditPolicyNew('additional-fraud-variables')]:
    'Ytterligare 12 bedrägeriförebyggande variabler',
  [intl.creditPolicyNew('limit-fraud-risk')]: 'Begränsa bedrägeririsken i ditt företag',
  [intl.creditPolicyNew('monitor-fraud-indicators')]:
    'Övervaka bedrägeriförebyggande indikatorer hos dina kunder',
  [intl.creditPolicyNew('talk-to-sales-rep')]:
    'Prata med en försäljningsrepresentant för att diskutera dina behov och hitta den bästa lösningen för ditt företag',
  [intl.creditPolicyNew('request-call-back')]: 'Begär att bli uppringd',
  [intl.creditPolicyNew('fraud-indicators-promotion')]:
    'Lås upp avancerede svindelindikatorer',
  [intl.creditPolicyNew('unsaved-changes')]: 'Du har osparade ändringar',
  [intl.creditPolicyNew('discard-changes')]: 'Kassera ändringar',
  [intl.creditPolicyNew('cancel')]: 'Avbryt',
  [intl.creditPolicyNew('undo')]: 'Ångra',

  [intl.customerManagement('add-new-users')]: 'Lägg till nya användare',
  [intl.customerManagement('are-you-sure-dialog')]:
    'Är du säker på att du vill återkalla åtkomst för det här företaget?',
  [intl.customerManagement('customer-plan')]: 'Kundplan',
  [intl.customerManagement('customer-plan-basic')]: 'Grundläggande',
  [intl.customerManagement('customer-plan-enterprise')]: 'Företag',
  [intl.customerManagement('customer-plan-freemium')]: 'Freemium',
  [intl.customerManagement('customer-plan-pro')]: 'Proffs',
  [intl.customerManagement('customer-plan-standard')]: 'Standard',
  [intl.customerManagement('customer-plan-trial')]: 'Rättegång',
  [intl.customerManagement('customer-type')]: 'Kundtyp',
  [intl.customerManagement('customer-type-customer')]: 'Kund',
  [intl.customerManagement('customer-type-lost-customer')]: 'Förlorad kund',
  [intl.customerManagement('customer-type-lost-trial')]: 'Förlorad rättegång',
  [intl.customerManagement('customer-type-risika')]: 'Risika (intern)',
  [intl.customerManagement('customer-type-trial')]: 'Rättegång',
  [intl.customerManagement('customer-updated')]: 'Kunden uppdaterad',
  [intl.customerManagement('existing-customers')]: 'Existerande kunder',
  [intl.customerManagement('existing-users')]: 'Befintliga användares',
  [intl.customerManagement('expiry-date')]: 'Utgångsdatum',
  [intl.customerManagement('handle-access')]: 'Återkalla åtkomst',
  [intl.customerManagement('manage-customer')]: 'Hantera kund',
  [intl.customerManagement('my-customers')]: 'Mina kunders',
  [intl.customerManagement('new-customer')]: 'Ny kund',
  [intl.customerManagement('new-customer-title')]: 'Skapa ny kund',
  [intl.customerManagement('responsible-person')]: 'Ställ ansvarig person',
  [intl.customerManagement('revoke-access')]: 'Återkalla åtkomst',
  [intl.customerManagement('title')]: 'Kundhantering',
  [intl.customerManagement('users')]: 'Användarnas',
  [intl.developer('confirm-remove-webhook-description')]:
    'OBS: om du tar bort webhooken och bestämmer dig för att skapa en ny kommer en ny hemlighet att genereras.',
  [intl.developer('confirm-remove-webhook-title')]:
    'Är du säker på att du vill ta bort din webhook?',
  [intl.developer('documentation')]: 'Dokumentation',
  [intl.developer('title')]: 'Utvecklaren',
  [intl.developer('webhook-add-info')]:
    'För mer information om användningen av webhooks, se vår',
  [intl.developer('webhook-existing-info')]:
    'Din webhook är konfigurerad. Du kan läsa mer om hur du använder dem från vår',
  [intl.developer('webhook-secret')]: 'Webhook Secret',
  [intl.developer('webhook-title')]: 'Webhook',
  [intl.developer('webhook-url')]: 'Webhook URL',
  [intl.developer('webhook-url-remove-button')]: 'Ta bort',
  [intl.developer('webhook-url-save-button')]: 'Addera',
  [intl.developer('webhook-url-test-button')]: 'Testa',
  [intl.enforcedPasswordChange('confirm-password-label')]: 'Bekräfta ditt lösenord',
  [intl.enforcedPasswordChange('description')]:
    'Av säkerhetsskäl måste du ändra ditt lösenord.',
  [intl.enforcedPasswordChange('min-characters')]: 'Minst 8 tecken',
  [intl.enforcedPasswordChange('must-match-password')]: 'Måste vara samma som lösenord',
  [intl.enforcedPasswordChange('password-change-success')]:
    'Perfekt! Du kan nu fortsätta använda Risika',
  [intl.enforcedPasswordChange('password-label')]: 'Lösenord',
  [intl.enforcedPasswordChange('submit')]: 'Ändra lösenord',
  [intl.enforcedPasswordChange('title')]: 'Lösenordsändring krävs',
  [intl.exportButton('description')]:
    '{count, plural, =0 {Intet at eksportere} en {Eksportering på {count} række} other {Eksportering på {count} rækker}}',
  [intl.exportButton('max-export')]:
    '{count plural other {Begrænset til de første {count}}}',
  [intl.exportButton('max-out-of')]: '{count plural other {ud af {count} rækker}}',
  [intl.exportButton('text')]: 'Exportera',
  [intl.faq('number_of_employees')]: 'Antal anställda',
  [intl.faq('Audit')]: 'Revision',
  [intl.faq('Company size')]: 'Företagsstorlek',
  [intl.faq('Connected bankruptcies')]: 'Relaterade konkurser',
  [intl.faq('Credit days')]: 'Kreditdagar',
  [intl.faq('Credit maximum')]: 'Kredit max',
  [intl.faq('Distress percentage year')]: 'Konkurspriser 2020',
  [intl.faq('Effect')]: 'Effekt',
  [intl.faq('Erklæringstype')]: 'Statement Type',
  [intl.faq('Explanation')]: 'Förklaring',
  [intl.faq('Financial key figures')]: 'Finansiella nyckeltal',
  [intl.faq('Fractile')]: 'Ömtålig',
  [intl.faq('Fraktil')]: 'Ömtålig',
  [intl.faq('How')]: 'Hur',
  [intl.faq(
    'I 2019 gik 2% af danske virksomheder under konkurs eller under tvangsopløsning.'
  )]: '',
  [intl.faq('Key figure performance')]: 'Nyckelfigurer',
  [intl.faq('Level of audit')]: 'Statement Type',
  [intl.faq('Maximum')]: 'Maximal',
  [intl.faq('Number of employees')]: 'Antal anställda',
  [intl.faq('Other Ratios')]: 'Andra nyckeltal',
  [intl.faq('Ownership')]: 'Äganderätt',
  [intl.faq('Performance')]: 'Prestanda',
  [intl.faq('Reason')]: 'Orsak',
  [intl.faq('Risika score')]: 'Risika score',
  [intl.faq('Risk')]: 'Risk',
  [intl.faq('Sole proprietorship model')]: 'Modell för singel',
  [intl.faq('UC: 77.93%, Gini: 55.86%')]: 'UC: 77.93%, Gini: 55.86%',
  [intl.faq('Understanding risika score and figures')]:
    'Förstå risika poäng och nyckeltal',
  [intl.faq('Unscored companies')]: 'Företag utan poäng',
  [intl.faq('company_size')]: 'Företagsstorlek',
  [intl.faq('What')]: 'Vad',
  [intl.faq('Why')]: 'Varför',
  [intl.faq(
    'With decision threshold on 5 the metrics is sensitivity: 81.01% and precision: 4.8%'
  )]: 'Med beslutströskel på 5 är måtten känslighet: 81,01% och precision: 4,8%',
  [intl.faq('definition')]: 'Definition',
  [intl.faq('variable')]: 'Nyckelfigurer',
  // english section only
  [intl.faq('find-answers')]: 'Find an answer quickly',
  [intl.faq('add-company-monitoring-list')]: 'Add company to monitoring list',
  [intl.faq('set-up-monitoring-emails')]: 'Set up monitoring emails',
  [intl.faq('adjust-credit-recommendation')]: 'Adjust the credit recommendation',
  [intl.faq('view-companies-subsidiaries')]: 'View a companies subsidiaries',
  [intl.faq('chat-cta')]: 'Chat with us',
  [intl.faq('welcome')]: 'Hi {name},',
  [intl.faq('response-time')]: 'We usually respond within 10 min',
  [intl.faq('how-can-we-help')]: 'How can we help?',
  [intl.faq('prompt-desc')]: 'Let us know if you have any questions, were here to help',
  //= ====================
  [intl.financial('probability-of-closure')]: 'Sannolikhet för konkurs',
  [intl.financial('probability-of-distress')]: 'Konkurssandsynlighed',
  [intl.financial('distress')]: 'Sannolikhet för konkurs',
  [intl.financialRatio('no-content')]: 'Inget innehåll tillgängligt',
  [intl.financialRatio('performance')]: 'Prestanda',
  [intl.firstTimeVisit('confirm-password-label')]: 'Bekräfta ditt lösenord',
  [intl.firstTimeVisit('description')]:
    'Säkerheten först! Du måste helt enkelt välja ditt nya lösenord för plattformen och du är igång.',
  [intl.firstTimeVisit('min-characters')]: 'Minst 8 tecken',
  [intl.firstTimeVisit('must-match-password')]: 'Måste vara samma som lösenord',
  [intl.firstTimeVisit('password-label')]: 'Lösenord',
  [intl.firstTimeVisit('submit')]: 'Komma igång',
  [intl.firstTimeVisit('title')]: 'Välj ditt lösenord',

  [intl.generic('view-details')]: 'Visa detaljer',
  [intl.generic('hide-details')]: 'Gide detaljer',
  [intl.generic('owners')]: 'ägare',
  [intl.generic('loading')]: 'Väntar',
  [intl.generic('print')]: 'Skriv ut',
  [intl.generic('actions')]: 'Åtgärder',
  [intl.generic('active')]: 'Aktiva',
  [intl.generic('add')]: 'Lägg till',
  [intl.generic('clear-selection')]: 'Klar',
  [intl.generic('advanced-views-not-allowed')]:
    'Avancerade vyer är inte tillgängliga på din nuvarande plan. Klicka här för att se mer!',
  [intl.generic('all')]: 'Allt',
  [intl.generic('back')]: 'Tillbaka',
  [intl.generic('cancel')]: 'Avbryt',
  [intl.generic('checks-left')]: 'kreditupplysning tillbaka',
  [intl.generic('clear')]: 'Ta bort',
  [intl.generic('companies')]: 'Företag',
  [intl.creditPolicyNew('companies')]: 'Företag',
  [intl.generic('company')]: 'Företag',
  [intl.generic('confirm-password')]: 'Bekräfta lösenord',
  [intl.generic('create')]: 'Skapa',
  [intl.generic('cvr-number')]: 'CVR-nummer',
  [intl.generic('denmark')]: 'Danmark',
  [intl.generic('do-not-want-to-wait')]:
    'Vill du inte vänta? Uppdatera din prenumeration nedan!',
  [intl.generic('edit')]: 'Redigera',
  [intl.generic('email')]: 'E-post',
  [intl.generic('emails')]: 'E-postmeddelanden',
  [intl.generic('employees')]: 'Anställdas',
  [intl.generic('error-reach-us-on-chat')]:
    'Ett fel inträffade! Venlist kontaktar oss på chatt',
  [intl.generic('failed-to-fetch-list')]:
    'Något gick fel när det gällde att hämta data för "{name}"!',
  [intl.generic('feature-not-available')]:
    'Den här funktionen är inte tillgänglig i din prenumeration.',
  [intl.generic('first-name')]: 'Förnamn',
  [intl.generic('first-page')]: 'Första sidan',
  [intl.generic('fte')]: 'år',
  [intl.generic('get-auditor-information')]: 'Visa information om revisorer',
  [intl.generic('get-credit-information')]: 'Visa kreditinformation',
  [intl.generic('get-highlight-information')]: 'Se uppmärksamhetspunkter',
  [intl.generic('import')]: 'Importera',
  [intl.generic('inactive')]: 'Inaktiv',
  [intl.generic('language')]: 'Språk',
  [intl.generic('last-name')]: 'Efternamn',
  [intl.generic('last-page')]: 'Sista sidan',
  [intl.generic('learn-more')]: 'Läs mer',
  [intl.generic('missing-address')]: 'Adress saknas',
  [intl.generic('monitor')]: 'Övervaka',

  [intl.generic('active-companies')]: 'Aktiva företag',

  [intl.generic('monitor-stop')]: 'Sluta övervaka',
  [intl.generic('more-checks-on-monday')]:
    'Du kommer att få {credit_rating_cap} på måndag',
  [intl.generic('name')]: 'Namn',
  [intl.generic('never')]: 'Inte tillgänglig',
  [intl.generic('next-page')]: 'Nästa sida',
  [intl.generic('no')]: 'Nej',
  [intl.generic('no-credit-information')]:
    'Ingen kreditupplysning finns tillgänglig för detta företag!',
  [intl.generic('no-data')]: 'Inga uppgifter',
  [intl.generic('norway')]: 'Norge',
  [intl.generic('number-of-companies')]: 'Antal företag',
  [intl.generic('active-companies')]: 'Aktiva företag',
  [intl.generic('optional')]: 'valfritt',
  [intl.generic('password')]: 'Lösenord',
  [intl.generic('phone')]: 'Telefon',
  [intl.generic('previous-page')]: 'Föregående sida',
  [intl.generic('remove')]: 'Ta bort',
  [intl.generic('restore-default')]: 'Återställ standardinställningen',
  [intl.generic('risika-score')]: 'Risika score',
  [intl.generic('roles')]: 'Roller',
  [intl.generic('rows')]: 'Rader',
  [intl.generic('save')]: 'Spara',
  [intl.generic('selected')]: 'Utvald',
  [intl.generic('send')]: 'Skicka',
  [intl.generic('show-more')]: 'Visa mer',
  [intl.generic('stop')]: 'Sluta',
  [intl.generic('sweden')]: 'Sverige',
  [intl.generic('total')]: 'Total',
  [intl.generic('update')]: 'Uppdatering',
  [intl.generic('yes')]: 'Ja',
  [intl.generic('you-have')]: 'Du har',
  [intl.generic('open-all')]: 'Öppna alla',
  [intl.generic('close-all')]: 'Stäng alla',
  [intl.generic('more-details')]: 'Mer information',
  [intl.generic('less-details')]: 'Färre detaljer',
  [intl.generic('days')]: 'dagar',
  [intl.generic('unknown')]: 'Okänd',
  [intl.generic('close')]: 'Stäng',
  [intl.generic('company-id')]: 'Företags ID',
  [intl.generic('company-name')]: 'Företagets namn',
  [intl.generic('company-type')]: 'Typ av företag',
  [intl.generic('date-of-incorporation')]: 'Inarbetningsdatum',
  [intl.generic('status')]: 'Status',
  [intl.generic('credit-max')]: 'Kreditgräns',
  [intl.generic('risika-recommendation')]: 'Rekommendation från Risika',
  [intl.generic('policy-outcome')]: 'Utfall av policy',
  [intl.generic('policy-credit-limit')]: 'Policy för kreditgräns',
  [intl.generic('street')]: 'Street',
  [intl.generic('street-number')]: 'Gatunummer',
  [intl.generic('coname')]: 'Coname',
  [intl.generic('zip-code')]: 'Postnummer',
  [intl.generic('city')]: 'Staden',
  [intl.generic('municipality')]: 'Kommun',
  [intl.generic('postal-district')]: 'Postdistrikt',
  [intl.generic('country')]: 'Land',
  [intl.generic('number-of-employees')]: 'Antal anställda',
  [intl.generic('industry-code')]: 'Kod för filialer',
  [intl.generic('industry-description')]: 'Beskrivning av filialen',
  [intl.generic('internal-id')]: 'Internt ID',
  [intl.generic('currency')]: 'Valuta',
  [intl.generic('equity')]: 'Rättvisa',
  [intl.generic('profit-loss')]: 'Årets resultat',
  [intl.generic('solidity')]: 'Grad av soliditet',
  [intl.generic('liquidity')]: 'Kassalikviditeten',
  [intl.generic('local-id')]: 'Företags ID',
  [intl.generic('credit-score')]: 'Kreditvärdighet',
  [intl.generic('English')]: 'Engelsk',
  [intl.generic('Danish')]: 'Danska',
  [intl.generic('Norwegian')]: 'Norska',
  [intl.generic('swedish')]: 'Svenska',
  [intl.generic('DENMARK')]: 'DANMARK',
  [intl.generic('SWEDEN')]: 'SVERIGE',
  [intl.generic('NORWAY')]: 'NORGE',
  [intl.generic('FINLAND')]: 'FINLAND',
  [intl.country('fi')]: 'Finland',
  [intl.creditPolicy('fi')]: 'Finland',
  [intl.generic('finland')]: 'Finland',
  [intl.generic('FINLAND')]: 'FINLAND',
  [intl.riskMonitoringOverview('fi_FI')]: 'Finland',
  [intl.generic('save_changes')]: 'Spara ändringar',
  [intl.generic('discard')]: 'Kassera',
  [intl.generic('successful_changes')]: 'Dina ändringar har sparats framgångsrikt',
  [intl.generic('continue')]: 'Fortsätt',
  [intl.generic('cancel')]: 'Avbryt',
  [intl.generic('view-less')]: 'Visa färre',
  [intl.generic('view-all')]: 'Visa alla',
  [intl.generic('x-items-selected')]:
    '{count, plural, =0 {} one {{count} objekt valt} other {{count} objekt valda}}',

  [intl.grading('average')]: 'Genomsnittlig',
  [intl.grading('not-available')]: 'n/a',
  [intl.grading('strong')]: 'Stark',
  [intl.grading('very-strong')]: 'Mycket stark',
  [intl.grading('very-weak')]: 'Mycket svag',
  [intl.grading('weak')]: 'Svag',
  [intl.history('advancedfilters')]: 'Filtrering',
  [intl.history('companyhierarchy')]: 'Grupphierarki',
  [intl.history('creditpolicy')]: 'Kreditpolitik',
  [intl.history('home')]: 'Framsida',
  [intl.history('monitoring')]: 'Övervakning',
  [intl.history('company-comparison')]: 'Jämföra',
  [intl.history('advance-credit-policy')]: 'Advance Credit Policy',
  [intl.history('observational-lists')]: 'Risk Insights',
  [intl.home('faq-score-calculation-description')]:
    'Vi beräknar Risika poängen genom att tillämpa en poängsättningsmodell som klassificerar alla företag i riskgrupperna 1 - 10 baserat på ett branschjusterat finansiellt nyckeltal, företagets ålder, typ av utlåtande, relaterade konkurser, utmätningar och den bransch som företaget tillhör. Bland de bokföringssiffror och andra variabler som ingår i modellen finns nettoresultat, EBITDA, EBIT, finansiella kostnader, omsättningstillgångar, balansräkning, skulder, eget kapital, valuta, företagstyp, bolagsdatum, deklarationstyp och bransch.',
  [intl.home('faq-score-calculation-title')]: 'Hur beräknas Risika-poängen?',
  [intl.home('faq-title')]: 'Vanliga frågor (FAQ)',
  [intl.home('integrations-description')]:
    'Få insikter om hur du kan automatisera kreditprocessen i ditt företag genom att integrera den med dina befintliga CRM- och ERP-system.',
  [intl.home('integrations-title')]: 'Integrera Risika direkt i dina befintliga verktyg',
  [intl.home('monitor-list-label')]: 'Dina bevakningslistor',
  [intl.home('monitor-overview-title')]: 'Dina övervakade företags',
  [intl.home('monitor-recent-changes')]: 'Senaste ändringarna',
  [intl.home('recently-viewed')]: 'Senaste ändringarna',
  [intl.home('no-changes')]: 'Inga förändringar',
  [intl.home('sales-and-marketing-description')]:
    'Sätt upp din egen interna och branschspecifika kreditpolicy direkt på plattformen, varefter all användning av Risika automatiskt justeras enligt denna policy. På så sätt maximeras säkerheten för att ingå rätt avtal med dina B2B-kunder.',
  [intl.home('sales-and-marketing-title')]:
    'Förbättrar din riskhantering genom att automatisera kreditpolicyn',
  [intl.home('newsletter-description')]:
    'Den nya styrkortsmodellen är nu live på Risika-plattformen. Det betyder att många företag i Danmark och Norge har fått en ny och mer korrekt Risika score. Du kan läsa mer om den nya modellen här. Dessutom är du också välkommen att kontakta oss om du har några frågor.',
  [intl.home('newsletter-description-ecp')]:
    'Vill du automatisera dina kreditbeslut? Titta på vårt on-demand-webinar, där Jamie Reynolds och Kristoffer Gerdes presenterar vår senaste produkt, Credit Automator.',
  [intl.home('newsletter-title-ecp')]: 'Credit Automator On-Demand Webinar',
  [intl.home('newsletter-title')]: 'Credit Automator On-Demand Webinar',
  [intl.home('subtitle')]: 'Lär dig mer om hur du får ut det mesta av plattformen.',
  [intl.home('title')]: 'Välkommen till Risika',
  [intl.homePage('contact-availability')]: 'Tillgänglig under de kommande 3 timmarna',
  [intl.homePage('contact-title')]: 'Har du en fråga?',
  [intl.homePage('go-to-monitoring')]: 'Gå till övervakning',
  [intl.homePage('invite-button')]: 'Inbjudan',
  [intl.homePage('invite-description')]:
    'Om du bjuder in en affärspartner och de provar systemet inom en vecka får du 2 entrébiljetter till Tivoli.',
  [intl.homePage('invite-description-fields')]:
    'Fyll i fälten nedan så skickar vi ett e-postmeddelande med en provinloggning till personen inom 24 timmar.',
  [intl.homePage('invite-form-company')]: 'Sök efter företag eller ange CVR-nummer',
  [intl.homePage('invite-form-email')]: 'Jobbmail',
  [intl.homePage('invite-form-invite-button')]: 'Skicka inbjudan',
  [intl.homePage('invite-form-name')]: 'Fullständiga namn',
  [intl.homePage('invite-form-no-options')]: 'Inga företag',
  [intl.homePage('invite-sent-success')]:
    'Tack! Vi skickar inbjudan inom de närmaste 24 timmarna',
  [intl.homePage('invite-suplementary-text')]: 'Känner du någon som vill prova Risika?',
  [intl.homePage('invite-title')]: 'Bjud in en affärspartner och kom till Tivoli',
  [intl.homePage('monitor-changes-subtitle')]: 'Ändringar under de senaste 7 dagarna',
  [intl.homePage('monitor-changes-title')]: 'Mest relevanta ändringar',
  [intl.homePage('monitor-overview-empty')]: 'Inga företag under övervakning',
  [intl.homePage('monitor-overview-import')]:
    'Importera din kundlista och kom igång snabbt',
  [intl.homePage('monitor-portfolio-subtitle')]:
    '{count plural =0 {Du kan inte övervaka fler företag} one {Du kan fortfarande övervaka ett företag} other {Du kan fortfarande övervaka {count} företag}}',

  [intl.home('no-searches-message')]:
    'Har du inte sökt efter något ännu? Börja med att klicka på sökfältet ovan.',

  [intl.homePage('monitor-portfolio-title')]: 'Dina övervakade företag',
  [intl.homePage('monitor-suggestions-empty')]: 'Inga förslag ännu',
  [intl.homePage('monitor-suggestions-empty-description')]:
    'Så fort du har sökt ett företag får du förslag',
  [intl.homePage('monitor-suggestions-subtitle')]: 'Baserat på dina senaste sökningar',

  [intl.home('monitor-recent-changes')]: 'Nyligen sett',

  [intl.homePage('monitor-suggestions-title')]: 'Förslag till övervakning',
  [intl.homePage('news-letter-april-main')]: 'Risikas apriluppdatering',
  [intl.homePage('news-letter-april-secondary')]:
    'Läs vårt senaste nyhetsbrev med nya förbättringar',
  [intl.homePage('news-letter-february-main')]: 'Risikas februariuppdatering',
  [intl.homePage('news-letter-february-secondary')]:
    'Läs vårt senaste nyhetsbrev med nya förbättringar',
  [intl.homePage('news-letter-march-main')]: 'Risika marsuppdatering',
  [intl.homePage('news-letter-march-secondary')]:
    'Läs vårt senaste nyhetsbrev med nya förbättringar',
  [intl.homePage('recent-searches-subtitle')]:
    'Du kan enkelt lägga till dem i din nuvarande lista',
  [intl.home('recent-searches-content')]:
    'Här kan du snabbt få tillgång till de företag och personer som du nyligen har sökt efter',
  [intl.homePage('recent-searches-title')]: 'Senaste sökningar',
  [intl.homePage('relevant-changes-empty')]: 'Inga ändringar under de senaste 7 dagarna.',

  [intl.home('searches-this-month')]: 'Dina sökningar denna månad',

  [intl.homePage('relevant-changes-empty-description')]:
    'Så fort en förändring har skett kommer du att se den här',
  [intl.homePage('resources-and-integrations-title')]: 'Lär dig mer om Risika',
  [intl.homePage('resources-api-solution')]: 'API-lösning',
  [intl.homePage('resources-api-solution-description')]: 'Läs mer om vår API-lösning',
  [intl.homePage('resources-click-copy-example')]: 'Klicka för kopiering i Kreditjek',
  [intl.homePage('resources-click-copy-example-description')]:
    'Enkel kopiering av data på ett företag',
  [intl.homePage('resources-dynamics-crm')]: 'Dynamics CRM',
  [intl.homePage('resources-dynamics-crm-description')]:
    'Läs mer om vår Microsoft Dynamics-integration',
  [intl.homePage('resources-features-and-integrations')]: 'Funktioner och integrationer',
  [intl.homePage('resources-features-and-integrations-description')]:
    'Läs om våra funktioner och integrationer',
  [intl.homePage('resources-monitoring-delete-example')]:
    'Ta bort flera övervakade företag samtidigt',
  [intl.homePage('resources-monitoring-delete-example-description')]:
    'Demo av hur man tar bort flera företag samtidigt',
  [intl.homePage('resources-monitoring-example')]: 'Hur fungerar vår övervakning?',
  [intl.homePage('resources-monitoring-example-desription')]:
    'Demo av de olika funktionerna inom övervakning',
  [intl.homePage('resources-monitoring-import-example')]:
    'Importföretag under övervakning',
  [intl.homePage('resources-monitoring-import-example-description')]:
    'Demo av importerande företag under övervakning',
  [intl.homePage('resources-navision')]: 'Navision',
  [intl.homePage('resources-navision-description')]:
    'Läs mer om vår Microsoft Navision-integration',
  [intl.homePage('resources-search-example')]: 'Sök efter företag',
  [intl.homePage('resources-search-example-description')]:
    'Demo av hur du kan söka efter företag',
  [intl.homePage('welcome')]: 'Välkommen {name}',
  [intl.homePage('all-lists')]: 'Alla listor',
  [intl.homePage('portfolio-recent-changes')]: 'Portföljens senaste ändringar',
  [intl.homePage('no-data')]: 'Inga data att visa för denna lista',
  [intl.importButton('text')]: 'Importera',
  [intl.keyFinancialFigures('export-excel')]: 'Exportera Excel',
  [intl.keyFinancialFigures('original-currency')]: 'Ursprunglig valuta',
  [intl.keyFinancialFigures('selected-currency')]: 'Vald valuta',
  [intl.keyFinancialFigures('export-pdf')]: 'Exportera PDF',
  [intl.keyFinancialFigures('EBIT')]: 'EBIT',
  [intl.keyFinancialFigures('EBITDA')]: 'EBITDA',
  [intl.keyFinancialFigures('annual-report-button')]: 'Årlig rapport',
  [intl.keyFinancialFigures('assets')]: 'Totala tillgångar',
  [intl.keyFinancialFigures('balance')]: 'Totala skulder',
  [intl.keyFinancialFigures('basic-view')]: 'Enkel översikt',
  [intl.keyFinancialFigures('cash')]: 'Likvida tillgångar',
  [intl.keyFinancialFigures('consolidated-statement')]: 'Koncernredovisning',
  [intl.keyFinancialFigures('contributedCapital')]: 'Företagskapital',
  [intl.keyFinancialFigures('currentAssets')]: 'Nuvarande tillgångar',
  [intl.keyFinancialFigures('currentFinancialAssets')]: 'Värdepapper',
  [intl.keyFinancialFigures('debt')]: 'Total skuld',
  [intl.keyFinancialFigures('depreciation')]: 'Avskrivning',
  [intl.keyFinancialFigures('detailed-view')]: 'Detaljerad',
  [intl.keyFinancialFigures('dividend')]: 'Avkastning',
  [intl.keyFinancialFigures('ebit')]: 'EBIT',
  [intl.keyFinancialFigures('ebitda')]: 'EBITDA',
  [intl.keyFinancialFigures('equity')]: 'Rättvisa',
  [intl.keyFinancialFigures('full-view')]: 'Fullständig översikt',
  [intl.keyFinancialFigures('grossResult')]: 'Bruttovinst',
  [intl.keyFinancialFigures('intangibleAssets')]: 'Immateriella anläggningstillgångar',
  [intl.keyFinancialFigures('interestExpenses')]: 'Finansiella kostnader',
  [intl.keyFinancialFigures('interestIncome')]: 'Finansiella intäkter',
  [intl.keyFinancialFigures('inventories')]: 'Aktier',
  [intl.keyFinancialFigures('liabilitiesAndEquity')]: 'Totala skulder',
  [intl.keyFinancialFigures('longTermDebt')]: 'Långfristig skuld',
  [intl.keyFinancialFigures('noncurrentAssets')]: 'Totala anläggningstillgångar',
  [intl.keyFinancialFigures('noncurrentFinancialAssets')]: 'Finansiella tillgångar',
  [intl.keyFinancialFigures('not-available')]: 'Inte tillgänglig',
  [intl.keyFinancialFigures('ordinaryProfit')]: 'Vanligt resultat',
  [intl.keyFinancialFigures('otherNetFinancialIncome')]:
    'Övriga finansiella poster netto',
  [intl.keyFinancialFigures('otherShortTermDebt')]: 'Andra skulder',
  [intl.keyFinancialFigures('otherShortTermReceivables')]: 'Övriga fordringar',
  [intl.keyFinancialFigures('parent-statement')]: 'Föräldrakonto',
  [intl.keyFinancialFigures('profitLoss')]: 'Årets resultat',
  [intl.keyFinancialFigures('propertyPlantAndEquipment')]:
    'Materiella anläggningstillgångar',
  [intl.keyFinancialFigures('provisions')]: 'Bestämmelser',
  [intl.keyFinancialFigures('reserves')]: 'Övriga reserver',
  [intl.keyFinancialFigures('retainedEarnings')]: 'Överfört resultat',
  [intl.keyFinancialFigures('revenue')]: 'Omsättning',
  [intl.keyFinancialFigures('shareCapital')]: 'Företagskapital',
  [intl.keyFinancialFigures('shortTermDebt')]: 'Kortvarig skuld',
  [intl.keyFinancialFigures('shortTermDebtToBanks')]: 'Kortfristig skuld till banker',
  [intl.keyFinancialFigures('shortTermDebtToGroupEnterprises')]:
    'Skuld till koncernbolag',
  [intl.keyFinancialFigures('shortTermReceivablesFromGroupEnterprises')]:
    'Fordringar på koncernbolags',
  [intl.keyFinancialFigures('shortTermReceivablesFromSalesAndServices')]:
    'Fordringar från försäljning och tjänster',
  [intl.keyFinancialFigures('shortTermTaxPayables')]: 'Företagsskatt',
  [intl.keyFinancialFigures('shortTermTradePayables')]: 'Leverantörsreskontra',
  [intl.keyFinancialFigures('staffExpenses')]: 'Arbetskraftskostnader',
  [intl.keyFinancialFigures('taxExpenses')]: 'Skattekostnad',
  [intl.keyFinancialFigures('title')]: 'Bokföringssiffror',
  [intl.keyFinancialFigures('total-debt')]: 'Total skuld',
  [intl.keyFinancialFigures('currency')]: 'Valuta',
  [intl.keyFinancialFigures('profit-loss')]: 'Årets resultat',
  [intl.keyFinancialFigures('solidity')]: 'Grad av soliditet',
  [intl.keyFinancialFigures('liquidity')]: 'Kassalikviditeten',
  [intl.keyFinancialFigures('capital')]: 'Huvudstad',
  [intl.keyFinancialFigures('start-date')]: 'Rapportens startdatum',
  [intl.keyFinancialFigures('end-date')]: 'Rapportens slutdatum',
  [intl.loginRegister('forgot-your-password')]: 'Glömt ditt lösenord',
  [intl.loginRegister('login-submit-button')]: 'Logga in',
  [intl.loginRegister('login-to-account')]: 'Logga in på ditt konto',
  [intl.loginRegister('register-create-account')]: 'Skapa ditt konto',
  [intl.loginRegister('register-create-button')]: 'Skapa',
  [intl.loginRegister('register-popup-failure')]:
    'Det gick inte att skapa konto! Vänligen kontakta oss så hjälper vi till.',
  [intl.loginRegister('register-popup-password')]: 'Lösenorden stämmer inte överens',
  [intl.loginRegister('register-popup-success')]: 'Konto skapat! Du kan nu logga in.',
  [intl.loginRegister('remember-me')]: 'Kom ihåg mig',
  [intl.loginRegister('static-content-paragraph-1')]:
    'Vår vision är att tillhandahålla de bästa lösningarna för finansiella riskhantering till våra kunder globalt.',
  [intl.loginRegister('static-content-paragraph-2')]:
    'För att uppnå denna vision utvinner vi värdefulla insikter från både icke-traditionella och offentliga datakällor med hjälp av avancerade algoritmer. Slutmålet är skräddarsydda, automatiserade och intuitiva lösningar som hjälper dig och ditt företag att minimera risker, spara tid och fatta bättre beslut.',
  [intl.loginRegister('static-content-welcome')]: 'Välkommen till Risika!',
  [intl.mainRecommendation('auditor_selected')]: 'Revisor utsedd',
  [intl.mainRecommendation('company-id')]: 'Företags ID',
  [intl.mainRecommendation('company-id-dk')]: 'Företags-ID (CVR)',
  [intl.mainRecommendation('company-id-no')]: 'Företags-ID (Organ nr.)',
  [intl.mainRecommendation('company-id-se')]: 'Företags-ID (Organ.nr.)',
  [intl.mainRecommendation('copy_company_address')]:
    'Företagsadress kopierad till urklipp',
  [intl.mainRecommendation('copy_company_local_id')]:
    'Företagets lokala id har kopierats till urklipp',
  [intl.mainRecommendation('copy_company_name')]: 'Företagsnamn kopierat till urklipp',
  [intl.mainRecommendation('currency')]: 'Listad i',
  [intl.mainRecommendation('edit-view')]: 'Visa',
  [intl.mainRecommendation('get-credit-information')]: 'Få kreditinformation',
  [intl.mainRecommendation('latest-annual-report')]: 'Senaste årsredovisningen',
  [intl.mainRecommendation('performance')]: 'Historisk utveckling',
  [intl.mainRecommendation('recommendation-credit')]: 'Ingen kredit',
  [intl.mainRecommendation('recommendation-credit-unknown')]: 'Okänd',
  [intl.mainRecommendation('recommended-credit-description')]:
    'Rekommenderad kreditgräns',
  [intl.mainRecommendation('recommended-days')]:
    '{amount, plural, =0 {Ingen kredit dage} one {Maks {amount} dag} other {Maks {amount} dage}}',
  [intl.mainRecommendation('recommended-days-description')]:
    'Rekommenderat antal kreditdagar',
  [intl.mainRecommendation('risika-estimation')]: 'Risika uppskattning',
  [intl.mainRecommendation('risk-level')]: 'Risknivå',
  [intl.mainRecommendation('risk-level-high')]: 'Hög risk',
  [intl.mainRecommendation('risk-level-high-short')]: 'Hög',
  [intl.mainRecommendation('risk-level-low')]: 'Låg risk',
  [intl.mainRecommendation('risk-level-low-short')]: 'Låg',
  [intl.mainRecommendation('risk-level-medium')]: 'Medel risk',
  [intl.mainRecommendation('risk-level-medium-short')]: 'Medium',
  [intl.mainRecommendation('risk-level-sole-proprietorship')]: 'enskild firma',
  [intl.mainRecommendation('risk-level-unknown')]: 'Okänd risk',
  [intl.mainRecommendation('statstidende-header')]: 'Konkursdekret',
  [intl.mainRecommendation('statstidende-title')]: 'Statstidning',
  [intl.mainRecommendation('status')]: 'Status:',
  [intl.mainRecommendation('status-active-from')]: 'Statusändring från:',
  [intl.mainRecommendation('you-have')]: 'Du har',
  [intl.mainRecommendation('explanation')]: 'Förklaring',

  [intl.mainRecommendation('estimation')]: 'Risika uppskattning',
  [intl.mainRecommendation('norwegian-payment-remarks')]: 'Betalningsanmärkningar',
  [intl.mainRecommendation('credit-override')]: 'Åsidosättande av policy',
  [intl.mainRecommendation('no-override-available')]:
    'Det finns ingen åsidosättning tillgänglig för detta företag',
  [intl.mainRecommendation('request-override')]: 'Begär åsidosättande',
  [intl.mainRecommendation('company-highlights')]: 'Företagsinformation',
  [intl.mainRecommendation('risika-score-calculation')]: 'Risika score beräkning',

  [intl.mainRecommendation('recommendation')]: 'Rekommendation',
  [intl.mainRecommendation('credit-max')]: 'Kreditgräns',
  [intl.mainRecommendation('credit-days')]: 'Antal kreditdagar',
  [intl.mainRecommendation('credit-policy')]: 'Kreditpolicy',
  [intl.mainRecommendation('date')]: 'Datum:',
  [intl.mainRecommendation('case-id')]: 'Ärendenummer:',
  [intl.mainRecommendation('statstidende-link')]: 'Länk:',
  [intl.mainRecommendation('bankruptcy-noticed')]: 'Konkursdekret',
  [intl.mainRecommendation('status-label')]: 'Status:',
  [intl.mainRecommendation('valid-from')]: 'Giltig från:',
  [intl.mainRecommendation('undergoing-bankruptcy')]: 'Under konkursförfarande',
  [intl.mainRecommendation('performance-no-data')]:
    ' Information om detta företags tidigare utveckling är inte tillgänglig',
  [intl.mainRecommendation('fraud-indicators')]: 'Bedrägeriindikatorer',

  [intl.paymentRemarks('widget-title')]: 'Betalningsanmärkningar',
  [intl.paymentRemarks('widget-text')]:
    '{count, plural, one {Det finns {count} betalningsanmärkningar registrerade på detta företag.} other {Det finns {count} betalningsanmärkningar registrerade på detta företag.}}',
  [intl.paymentRemarks('widget-open-modal')]: 'Se betalningsanmärkningar',
  [intl.paymentRemarks('creditor')]: 'Borgenärernas',
  [intl.paymentRemarks('type')]: 'Anteckningstyp',
  [intl.paymentRemarks('case-id')]: 'Ärende ID',
  [intl.paymentRemarks('amount')]: 'Belopp',
  [intl.paymentRemarks('registered')]: 'Registrerad',
  [intl.paymentRemarks('status')]: 'Status',
  [intl.paymentRemarks('data-source')]: 'Datakälla',
  [intl.paymentRemarks('total-remarks')]:
    '{count, plural, one {{count} betalningsanmärkningar totalt} other {{count} betalningsanmärkningar totalt}}',
  [intl.paymentRemarks('asterisk-1')]:
    '* Okända belopp beror på att fallets valuta inte är i NOK.',
  [intl.paymentRemarks('asterisk-2')]:
    '** Vid sammanslagning av två bolag skapas ärenden från bolaget som upplösts efter fusion med nytt CVR-nummer.',
  [intl.paymentRemarks('legal-collector')]:
    'Betalningsanmärkningar hämtas från Intrum, Visma och Kredinor och är giltiga för användning vid kreditupplysning i Norge. Enligt norsk lag kan betalningsanmärkningar lagras i fyra år, varefter de raderas från vårt system.',
  [intl.paymentRemarks('total-unsettled-amount')]: 'Totalt obetalt belopp',
  [intl.paymentRemarks('total-unsettled-number')]: 'Totalt antal obetalda poster',
  [intl.paymentRemarks('debt-collection-agencies')]: 'Inkassobyråer',
  [intl.paymentRemarks('data-from')]: 'Data från',
  [intl.paymentRemarks('unique-creditors')]: 'Unika fordringsägare',
  [intl.paymentRemarks('avg-unsettled-time')]: 'Genomsnittlig skuldperiod',
  [intl.paymentRemarks('active-creditors')]: 'aktiva borgenärer',

  [intl.newScorecard('title')]: 'Risika score beräkning',
  [intl.newScorecard('title-tooltip')]:
    'Risika score väljer ut de 25 faktorer som har störst potential att förutsäga konkurs, bland mer än 180 tillgängliga. Varje faktor tilldelas sedan en vikt beroende på värdet på den faktorn, som tillsammans ger den totala poängen.',
  [intl.newScorecard('high_positive_impact')]: 'Hög positiv påverkan',
  [intl.newScorecard('moderate_positive_impact')]: 'Måttlig positiv påverkan',
  [intl.newScorecard('minor_impact')]: 'Mindre påverkan',
  [intl.newScorecard('moderate_negative_impact')]: 'Måttlig negativ påverkan',
  [intl.newScorecard('high_negative_impact')]: 'Hög negativ påverkan',
  [intl.newScorecard('factor-counter')]:
    '{count, plural, =0 {{count} faktorer} one {{count} faktor} other {{count} faktorer}}',

  [intl.newScorecard('minor-impact-tooltip')]:
    'Dessa faktorer har liten eller ingen inverkan på den totala poängen, vilket innebär att de inte påverkade Risika-poängen avgörande i positiv eller negativ riktning',

  [intl.monitorFrequency('daily')]: 'Veckodag',
  [intl.monitorFrequency('daily-select')]: 'Dagligen',
  [intl.monitorFrequency('friday-select')]: 'Fredag',
  [intl.monitorFrequency('how-often-label')]: 'Hur ofta?',
  [intl.monitorFrequency('monday-select')]: 'Måndag',
  [intl.monitorFrequency('monthly')]: 'Varje månad',
  [intl.monitorFrequency('monthly-select')]: 'Månatlig (första måndagen)',
  [intl.monitorFrequency('saturday-select')]: 'Lördag',
  [intl.monitorFrequency('sunday-select')]: 'Söndag',
  [intl.monitorFrequency('thursday-select')]: 'Torsdag',
  [intl.monitorFrequency('title')]: 'Hur ofta vill du bli underrättad?',
  [intl.monitorFrequency('tuesday-select')]: 'Tisdag',
  [intl.monitorFrequency('wednesday-select')]: 'Onsdag',
  [intl.monitorFrequency('weekly-friday')]: 'Varje fredag',
  [intl.monitorFrequency('weekly-monday')]: 'Varje måndag',
  [intl.monitorFrequency('weekly-saturday')]: 'Varje lördag',
  [intl.monitorFrequency('weekly-select')]: 'Varje vecka',
  [intl.monitorFrequency('weekly-sunday')]: 'Varje söndag',
  [intl.monitorFrequency('weekly-thursday')]: 'Varje torsdag',
  [intl.monitorFrequency('weekly-tuesday')]: 'Varje tisdag',
  [intl.monitorFrequency('weekly-wednesday')]: 'Varje onsdag',
  [intl.monitorFrequency('which-day-label')]: 'Vilken dag?',
  [intl.navigation('advanced-filters')]: 'Filtrering',
  [intl.navigation('company-comparison')]: 'Jämföra',
  [intl.navigation('cfo-view')]: 'CFO syn',
  [intl.navigation('chat')]: 'Stöd',
  [intl.navigation('company-hierarchy')]: 'Grupphierarki',
  [intl.navigation('credit-check')]: 'Kredit upplysning',
  [intl.navigation('credit-policy')]: 'Kreditpolicy',
  [intl.navigation('fraud-indicators')]: 'Bedrägeriindikatorer',
  [intl.navigation('customer-management')]: 'Kundernas',
  [intl.navigation('dashboard')]: 'Instrumentbräda',
  [intl.navigation('developer')]: 'Utvecklaren',
  [intl.navigation('faq')]: 'FAQ',
  [intl.navigation('home')]: 'Framsida',
  [intl.navigation('login')]: 'Logga in',
  [intl.navigation('logout')]: 'Logga ut',
  [intl.navigation('relation-overview')]: 'Personers',
  [intl.creditPolicyNew('people')]: 'Personers',
  [intl.navigation('risk-monitoring')]: 'Övervaka',
  [intl.navigation('search-business')]: 'Sök',
  [intl.navigation('settings')]: 'Inställningar',
  [intl.navigation('user-management')]: 'Användarhantering',
  [intl.navigation('revoked-warning')]: 'Du har inte tillgång',
  [intl.navigation('enterprise-credit-policy')]: 'Credit Automator',
  [intl.navigationTooltip('company-hierarchy')]: 'Sök efter ett företag med dotterbolag',
  [intl.navigationTooltip('credit-check')]: 'Sök efter ett företag först',
  [intl.navigationTooltip('relation-overview')]: 'Sök efter en person först',
  [intl.news('no-articles')]: 'Inga artiklar just nu.',
  [intl.news('show-less')]: 'Färre',
  [intl.news('show-more')]: 'Mer',
  [intl.news('title')]: 'Nyheter',
  [intl.notFound('best-experience')]: 'För den bästa upplevelsen',
  [intl.notFound('better-performance')]: 'Bättre prestanda',
  [intl.notFound('not-found-402')]:
    'Om du är på en demo så har tyvärr begränsningarna för ditt abonnemang förmodligen nåtts. Du kan kontakta den person som ansvarar för ditt abonnemang i ditt företag eller kontakta oss direkt',
  [intl.notFound('not-found-404')]:
    'Tyvärr har du kommit in på en sida som inte finns. Du kan klicka på knappen för att återgå till instrumentpanelen.',
  [intl.notFound('not-found-500')]:
    'Vi har för närvarande tekniska problem. Vi jobbar hårt för att få dem att lösa och systemet bör vara online inom kort!',
  [intl.notFound('not-found-503')]:
    'Något gick fel med din begäran. Det är möjligt att våra servrar är nere för underhåll eller överbelastade. Försök igen om några minuter.',
  [intl.notFound('not-found-button-dashboard')]: 'Gå till "instrumentpanelen"',
  [intl.notFound('not-found-button-risika-dk')]: 'Gå till "Risika.dk"',
  [intl.notFound('not-supported-header')]: 'Stöds inte',
  [intl.notFound('not-supported-text')]:
    'För att säkerställa den bästa upplevelsen för våra användare har vi inaktiverat support för Internet Explorer. Följ länkarna nedan för att ladda ner en av våra rekommenderade webbläsare.',
  [intl.notFound('pricing-button')]: 'Gå till "Priser och planer"',
  [intl.notFound('under-meintenance-header')]: 'Under kontroll',
  [intl.notFound('under-meintenance-text')]:
    'Risika instrumentpanel och API är för närvarande under granskning. Vi kommer snart tillbaka!',
  [intl.popconfirm('cancel')]: 'Avbryt',
  [intl.popconfirm('confirm')]: 'Okej',
  [intl.popconfirm('title')]: 'Är du säker?',
  [intl.relationOverview('end-date')]: 'Slutdatum',
  [intl.relationOverview('function')]: 'Fungera',
  [intl.relationOverview('function-status')]: 'Funktionsstatus',
  [intl.relationOverview('no-entity-redirect')]:
    'Inget företag eller individ som valts kommer att återföra dig till sökföretaget',
  [intl.relationOverview('start-date')]: 'Start datum',
  [intl.relationOverview('title')]: 'Relationsöversikt',
  [intl.relations('auditor')]: 'Revisor',
  [intl.relations('board_member')]: 'Medlem',
  [intl.relations('branch_manager')]: 'Platschef',
  [intl.relations('ceo')]: 'Adm. Chef',
  [intl.relations('founder')]: 'Grundare',
  [intl.relations('legal_owner')]: 'Juridisk ägare',
  [intl.relations('management')]: 'Förvaltning',
  [intl.relations('president')]: 'Ordförande',
  [intl.relations('real_owner')]: 'Riktig ägare',
  [intl.relations('stakeholder')]: 'Intressant',
  [intl.relations('substitute')]: 'Ersättning',
  [intl.relations('vice_president')]: 'Vice President',
  [intl.relevantFinancialRatios('asset_turnover')]: 'Tillgångens omsättningskvot',
  [intl.relevantFinancialRatios('based-on-parent-statement')]:
    'Baserat på moderbolagets redovisningssiffror',
  [intl.relevantFinancialRatios('basic_earning_power')]: 'Intjäningsförmåga',
  [intl.relevantFinancialRatios('capacity_ratio')]: 'Kapacitetsgrad',
  [intl.relevantFinancialRatios('cash_conversion_rate')]: 'Kontant konverteringsgrad',
  [intl.relevantFinancialRatios('cash_ratio')]: 'Pengakurs',
  [intl.relevantFinancialRatios('current_assets_to_equity')]:
    'Omsättningstillgångar i förhållande till eget kapital',
  [intl.relevantFinancialRatios('current_ratio')]: 'Kassalikviditeten',
  [intl.relevantFinancialRatios('debt_ratio')]: 'Skuldkvoten',
  [intl.relevantFinancialRatios('debt_to_equity_ratio')]: 'Gearing',
  [intl.relevantFinancialRatios('ebitda_to_debt_ratio')]: 'EBITDA till skuldkvot',
  [intl.relevantFinancialRatios('fixed_assets_to_long_term_liabilities_ratio')]:
    'Kapitalbindningsgrad',
  [intl.relevantFinancialRatios('gross_margin')]: 'Täckningsgrad',
  [intl.relevantFinancialRatios('interest_coverage')]: 'Räntetäckningsgrad',
  [intl.relevantFinancialRatios('interest_margin')]: 'Räntemarginal',
  [intl.relevantFinancialRatios('interest_rate_on_debt_with_average')]: 'Skuldränta',
  [intl.relevantFinancialRatios('not-available')]: 'Inte tillgänglig',
  [intl.relevantFinancialRatios('one_year_change_in_equity')]:
    'Förändring i eget kapital',
  [intl.relevantFinancialRatios('operating_margin')]: 'Vinstmarginal',
  [intl.relevantFinancialRatios('profit_margin')]: 'Framgång',
  [intl.relevantFinancialRatios('return_on_assets')]: 'Avkastning',
  [intl.relevantFinancialRatios('return_on_equity')]: 'Avkastning på eget kapital',
  [intl.relevantFinancialRatios('solidity_ratio')]: 'Grad av soliditet',
  [intl.relevantFinancialRatios('title')]: 'Relevanta nyckeltal',
  [intl.reportBug('agreement')]:
    'Genom att lämna in en rapport tillåter du oss att använda din e-post som referens för det här problemet.',
  [intl.reportBug('description')]: 'Beskriv felet du upplevde',
  [intl.reportBug('drag-drop')]:
    'Du kan dra och släppa eller bläddra för att bifoga några bilder.',
  [intl.reportBug('error-in-credit-information')]: 'Fel i kreditinformation',
  [intl.reportBug('error-in-financial-data')]: 'Fel i finansiella data',
  [intl.reportBug('error-in-general-information')]: 'Fel i allmän företagsinformation',
  [intl.reportBug('error-in-highlights')]: 'Fel i uppmärksamhetspunkter',
  [intl.reportBug('error-in-relation-data')]: 'Misslyckande i relationer',
  [intl.reportBug('other')]: 'Andra saker',
  [intl.reportBug('report-bug-paper')]: 'Rapportera en bugg',
  [intl.reportBug('send-button')]: 'Skicka felrapport',
  [intl.reportBug('type-of-report')]: 'Vilken typ av fel upplevde du?',
  [intl.restriction('basic-description')]:
    'Ditt abonnemang täcker inte denna funktion. Klicka på knappen nedan för mer information',
  [intl.restriction('create-new-list-content')]:
    'Den här funktionen är inte tillgänglig i din prenumeration.',
  [intl.restriction('create-new-list-title')]: 'Övervakningslistor',
  [intl.restriction('credit-reports-button')]: 'Priser och planer',
  [intl.restriction('credit-reports-description')]:
    'Du har inte tillgång till denna funktion. Du kan uppgradera din prenumeration eller köpa kreditupplysningar som en separat funktion.',
  [intl.restriction('credit-reports-title')]: 'Kreditupplysningar',
  [intl.restriction('export-button')]: 'Priser och planer',
  [intl.restriction('export-description')]:
    'Du har inte tillgång till denna funktion. Du kan uppgradera ditt abonnemang eller köpa exportalternativet som en separat funktion.',
  [intl.restriction('export-title')]: 'Exporterar',
  [intl.restriction('key-figures-title')]: 'Bokföringssiffror',
  [intl.restriction('risk-monitoring-button')]: 'Priser och planer',
  [intl.restriction('risk-monitoring-description')]:
    'Du har inte tillgång till denna funktion. Du kan uppgradera ditt abonnemang eller köpövervakning som en separat funktion.',
  [intl.restriction('risk-monitoring-title')]: 'Övervakning',
  [intl.observationalLists('title')]: 'Risk Insights',
  [intl.observationalLists('risika-score-title')]: 'Risika kreditpoäng',
  [intl.observationalLists('risika-score-by-time-title')]:
    'Utveckling av Risika kreditpoäng',
  [intl.observationalLists('risika-score-by-company-title')]:
    'Risika kreditpoäng fördelat efter företagstyp',
  [intl.observationalLists('risika-score-distribution-title')]:
    'Risika kreditpoängfördelning',
  [intl.observationalLists('risika-score-employees-title')]:
    'Risika kreditpoäng dividerat med antal anställda',
  [intl.observationalLists('risika-score-industry-title')]:
    'Risika kreditpoäng fördelat på branscher',
  [intl.observationalLists('key-figures-industry-title')]:
    'Nyckeltal fördelade på branscher',
  [intl.observationalLists('development-selected-key-figures-title')]:
    'Utveckling i utvalda nyckeltal',
  [intl.observationalLists('geographical-risika-score-title')]:
    'Risika kreditpoäng fördelad geografiskt',
  [intl.observationalLists('risika-score-over-time-title')]:
    'Utveckling av Risika kreditpoäng',
  [intl.observationalLists('risika-key-figures-by-company-type-title')]:
    'Nyckeltal fördelade på företagstyp',
  [intl.observationalLists('tab-risika-credit-score')]: 'Risika kreditbetyg',
  [intl.observationalLists('tab-financial-performance')]: 'Finansiella resultat',
  [intl.observationalLists('tab-companies')]: 'Företag',
  [intl.observationalLists('annual-result-average-title')]: 'Årsresultat, Gen.',
  [intl.observationalLists('equity-average-title')]: 'Eget kapital, Gen.',
  [intl.observationalLists('employees-average-title')]: 'Antal anställda, Gen.',
  [intl.observationalLists('development-credit-policy-title')]:
    'Utveckling i enlighet med kreditpolicyn',
  [intl.observationalLists('list-id-error')]: 'Vänligen ange ett giltigt list-ID!',
  [intl.observationalLists('credit-score')]: 'Kreditvärdighet',
  [intl.observationalLists('select-industry')]: 'Välj bransch',
  [intl.observationalLists('highlights')]: 'Uppmärksamhetspunkter',
  [intl.observationalLists('credit_policy.violations')]: 'Kränkningar',
  [intl.observationalLists('risk_assessment.code')]: 'Risknivå',
  [intl.observationalLists('credit_automator_result')]: 'Resultat av kreditpolitiken',
  [intl.observationalLists('credit_policy.allowed')]: 'Efterlevnad',
  [intl.riskMonitoring('intelligence-suite')]: 'Risk Insights',
  [intl.riskMonitoring('intelligence-suite-tooltip-access')]:
    'Få tillgång till portföljinsikter och förstå din riskprofil.',
  [intl.riskMonitoring(
    'intelligence-suite-tooltip-no-access'
  )]: `Din prenumerationsplan inkluderar inte Risk Insights. Men vänligen kontakta vårt Customer Success-team om du vill veta mer om hur Risk Insights kan hjälpa dig att fatta välgrundade strategiska beslut.`,
  [intl.riskMonitoring('company')]: 'Företag',
  [intl.riskMonitoring('company-search')]: 'Sök efter ett företag',
  [intl.riskMonitoring('company-type')]: 'Affärs Typ',
  [intl.riskMonitoring('companyName')]: 'Företag',
  [intl.riskMonitoring('companyType')]: 'Affärs Typ',
  [intl.riskMonitoring('country')]: 'Land',
  [intl.riskMonitoring('credit-max')]: 'Kredit max',
  [intl.riskMonitoring('currency')]: 'Valuta',
  [intl.riskMonitoring('date-of-incorporation')]: 'Inarbetningsdatum',
  [intl.riskMonitoring('dateOfIncorporation')]: 'Inarbetningsdatum',
  [intl.riskMonitoring('edit-company-title')]: 'Redigera företag',
  [intl.riskMonitoring('employee-interval')]: 'Anställdas',
  [intl.riskMonitoring('employeeInterval')]: 'Anställdas',
  [intl.riskMonitoring('equity')]: 'Rättvisa',
  [intl.riskMonitoring('from-to-count')]: '{from}-{to} av {count}',
  [intl.riskMonitoring('import-from-file')]: 'Importera från fil',
  [intl.riskMonitoring('import-from-file-company-id-field')]:
    'Välj fältet med företags-ID',
  [intl.riskMonitoring('import-from-file-include-internal-id')]: 'Inkludera internt ID',
  [intl.riskMonitoring('import-from-file-internal-id-field')]:
    'Välj fältet med ditt interna ID',
  [intl.riskMonitoring('import-from-manual-entry')]: 'Ange manuellt',
  [intl.riskMonitoring('import-from-manual-entry-helper-text')]:
    'Avgränsad med mellanslag, komma eller semikolon',
  [intl.riskMonitoring('internal-id')]: 'Internt ID',
  [intl.riskMonitoring('internalId')]: 'Internt ID',
  [intl.riskMonitoring('liquidity')]: 'Kassalikviditeten',
  [intl.riskMonitoring('local-id')]: 'Företags ID',
  [intl.riskMonitoring('local-id-search')]: 'Sök efter företags-ID',
  [intl.riskMonitoring('internal-id-search')]: 'Sök efter internt ID',
  [intl.riskMonitoring('localId')]: 'Företags-ID (CVR)',
  [intl.riskMonitoring('no-credit')]: 'Ingen kredit',
  [intl.riskMonitoring('no-data')]: 'Ingen data tillgänglig',
  [intl.riskMonitoring('profit-loss')]: 'Årets resultat',
  [intl.riskMonitoring('profitLoss')]: 'Årets resultat',
  [intl.riskMonitoring('reject-companies')]: 'Lägg till i listan i Kreditpolicy',
  [intl.riskMonitoring('remaining-monitoring')]: 'Övervakningar kvar',
  [intl.riskMonitoring('remove')]: 'Ta bort',
  [intl.riskMonitoring('risika-score')]: 'Göra',
  [intl.riskMonitoring('rows-per-page')]: 'Per sida',
  [intl.riskMonitoring('score')]: 'Göra',
  [intl.riskMonitoring('solidity')]: 'Grad av soliditet',
  [intl.riskMonitoring('status')]: 'Status',
  [intl.riskMonitoring('summation-change-duration')]:
    'Förändringar under den senaste veckan',
  [intl.riskMonitoring('title')]: 'Övervakning',
  [intl.riskMonitoring('top-negative')]: 'Mest negativt',
  [intl.riskMonitoring('you-already-have-preset-with-name')]:
    'Du har redan en inställning med detta namn',
  [intl.riskMonitoring('please-select-a-name')]: 'Välj ett namn',
  [intl.riskMonitoring('preset-name')]: 'Export-name',
  [intl.riskMonitoring('select-preset')]: 'Välj export',
  [intl.riskMonitoring('no-presets-saved')]: 'Ingen export sparad',
  [intl.riskMonitoring('capital')]: 'Huvudstad',
  [intl.riskMonitoring('cannot-monitor-that-many-companies')]:
    'Det här abonnemang paketet tillåter inte att du övervakar så många företag',
  [intl.riskMonitoring('invalid-request')]: 'Ogiltig begäran, kontrollera listan',
  [intl.riskMonitoringNew('table-shown')]: 'Visad',
  [intl.riskMonitoringNew('table-hidden')]: 'Dolt',
  [intl.riskMonitoringNew('add')]: 'Lägg till',
  [intl.riskMonitoringNew('add-companies-button')]: 'Importera',
  [intl.riskMonitoringNew('add-list-name-snackbar')]:
    'Vänlista välj ett namn för listan!',
  [intl.riskMonitoringNew('add-name')]: 'Namnge din lista',
  [intl.riskMonitoringNew('add-new-list')]: 'Ny lista',
  [intl.riskMonitoringNew('add-new-subscription')]: 'Lägg till avisering',
  [intl.riskMonitoringNew('add-to-monitoring-counter')]:
    'Försöker importera {count} företag till "{listName}". Är du säker på att du vill fortsätta?',
  [intl.riskMonitoringNew('add-to-monitoring-list-title')]:
    'Ladda filen eller ange CVR-nummer manuellt',
  [intl.riskMonitoringNew('added-some-companies')]:
    'Beloppet överstiger din nuvarande övervakningsplan. Lade bara till några företag.',
  [intl.riskMonitoringNew('adding-companies-wait')]: 'Lägger till företag. Vänta...',
  [intl.riskMonitoringNew('are-you-sure-delete')]:
    'Är du säker på att du vill ta bort listan "{text}"?',
  [intl.riskMonitoringNew('are-you-sure-delete-entries')]:
    '{count, plural, one {Er du sikker på du vil slette virksomheden?} other {Er du sikker på du vil slette virksomhederne?}}',
  [intl.riskMonitoringNew('back')]: 'Tillbaka',
  [intl.riskMonitoringNew('copy-button-text')]: 'Kopiera',
  [intl.riskMonitoringNew('copy-to-another-list-title')]:
    'Välj listan du vill kopiera till',
  [intl.riskMonitoringNew('create-list-button')]: 'Spara lista',
  [intl.riskMonitoringNew('create-new-list-text-field')]: 'Skapa ny lista',
  [intl.riskMonitoringNew('create-subscription')]: 'Skapa avisering',
  [intl.riskMonitoringNew('cvt-textfield-label')]: 'Ange CVR-nummer (lokala ID) här',
  [intl.riskMonitoringNew('delete')]: 'Radera',
  [intl.riskMonitoringNew('delete-companies-title')]:
    '{count, plural, one {Slet markerede virksomheder} other {Slet markerede virksomheder}}',
  [intl.riskMonitoringNew('delete-dialog-title')]: 'Radera lista',
  [intl.riskMonitoringNew('delete-failed')]: 'Kunde inte ta bort listan',
  [intl.riskMonitoringNew('delete-items')]: 'Radera',
  [intl.riskMonitoringNew('delete-list')]: 'Radera lista',
  [intl.riskMonitoringNew('delete-success')]: 'Listan har tagits bort',
  [intl.riskMonitoringNew('deleting-companies-wait')]: 'Radering av företag. Vänta...',
  [intl.riskMonitoringNew('drag-drop')]:
    'Dra och släpp, eller <span class="filepond--label-action">välj en fil</span> (.csv eller .xlsx)',
  [intl.riskMonitoringNew('edit-button')]: 'Döp om',
  [intl.riskMonitoringNew('edit-list-button')]: 'Omdøb-listan',
  [intl.riskMonitoringNew('edit-list-text-field')]: 'Omdøb-listan',
  [intl.riskMonitoringNew('edit-name')]: 'Nytt namn',
  [intl.riskMonitoringNew('existing-subscriptions')]: 'Befintliga meddelanden',
  [intl.riskMonitoringNew('export-companies-button')]: 'Exportera',
  [intl.riskMonitoringNew('failed-validation')]:
    '{amount} företag misslyckades med valideringen och lades inte till!',
  [intl.riskMonitoringNew('field-list-header')]:
    'Välj kolumnen som innehåller CVR-nummer',
  [intl.riskMonitoringNew('handle-settings-button')]: 'Bordsinställningar',
  [intl.riskMonitoringNew('handle-visibility-button')]: 'Visa',
  [intl.riskMonitoringNew('items-copied')]:
    '{count, plural, one {Virksomheden blev kopieret} other {{count} virksomheder blev kopieret}}',
  [intl.riskMonitoringNew('items-deleted')]:
    '{count, plural, one {Virksomheden blev slettet} other {{count} virksomheder blev slettet}}',
  [intl.riskMonitoringNew('items-failed-to-copy')]:
    'Ett fel uppstod när vi försökte kopiera företagens',
  [intl.riskMonitoringNew('items-failed-to-delete')]:
    'Ett fel uppstod när vi försökte ta bort företagens',
  [intl.riskMonitoringNew('items-failed-to-import')]: 'Företag kunde inte importeras.',
  [intl.riskMonitoringNew('items-failed-to-move')]:
    'Ett fel uppstod när vi försökte flytta företagens',
  [intl.riskMonitoringNew('items-imported')]: 'Företagen importerades',
  [intl.riskMonitoringNew('items-moved')]:
    '{count, plural, one {Virksomheden blev flyttet} other {{count} virksomheder blev flyttet}}',
  [intl.riskMonitoringNew('monitor-add-subscription')]: 'Lägg till avisering',
  [intl.riskMonitoringNew('monitor-settings')]: 'Ange inställningar',
  [intl.riskMonitoringNew('move-button-text')]: 'Flytta',
  [intl.riskMonitoringNew('move-to-another-list-title')]:
    'Välj listan du vill flytta till',
  [intl.riskMonitoringNew('new-list-added-failed')]: 'Kunde inte lägga till ny lista',
  [intl.riskMonitoringNew('new-list-added-success')]: 'Ny lista tillagd',
  [intl.riskMonitoringNew('new-list-private')]: 'Privat',
  [intl.riskMonitoringNew('no-monitoring-list-available')]:
    'Ingen övervakningslista tillgänglig',
  [intl.riskMonitoringNew('create-monitoring-list-text-empty')]:
    'Skapa eller gå med i en bevakningslista för att börja få uppdateringar om företag som är viktiga för dig.',
  [intl.riskMonitoringNew('new-list-public')]: 'Offentlig',
  [intl.riskMonitoringNew('new-list-subtitle')]: 'Välj de användare som kan se listan',
  [intl.riskMonitoringNew('no-credit')]: 'Ingen kredit',
  [intl.riskMonitoringNew('no-subscriptions-text')]:
    'Det verkar som att du inte får ändringar på vissa listor. Tryck på knappen ovan för att lägga till en ny!',
  [intl.riskMonitoringNew('remove-internal-id')]: 'Ta bort internt ID',
  [intl.riskMonitoringNew('remove-internal-id-description')]:
    "{count, plural, one {Du er ved at slette {count} internt ID} other {Du er ved at slette {count} interne ID'er}}",
  [intl.riskMonitoringNew('remove-internal-id-title')]:
    "{{count, plural, one {Slet internt ID} other {Slet interne ID'er}}",
  [intl.riskMonitoringNew('save-changes')]: 'Spara ändringar',
  [intl.riskMonitoringNew('select-lists')]: 'Välj listor',
  [intl.riskMonitoringNew('select-subscription-lists')]:
    'Välj listor som du vill få ändringar på',
  [intl.riskMonitoringNew('select-subscription-options')]: 'Välj vad du vill höra om',
  [intl.riskMonitoringNew('selected')]:
    '{count plural =0 {Ingen valda} other {{count} valda}}',
  [intl.riskMonitoringNew('settings-header')]: 'Välj vilka kolumner du vill se',
  [intl.riskMonitoringNew('submit')]: 'Bekräfta',
  [intl.riskMonitoringNew('subscribed-to-all')]:
    'Du prenumererar på alla tillgängliga listor',
  [intl.riskMonitoringNew('subscription-add-failed')]:
    'Det gick inte att skapa din avisering',
  [intl.riskMonitoringNew('subscription-add-success')]: 'Avisering skapad',
  [intl.riskMonitoringNew('subscription-delete-failed')]:
    'Det uppstod ett problem när du försökte ta bort din avisering',
  [intl.riskMonitoringNew('subscription-delete-success')]: 'Avisering raderad',
  [intl.riskMonitoringNew('subscription-updated-success')]: 'Aviseringen har uppdaterats',
  [intl.riskMonitoringNew('table-actions-tooltip')]: 'Välj först en rad',
  [intl.riskMonitoringNew('text-field-hint')]:
    'Använd ett kommatecken eller mellanslag mellan varje CVR-nummer',
  [intl.riskMonitoringNew('update')]: 'Uppdatering',
  [intl.riskMonitoringNew('update-subscriptions')]: 'Dina aviseringar',
  [intl.riskMonitoringNew('update-subscriptions-title')]: 'Dina aviseringar',
  [intl.riskMonitoringNew('visibility-header')]: 'Uppdatera vem som kan se listan',
  [intl.riskMonitoringNew('your-lists')]: 'Dina listor',
  [intl.riskMonitoringNew('sum-of-all-ids-found')]:
    'Summan av alla ID:n som finns i filen: {count}.',
  [intl.riskMonitoringNew('faulty-ids-found-message')]:
    '{count, plural, one {{count} defekt företags-ID hittades.} other {{count} defekt företags-ID hittades.}}',
  [intl.riskMonitoringNew('faulty-ids-button-export-label')]: 'Export defekt(er) ID',
  [intl.riskMonitoringNew('faulty-ids-file-name')]: 'Defekt-ID',
  [intl.riskMonitoringNew('duplicate-ids-found-message')]:
    '{count, plural, one {{count} dubblett företags-ID hittades.} other {{count} dubblett företags-ID hittades.}}',
  [intl.riskMonitoringNew('duplicate-ids-button-export-label')]: 'Exportera dubblett-ID',
  [intl.riskMonitoringNew('duplicate-ids-file-name')]: 'DuplicateID',
  [intl.riskMonitoringNew('language-choice')]: 'Välj önskat språk',
  [intl.riskMonitoringNew('error-loading-page')]:
    'Vi stötte på ett problem när vi laddade dina övervakningslistor. Uppdatera sidan och försök igen.',
  [intl.riskMonitoringNew('list-name-update-success')]: 'Listnamn uppdaterat',
  [intl.riskMonitoringNew('list-name-update-fail')]: 'Uppdatering misslyckades',

  [intl.riskMonitoringOverview('all')]: 'Allt',
  [intl.riskMonitoringOverview('da_DK')]: 'Danmark',
  [intl.riskMonitoringOverview('delete-all-button')]: 'Radera allt',
  [intl.riskMonitoringOverview('delete-all-popup-text')]:
    'Den här åtgärden tar bort alla företag som du för närvarande övervakar. Är du säker på att du vill fortsätta?',
  [intl.riskMonitoringOverview('delete-all-popup-title')]:
    'Ta bort alla övervakade företag',
  [intl.riskMonitoringOverview('high-risk')]: 'Hög risk',
  [intl.riskMonitoringOverview('low-risk')]: 'Låg risk',
  [intl.riskMonitoringOverview('medium-risk')]: 'Medel risk',
  [intl.riskMonitoringOverview('no-score')]: 'Inga poäng',
  [intl.riskMonitoringOverview('no_NO')]: 'Norge',
  [intl.riskMonitoringOverview('sv_SE')]: 'Sverige',
  [intl.riskMonitoringOverview('total')]: 'Total',
  [intl.riskMonitoringSubscription('add-description')]:
    'Du kan skapa en prenumeration på de parametrar du vill höra om. Vi skickar ett e-postmeddelande varje vecka om det har skett några förändringar i företagen du övervakar.',
  [intl.riskMonitoringSubscription('add-subscription')]: 'Lägg till övervakning',
  [intl.riskMonitoringSubscription('add-subscription-title')]:
    'Lägg till ett övervakningsabonnemang',
  [intl.riskMonitoringSubscription('address')]: 'Adress',
  [intl.riskMonitoringSubscription('all-important-changes')]: 'Alla viktiga förändringar',
  [intl.riskMonitoringSubscription('all-regular-changes')]: 'Alla vanliga förändringar',
  [intl.riskMonitoringSubscription('annual_reports')]: 'Årliga rapporter',
  [intl.riskMonitoringSubscription('company_type')]: 'Affärs Typ',
  [intl.riskMonitoringSubscription('email')]: 'Resultat av politiken',
  [intl.riskMonitoringSubscription('policy_outcome')]: 'Resultat av kreditpolitiken',
  [intl.riskMonitoringSubscription('employees')]: 'Antal anställda',
  [intl.riskMonitoringSubscription('gazette')]: 'Statstidning',
  [intl.riskMonitoringSubscription('important-changes')]: 'Viktiga ändringar',
  [intl.riskMonitoringSubscription('industry')]: 'Industri',
  [intl.riskMonitoringSubscription('manage-description')]:
    'Du har möjlighet att ta bort ditt övervakningsabonnemang eller ändra de parametrar du vill höra om i framtiden.',
  [intl.riskMonitoringSubscription('manage-subscription')]: 'Preferenser',
  [intl.riskMonitoringSubscription('manage-subscription-title')]:
    'Konfigurera ditt övervakningsabonnemang',
  [intl.riskMonitoringSubscription('name')]: 'Företagsnamn',
  [intl.riskMonitoringSubscription('phone_number')]: 'Telefonnummer',
  [intl.riskMonitoringSubscription('receive-updates-description')]:
    'När det finns ändringar som du tycker är relevanta skickar vi dem till dig.',
  [intl.riskMonitoringSubscription('receive-updates-title')]:
    'Uppdateringar av din e-post',
  [intl.riskMonitoringSubscription('regular-changes')]: 'Vanliga förändringar',
  [intl.riskMonitoringSubscription('relations')]: 'Relationer',
  [intl.riskMonitoringSubscription('remove-subscription')]: 'Ta bort avisering',
  [intl.riskMonitoringSubscription('score')]: 'Risika poäng',
  [intl.riskMonitoringSubscription('snackbar-failed-subscription-added')]:
    'Tyvärr kunde vi inte lägga till din prenumeration',
  [intl.riskMonitoringSubscription('snackbar-failed-subscription-removed')]:
    'Tyvärr kunde vi inte ta bort din prenumeration',
  [intl.riskMonitoringSubscription('snackbar-failed-subscription-updated')]:
    'Tyvärr kunde vi inte uppdatera din prenumeration',
  [intl.riskMonitoringSubscription('snackbar-subscription-added')]:
    'Din prenumeration har lagts till',
  [intl.riskMonitoringSubscription('snackbar-subscription-removed')]:
    'Din prenumeration har tagits bort',
  [intl.riskMonitoringSubscription('snackbar-subscription-updated')]:
    'Din prenumeration har uppdaterats',
  [intl.riskMonitoringSubscription('status')]: 'Företagsstatus',
  [intl.searchBusiness('api-error')]: 'Begäran misslyckades: {meddelande}',
  [intl.searchBusiness('clear-range-picker')]: 'Återställ Risika poäng',
  [intl.searchBusiness('company')]: 'Företag',
  [intl.searchBusiness('employees')]: 'Anställdas',
  [intl.searchBusiness('local-id')]: 'Företags ID',
  [intl.searchBusiness('max-export')]:
    '{count plural other {Eksportering begrænset til de første {count} rækker}}',
  [intl.searchBusiness('no-data')]: 'Ingen data tillgänglig',
  [intl.searchBusiness('personal-id')]: 'Personligt ID',
  [intl.searchBusiness('results-time-elapsed')]:
    '{{count, plural, one {# result} other {# results}} på {hour, plural other {#}} sekunder',
  [intl.searchBusiness('risika-score')]: 'Risika poäng',
  [intl.searchBusiness('search-field')]: 'Sök',
  [intl.searchBusiness('search-placeholder')]:
    'Skriv företagsnamn/ID (CVR), adress, e-post eller telefonnr (minst 3 tecken)',
  [intl.searchBusiness('selector-companies')]:
    '{count, plural, one {Virksomhed ({countDisplay})} other {Virksomheder ({countDisplay})}}',
  [intl.searchBusiness('selector-no-companies')]:
    'Vi kunde inte hitta några företag. Söker efter människor istället.',
  [intl.searchBusiness('selector-people')]:
    '{count, plural, one {Person (#)} other {Persons (#)}}',
  [intl.searchBusiness('people')]: 'Persons',
  [intl.searchBusiness('status')]: 'Status',
  [intl.searchBusiness('title')]: 'Sök',
  [intl.searchBusiness('type')]: 'Typ',
  [intl.settings('address')]: 'Företagsadress',
  [intl.settings('all-users')]: 'Alla användare',
  [intl.settings('are-you-sure-delete-user')]:
    'Är du säker på att du vill ta bort den här användaren?',
  [intl.settings('cant-delete-yourself')]: 'Du kan inte ta bort dig själv!',
  [intl.settings('cant-have-user-and-admin')]:
    'Du kan antingen välja "Användare" eller "Administratör"!',
  [intl.settings('code-helper-text')]: 'Kopiera texten och lägg till på din webbplats',
  [intl.settings('company-details-title')]: 'Detaljer om ditt möte',
  [intl.settings('company-information-title')]: 'Företagsinformation',
  [intl.settings('company-information-updated-success')]:
    'Företagsinformationen har uppdaterats',
  [intl.settings('confirm-new-password')]: 'Bekräfta nytt lösenord',
  [intl.settings('confirm-password-requirements')]:
    'Bekräftelselösenordet måste vara detsamma som lösenordet',
  [intl.settings('content-copied')]: 'Innehållet kopierat',
  [intl.settings('copy-icon')]: 'Kopiera innehåll',
  [intl.settings('customerType')]: 'Prenumeration',
  [intl.settings('dark')]: 'Mörk',
  [intl.settings('domain-label')]: 'domän',
  [intl.settings('email-validation')]: 'E-postadress ogiltig',
  [intl.settings('phone-validation')]: 'Felaktigt telefonnummer',
  [intl.settings('name-validation')]: 'Vänligen lägg till användarnamn',
  [intl.settings('general-information')]: 'Allmän information',
  [intl.settings('generate')]: 'Generera',
  [intl.settings('invalid-phone')]: 'Ogiltigt telefonnummer',
  [intl.settings('invalid-url')]: 'Lägg till vänlista en giltig URL',
  [intl.settings('invalid-url-phone')]: 'Ogiltig webbadress och telefonnummer',
  [intl.settings('large')]: 'Stor',
  [intl.settings('last-login')]: 'Sista aktiviteten',
  [intl.settings('light')]: 'Ljus',
  [intl.settings('link-type')]: 'Länktyp',
  [intl.settings('logo-title')]:
    'Steg 1: Berätta för oss var du vill använda vår logotyp',
  [intl.settings('mail-send')]: 'Email skickat',
  [intl.settings('medium')]: 'Medium',
  [intl.settings('monitoring-used')]: 'Antal övervakningar',
  [intl.settings('name')]: 'Företagsnamn',
  [intl.settings('new-password')]: 'Nytt lösenord',
  [intl.settings('password-requirements')]: 'Ditt lösenord måste vara minst 8 tecken',
  [intl.settings('password-title')]: 'Lösenord',
  [intl.settings('password-updated-successfully')]: 'Lösenordet har uppdaterats',

  [intl.settings('add-user-button')]: 'Lägg till användare',
  [intl.settings('user-create-success')]: 'Användaren har skapats',
  [intl.settings('user-create-error')]: 'Det gick inte att skapa en användare',
  [intl.settings('user-update-success')]: 'Användare uppdaterad',
  [intl.settings('user-update-error')]: 'Misslyckades med att uppdatera användaren',
  [intl.settings('user-delete-success')]: 'Användare borttagen',
  [intl.settings('user-delete-error')]: 'Misslyckades med att ta bort användare',
  [intl.settings('user-table-results-per-page')]: 'Resultat per sida',

  [intl.settings('phone-label')]: 'Telefonnummer',
  [intl.settings('preview-title')]: 'Steg 2: Designa din logotyp',
  [intl.settings('radio-text')]: 'Vilken bakgrund kommer logotypen att ha på?',
  [intl.settings('remainingMonitoring')]: 'Återstående övervakning',
  [intl.settings('remainingUsers')]: 'Återstående användare',
  [intl.settings('risika-logo')]: 'Skaffa en Risika-logga',
  [intl.settings('see-code')]: 'Se koden',
  [intl.settings('select-helper-text')]: 'HTML eller URL?',
  [intl.settings('share-logo')]: 'Dela logotyp',
  [intl.settings('share-logo-message')]:
    'Lägg till ett meddelande tillsammans med logotypen',
  [intl.settings('size-text')]: 'Storleken på logotypen',
  [intl.settings('small')]: 'Små',
  [intl.settings('step-3')]: 'Steg 3: Skicka koden till din utvecklare',
  [intl.settings('termsOfService')]: 'Affärsvillkor',
  [intl.settings('title')]: 'Inställningar',
  [intl.settings('update-avatar')]: 'UPPDATERA AVATAR',
  [intl.settings('update-company')]: 'Uppdatera företaget',
  [intl.settings('update-password')]: 'Uppdatera lösenord',
  [intl.settings('update-user')]: 'Uppdatera användare',
  [intl.settings('user-information-title')]: 'Användar information',
  [intl.settings('user-management')]: 'Användarhantering',
  [intl.settings('users-used')]: 'Antal användare',
  [intl.settings('whitelist')]: 'Hemsida',
  [intl.settings('whitelist-helper-text')]:
    'När du har gått in på din webbplats trycker du på "enter" för att validera den. Tryck sedan på knappen Generera.',
  [intl.settings('scoring-model')]: 'Poängmodell',
  [intl.settings('click-to-change-currency')]: 'Klicka för att ändra valuta',
  [intl.settings('credit-override')]: 'Kreditöverskridning',

  [intl.settings('score-model-dk')]: 'Poängmodell (Danmark)',
  [intl.settings('score-model-se')]: 'Poängmodell (Sverige)',
  [intl.settings('score-model-no')]: 'Poängmodell (Norge)',
  [intl.settings('score-model-uk')]: 'Poängmodell (STORBRITANNIEN)',
  [intl.settings('score-model-fi')]: 'Poängmodell (Finland)',
  [intl.settings('user-information-updated')]: 'Användaruppgifter har uppdaterats',
  [intl.settings('user-password-updated')]: 'Lösenordet har uppdaterats',

  [intl.shareReport('add-custom-message')]: 'Meddelande till mottagare (valfritt)',
  [intl.shareReport('select-recipient')]: 'Välj mottagare',
  [intl.shareReport('sender-name')]: 'Avsändarens namn (valfritt)',
  [intl.snackbar(
    'Your subscription plan does not allow you to monitor resources from NO'
  )]: 'Din prenumerationsplan tillåter dig inte att övervaka resurser från Norge',
  [intl.snackbar(
    'Your subscription plan does not allow you to monitor resources from SE'
  )]: 'Din prenumerationsplan tillåter dig inte att övervaka resurser från Sverige',
  [intl.snackbar(
    'Your subscription plan does not allow you to monitor this many companies'
  )]: 'Din prenumerationsplan tillåter dig inte att övervaka dessa många företags',
  [intl.snackbar('account-activated-failed')]: 'Ett problem uppstod',
  [intl.snackbar('account-activated-success')]: 'Perfekt! Du kan nu börja använda Risika',
  [intl.snackbar('add-companies-monitoring')]: 'Företagen övervakas nu',
  [intl.snackbar('add-companies-monitoring-failed')]:
    'Import misslyckades: kontrollera att en av kolumnerna heter "cvr"',
  [intl.snackbar('add-company-monitoring')]: 'Företaget övervakas nu',
  [intl.snackbar('added-back')]: 'Övervakas igen',
  [intl.snackbar('all-added-back')]: 'Företag övervakas igen',
  [intl.snackbar('companies-added-to-list')]: 'Företag har lagts till i {list}',
  [intl.snackbar('companies-added')]: 'Företag har lagts till',
  [intl.snackbar('companies-failed-to-add')]: 'Företagen kunde inte läggas till i {list}',
  [intl.snackbar('company-added-to-list')]: 'Företaget har lagts till i {list}',
  [intl.snackbar('company-removed-from-list')]: 'Företaget har tagits bort från {list}',
  [intl.snackbar('copied')]: 'Kopierade',
  [intl.snackbar('dismiss')]: 'Stänga',
  [intl.snackbar('export-failed')]: 'Din export misslyckades',
  [intl.snackbar('export-success')]: 'Din export är klar',
  [intl.snackbar('failed-to-add-company')]: 'Kunde inte skapa företaget',
  [intl.snackbar('failed-to-add-emails')]: 'Kunde inte skapa användare med e-post',
  [intl.snackbar('fields-required')]: 'Vänligen fyll i de obligatoriska fälten',
  [intl.snackbar('invalid-email')]: 'Vänligen lägg till en giltig e-post',
  [intl.snackbar('logo-shared-succes')]: 'Logotypen kommer att skickas.',
  [intl.snackbar('maximum-export-exceded-exporting-less')]:
    'Maximalt antal företag att exportera. Export av de första {exportLimit} företagen',
  [intl.snackbar('no-subsidiaries')]: 'Bolaget har inga dotterbolag',
  [intl.snackbar('password-not-allowed')]: 'Vän väljer ett annat lösenord',
  [intl.snackbar('remove-companies-monitoring')]: 'Alla företag övervakas inte längre',
  [intl.snackbar('remove-company-monitoring')]: 'Företaget övervakas inte längre',
  [intl.snackbar('remove-failed')]: 'Ett fel uppstod när din webhook skulle tas bort',
  [intl.snackbar('remove-successfull')]: 'Din webhook har tagits bort',
  [intl.snackbar('report-error-something-wrong')]: 'Ett problem uppstod',
  [intl.snackbar('report-error-no-data-available')]: 'Data ej tillgängliga',
  [intl.errors('observation-list-no-data-to-load')]:
    'Tyvärr har vi för närvarande inte de nödvändiga uppgifterna för att visa denna grafik',
  [intl.snackbar('report-error-timeout')]: 'För närvarande har vi ingen rapport för',
  [intl.snackbar('report-failed-to-submit')]: 'Rapporten kunde inte lämnas',
  [intl.snackbar('report-submited')]: 'Rapporten har skickats framgångsrikt',
  [intl.snackbar('reset-filters')]: 'Filter har återställts',
  [intl.snackbar('settings-saved')]: 'Inställningar Sparade',
  [intl.snackbar('shared-succes')]: 'Rapporten skickas.',
  [intl.snackbar('test-failed')]: 'Ett fel uppstod med testet: {error}',
  [intl.snackbar('test-successfull')]: 'Du kommer att få ett exempelsvar inom 2 minuter',
  [intl.snackbar('undo')]: 'Ångra',
  [intl.snackbar('users-added-successfully')]: 'Alla användare skapade',
  [intl.table('loading')]: 'Lastare...',
  [intl.table('next')]: 'Nästa',
  [intl.table('no-rows')]: 'Inga rader tillgängliga',
  [intl.table('of')]: 'av',
  [intl.table('page')]: 'Sida',
  [intl.table('previous')]: 'Tidigare',
  [intl.table('rows')]: 'rader',
  [intl.toolbar('add-email')]: 'Lägg till e-post',
  [intl.toolbar('add-phone')]: 'Lägg till telefon',
  [intl.toolbar('billing-plan')]: 'Faktureringsplan',
  [intl.toolbar('edit-profile')]: 'Redigera profil',
  [intl.toolbar('greeting')]: 'Hej',
  [intl.toolbar('logout')]: 'Logga ut',
  [intl.toolbar('role')]: 'Roll',
  [intl.toolbar('welcome-to-risika')]: 'Välkommen till Risika Dashboard',
  [intl.tooltip('click-to-copy')]: 'Klicka för att kopiera',
  [intl.userManagement('delete-dialog-title')]: 'Ta bort användare',
  [intl.userManagement('delete-dialog-content')]:
    'Genom att ta bort den här användaren samtycker du till att permanent återkalla dennes åtkomst till alla Risika-produkter och API. Är du säker på att du vill fortsätta?',
  [intl.userManagement('failed-to-load-users')]: 'Det gick inte att ladda användarnas',
  [intl.userManagement('invalid-email')]: 'Ogiltig e-postadress',
  [intl.userManagement('invite-header')]: 'Bjud in användare till Risika',
  [intl.userManagement('invite-users-button')]: 'Investera användares',
  [intl.userManagement('invites-send-failed')]:
    'Vi kunde inte skicka e-post till {email}',
  [intl.userManagement('invites-send-success')]: 'Inbjudan till {email} har skickats',
  [intl.userManagement('maximum-invites-reached')]:
    'Du har nått din maximala inbjudan. Klicka här för att köpa mer',
  [intl.userManagement('more-than-30')]: '30+ dagar',
  [intl.userManagement('no-data-yet')]: 'Inga data',
  [intl.userManagement('role-change-failed')]: 'Kunde inte byta roll',
  [intl.userManagement('role-change-success')]: 'Så småningom bytte roll',
  [intl.userManagement('send-invite-button')]: 'Inbjudan',
  [intl.userManagement('user-delete-failed')]: 'Användaren kunde inte raderas',
  [intl.userManagement('user-delete-success')]: 'Användaren togs bort',
  [intl.visualScore('high-risk')]: 'Hög risk',
  [intl.visualScore('low-risk')]: 'Låg risk',
  [intl.visualScore('medium-risk')]: 'Medel risk',
  [intl.companyComparison('title')]: 'Jämföra',
  [intl.companyComparison('add-to-comparison-button')]: 'Lägg till för att jämföra',
  [intl.companyComparison('add-to-comparison-tooltip')]:
    'Lägg till detta företag till en jämförande lista',
  [intl.companyComparison('dialog-cannot-add-title')]: 'Företaget kunde inte lägga till',
  [intl.companyComparison('dialog-cannot-add-content')]:
    'Företaget kunde inte läggas till i jämförelselistan.',
  [intl.autoSelectCompanies('search-box-title')]: 'Sök företag',
  [intl.keyFigures('title')]: 'Nyckelfigurer',
  [intl.keyFiguresDevelopment('title')]: 'Financial Ratios Development',
  [intl.keyFiguresDevelopment('liquidity-ratio')]: 'Kassalikviditeten',
  [intl.keyFiguresDevelopment('result')]: 'Resultat',
  [intl.charts('radar-tooltip-score-since')]: 'Eftersom',

  [intl.charts('radar-tooltip-distress')]: 'Sannolikhet för konkurs: {value}%',
  [intl.charts('radar-tooltip-current-ratio')]: 'Kortfristiga skulder: {value}',
  [intl.charts('radar-tooltip-explanation')]: 'Detta anses vara {rating} i sin bransch',

  [intl.charts('radar-tooltip-ratio')]:
    '{key} är på {value}%, vilket anses vara en {rating} {key} jämfört med andra företag i samma bransch.',
  [intl.charts('radar-key-figures-info')]:
    'Diagrammet visar hur företagets nyckeltal ser ut jämfört med andra företag i samma bransch. De fyra valda nyckeltalen representerar bolagets lönsamhet, solvens, likviditet och konkursrisk.',
  [intl.alikeCompanies('dialog-not-found-title')]: 'Inget företag att visa',
  [intl.alikeCompanies('dialog-not-found-content')]: 'Inget liknande företag hittades.',
  [intl.alikeCompanies('tooltip')]:
    'Ett liknande företag kommer att hittas baserat på det första företaget som är med i jämförelsen. Liknande företag finns baserat på aktiva företag som finns i samma bransch och som har ungefär samma antal anställda och kapital.',
  [intl.portfolioInsights('drawer-trigger-button')]: 'Portfolio Insights',
  [intl.portfolioInsights('tab-summary-label')]: 'Sammanfattning',
  [intl.portfolioInsights('tab-compliance-label')]: 'Efterlevnad',
  [intl.portfolioInsights('tab-scheduler-label')]: 'Planen',
  [intl.portfolioInsights('tab-scheduler-destination')]: 'Mottagare',
  [intl.portfolioInsights('tab-scheduler-destination-email')]: 'E-post',
  [intl.portfolioInsights('tab-scheduler-destination-email-recipients')]:
    'E-postmottagare',
  [intl.portfolioInsights('tab-scheduler-emails-list-placeholder')]:
    'Ange minst en mottagare',
  [intl.observationalLists('press-enter-email')]:
    'Tryck på Enter när du har angett din e-postadress för att verifiera',
  [intl.portfolioInsights('tab-scheduler-emails-list-invalid-email-warning')]:
    'Ogiltig e-postadress. Se till att alla e-postadresser är korrekta.',
  [intl.portfolioInsights('tab-scheduler-frequency-label')]: 'Frekvens',
  [intl.portfolioInsights('tab-scheduler-frequency-send-every')]: 'Skicka var och en',
  [intl.portfolioInsights('tab-scheduler-frequency-week')]: 'Vecka',
  [intl.portfolioInsights('tab-scheduler-frequency-month')]: 'Månad',
  [intl.portfolioInsights('tab-scheduler-frequency-year')]: 'År',
  [intl.portfolioInsights('tab-scheduler-month-first-day')]: 'Första dagen i månaden',
  [intl.portfolioInsights('tab-scheduler-month-first-week-day')]:
    'Första vardagen i månaden',
  [intl.portfolioInsights('tab-scheduler-month-last-day')]: 'Sista dagen i månaden',
  [intl.portfolioInsights('tab-scheduler-month-last-week-day')]:
    'Sista vardagen i månaden',
  [intl.portfolioInsights('tab-scheduler-frequency-send-on')]: 'Dag',
  [intl.portfolioInsights('tab-scheduler-frequency-starting-from')]: 'Med början från',
  [intl.portfolioInsights('tab-scheduler-frequency-day-mon')]: 'Du',
  [intl.portfolioInsights('tab-scheduler-frequency-day-tue')]: 'Tisdag',
  [intl.portfolioInsights('tab-scheduler-frequency-day-wed')]: 'Onsdag',
  [intl.portfolioInsights('tab-scheduler-frequency-day-thu')]: 'Torr',
  [intl.portfolioInsights('tab-scheduler-frequency-day-fri')]: 'Fredag',
  [intl.portfolioInsights('tab-scheduler-frequency-day-sat')]: 'Lör',
  [intl.portfolioInsights('tab-scheduler-frequency-day-sun')]: 'Son',
  [intl.portfolioInsights('tab-scheduler-status')]: 'Status',
  [intl.portfolioInsights('tab-scheduler-status-active')]: 'Aktiva',
  [intl.portfolioInsights('tab-scheduler-status-disabled')]: 'Inaktiverad',
  [intl.portfolioInsights('tab-scheduler-save-changes')]: 'Spara ändringar',
  [intl.portfolioInsights('tab-scheduler-subscription-create-successful')]:
    'Alternativ tillagt',
  [intl.portfolioInsights('tab-scheduler-subscription-update-successful')]:
    'Inställning uppdaterad',
  [intl.portfolioInsights('tab-scheduler-subscription-create-fail')]:
    'Kunde inte lägga till inställning',
  [intl.portfolioInsights('tab-scheduler-subscription-update-fail')]:
    'Kunde inte uppdatera inställningen',
  [intl.portfolioInsights('portfolio-score')]: 'PORTFÖLJPOÄNG',
  [intl.portfolioInsights('points-of-attention')]: 'Uppmärksamhetspunkter',
  [intl.portfolioInsights('portfolio-risk-distribution')]: 'Portföljriskfördelning',
  [intl.portfolioInsights('portfolio-risk-distribution-subtitle')]:
    '{currentCompaniesCount} av {allCompaniesCount} företag',
  [intl.portfolioInsights('Low risk')]: 'Låg risk',
  [intl.portfolioInsights('Medium risk')]: 'Medel risk',
  [intl.portfolioInsights('High risk')]: 'Hög risk',
  [intl.portfolioInsights('Unknown risk')]: 'Okänd risk',
  [intl.portfolioInsights('portfolio-risk-history')]: 'Portföljriskhistorik',
  [intl.portfolioInsights('credit-policy-compliance')]: 'Efterlevnad av kreditpolicy',
  [intl.portfolioInsights('credit-policy-violation')]: 'Brott mot kreditpolicy',
  [intl.portfolioInsights('violations')]: 'Överträdelse',
  [intl.portfolioInsights('compliant-label')]: 'Kompatibel',
  [intl.portfolioInsights('non-compliant-label')]: 'Ej kompatibel',
  [intl.portfolioInsights('compliance-history')]: 'Efterlevnadshistorik',
  [intl.portfolioInsights('avg.')]: 'avg.',
  [intl.portfolioInsights('approved')]: 'Kredit godkänd',
  [intl.portfolioInsights('rejected')]: 'Kredit avslås',
  [intl.portfolioInsights('manual-check')]: 'Manuell kontroll',
  [intl.portfolioInsights('no-policy-set')]: 'Ingen policy tillämpas',
  [intl.portfolioInsights('allowed-label')]: 'Godkänd',
  [intl.portfolioInsights('manual_check-label')]: 'Manuell kontroll',
  [intl.portfolioInsights('rejected-label')]: 'Avvisad',
  [intl.portfolioInsights('do_not_evaluate-label')]: 'Ingen policy',

  [intl.errors('hubspot_error')]:
    'E-postverifiering misslyckades. Använd en företags e-post eller kontakta vår kundtjänst',
  [intl.errors('companies_local_id_country_unique')]: 'CVR används redan',
  [intl.errors('company_already_exists')]: 'CVR används redan',
  [intl.errors('invalid_local_id')]: 'Du har använt ett ogiltigt CVR-nummer',
  [intl.errors('error_creating_new_customer')]:
    'Något gick fel. Vänligen kontakta vår kundtjänst på contact@risika.com',
  [intl.errors('observation-list-can-t-be-loaded')]:
    'Tyvärr kan vi inte ladda data för denna grafik just nu, vänligen försök igen senare.',
  [intl.errors('service-unavailable')]: 'Tjänsten Otillgänglig',
  [intl.errors('service-unavailable-description')]:
    'Vi kunde inte hämta detaljerna just nu. Vänligen försök igen senare.',
  [intl.errors('contract-min-date-error')]:
    'Kontraktets slutdatum kan inte vara tidigare än startdatumet.',
}

export default message
