import { paginate } from 'services/helpers/paginate'
import risikaAPI from 'services/api/helpers/risikaAPI'

const PAGE_SIZE = 1000

function formatNotes(notes) {
  return notes.map(({ data, local_organization_id }) => ({
    data,
    localId: local_organization_id,
  }))
}

function fetchNotesList({ from = 0, to = PAGE_SIZE } = {}) {
  return risikaAPI
    .post(
      `/notes/list`,
      {
        rows: {
          from,
          to,
        },
      },
      {
        microservice: 'account',
      }
    )
    .then(({ data }) => {
      return {
        data: formatNotes(data.notes),
        count: data.count,
        rows: data.rows,
      }
    })
    .catch((e) => ({
      data: [], // Return an empty result to prevent promise rejection
      count: 0,
      rows: [],
    }))
}

export default async function notesList() {
  const initialCall = await fetchNotesList({ from: 0, to: PAGE_SIZE })

  const pages = paginate(PAGE_SIZE, initialCall.count, { startPage: 1 })

  if (pages.length === 0) {
    return initialCall
  }

  const promises = pages.map((pageRange) => fetchNotesList(pageRange))

  return Promise.allSettled(promises)
    .then((results) => {
      let result = initialCall.data

      results.forEach((res) => {
        if (res.status === 'fulfilled') {
          result = result.concat(res.value.data)
        } else {
          console.error('Fetch notes list error:', res.reason)
        }
      })

      return { data: result, count: initialCall.count }
    })
    .catch((e) => ({ data: initialCall.data, count: initialCall.count }))
}
