import React from 'react'
import { Box, Skeleton, Stack, SxProps, Typography, useMediaQuery } from '@mui/material'
import SectionSkeleton from './SectionSkeleton'

type Props = {
  title: string
  children?: React.ReactNode
  sx?: SxProps
}

const Section = ({ title, children, sx }: Props) => {
  const isSmallScreen = useMediaQuery('(max-width: 820px)')

  // TODO: get the loading flag from the fetch fraud policy api call
  const isPolicyFetching = false

  return (
    <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={6} sx={sx}>
      <Box sx={{ width: isSmallScreen ? 'auto' : 440 }}>
        <Typography variant="h5">
          {isPolicyFetching ? <Skeleton width={'50%'} /> : title}
        </Typography>
      </Box>
      <Box sx={{ width: isSmallScreen ? 'auto' : 520 }}>
        <Stack spacing={6}>{isPolicyFetching ? <SectionSkeleton /> : children}</Stack>
      </Box>
    </Stack>
  )
}

export default Section
