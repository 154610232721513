import * as React from 'react'
import { Stack, FormControl, Select, MenuItem, Typography, Skeleton } from '@mui/material'

import { useIntl } from 'react-intl'
import intl from 'localization/components'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { CountryFlagIcon } from 'components-new'

const styles = {
  root: (open) => ({
    flex: 'stretch',
    padding: 3,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    border: open ? '2px solid' : 'none',
    borderColor: 'primary.500',
    ' .MuiSelect-select': {
      paddingY: 0,
      paddingLeft: 0,
      paddingRight: '12px !important',
      margin: 0,
      minHeight: 'auto',
      display: 'flex',
    },
    svg: {
      marginRight: 0,
    },
  }),
  menu: {
    left: 0,
    padding: 0,
    '& .MuiMenu-list': { paddingY: 2 },
    '& .MuiMenu-paper': {
      padding: 0,
      boxShadow: 2,
      border: '1px solid',
      borderColor: 'grey.200',
    },
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 3,
    gap: 2,
    '& img': {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
}

const defaultCountries = ['dk', 'se', 'no', 'fi']

const CountryPicker = ({ value, onChange, className, style, allowedCountries }) => {
  const reactIntl = useIntl()
  const [isOpen, setIsOpen] = React.useState(false)

  const countries = React.useMemo(() => {
    return defaultCountries
      .map((country) => ({
        label: reactIntl.formatMessage({ id: intl.country(country) }),
        value: country,
      }))
      .filter((country) => {
        if (!allowedCountries || !allowedCountries.length) return true
        return allowedCountries.includes(country.value)
      })
  }, [allowedCountries, reactIntl])

  const [canUserChangeCountry, setCanUserChangeCountry] = React.useState(false)

  React.useEffect(() => {
    setCanUserChangeCountry(countries.length > 1)
  }, [countries])

  const renderSelectedValue = (selected) => {
    const selectedCountry = countries.find(({ value }) => value === selected)

    if (!selectedCountry) {
      return <Skeleton height={40} width={60} />
    }

    return (
      <Stack pl={0} direction="row" alignItems="center" spacing={2}>
        <CountryFlagIcon country={selectedCountry.value} />
        <Typography variant="body1">{selectedCountry.label}</Typography>
      </Stack>
    )
  }

  const renderIcon = () => {
    const iconStyles = { cursor: 'pointer', color: 'primary.700' }
    return isOpen ? (
      <ExpandLessIcon sx={iconStyles} onClick={() => setIsOpen(false)} />
    ) : (
      <ExpandMoreIcon sx={iconStyles} onClick={() => setIsOpen(true)} />
    )
  }
  return (
    <FormControl variant="outlined" className={className} style={style}>
      <Select
        IconComponent={renderIcon}
        sx={{ ...styles.root(isOpen) }}
        variant="standard"
        disableUnderline
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        MenuProps={{
          sx: { ...styles.menu },
        }}
        value={value}
        onChange={onChange}
        disabled={!canUserChangeCountry}
        renderValue={renderSelectedValue}
      >
        {countries.map(({ label, value, flagSrc }) => (
          <MenuItem key={value} value={value} sx={styles.menuItem}>
            <CountryFlagIcon country={value} />
            <Typography variant="body1">{label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CountryPicker
