// @flow

import * as React from 'react'
import { FormGroup, Typography, Stack } from '@mui/material'
import { Option } from 'components'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const generalDataEng = [
  'Company ID',
  'Name',
  'Advertisement Protected',
  'Date of Incorporation',
  'Company Type',
  'Number of Employees',
  'Street Name',
  'Street Number',
  'Zipcode',
  'Municipality',
  'Region',
  'City',
  'Country',
  'Email',
  'Email Hidden',
  'Phone',
  'Phone Hidden',
  'Webpage',
  'Industry Code',
  'Industry Name',
  'Powers to Bind',
  'Management',
]

const generalDataDa = [
  'Virksomheds ID',
  'Navn',
  'Reklamebeskyttet',
  'Stiftelsesdato',
  'Virksomhedstype',
  'Antal ansatte',
  'Vejnavn',
  'Vejnummer',
  'Postnummer',
  'By',
  'Region',
  'Kommune',
  'Land',
  'E-mail',
  'E-mail skjult',
  'Telefon',
  'Telefon skjult',
  'Hjemmeside',
  'Branchekode',
  'Branchekode navn',
  'Tegningsregel',
  'Ledelse',
]

const financialDataEng = [
  'Current Financial Assets',
  'Currency',
  'Revenue',
  'Gross Result',
  'EBITDA',
  'Profit and Loss',
  'Intangible Assets',
  'Property Plant and Equipment',
  'Noncurrenct Assets',
  'Inventories',
  'Short Term Receivables From Sales and Services',
  'Cash',
  'Assets',
  'Equity',
  'Capital',
  'Provisions',
  'Long Term Debt',
  'Short Term Debt',
  'Liabilities and Equity',
  'Gross Margin',
  'Return on Equity',
  'Solidity Ratio',
  'Report start',
  'Report end',
  'Bank',
  'Type of Auditor Assistance',
  'Name of Auditor',
]

const financialDataDa = [
  'Værdipapirer',
  'Valuta',
  'Omsætning',
  'Bruttofortjeneste',
  'EBITDA',
  'Årsresultat',
  'Immaterielle aktiver',
  'Materialle anlægsaktiver',
  'Anlægsaktiver i alt',
  'Varelager',
  'Tilgodehavender fra salg og tjenesteydesler',
  'Likvider',
  'Aktiver',
  'Egenkapital',
  'Selskabskapital',
  'Hensættelser',
  'Langfristet gæld',
  'Kortfristet gæld',
  'Balancesum',
  'Bruttomargin',
  'Egenkapitalforrentning',
  'Soliditetsgrad',
  'Regnskabsår start',
  'Regnskabsår slut',
  'Bank',
  'Revisionspåtegning',
  'Revisornavn',
]

const getDataByLang = (lang, { dataEng, dataDa }) => {
  if (lang !== 'da_DK') {
    return dataEng
  } else {
    return dataDa
  }
}

const Section = ({
  title,
  selectAllLabel,
  data,
  checkAllString,
  handleChecked,
  handleSelectSection,
  selectedFields,
  groupSelected,
}) => {
  const columnSize = Math.ceil(data.length / 2)
  const leftSide = data.slice(0, columnSize)
  const rightSide = data.slice(columnSize, data.length - 1)
  const selectedFieldsFromCategory = React.useMemo(
    () => selectedFields.filter((field) => data.find((item) => item === field)),
    [data, selectedFields]
  )
  const isCategoryIndeterminate =
    selectedFieldsFromCategory.length && selectedFieldsFromCategory.length < data.length
  return (
    <Stack gap={1}>
      <Typography sx={{ marginLeft: '11px' }} variant="subtitle1">
        {title}
      </Typography>
      <Option
        checked={groupSelected}
        indeterminate={isCategoryIndeterminate}
        onChange={handleSelectSection}
        label={selectAllLabel}
      />
      <Stack gap={5} direction="row">
        <Stack width={260}>
          {leftSide.map((key) => (
            <Option
              key={key}
              checked={selectedFields.includes(key)}
              onChange={() => handleChecked(key)}
              name={key}
              label={key}
            />
          ))}
        </Stack>
        <Stack width={260}>
          {rightSide.map((key) => (
            <Option
              key={key}
              checked={selectedFields.includes(key)}
              onChange={() => handleChecked(key)}
              name={key}
              label={key}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}

/**
 *
 * To add a section please follow these comments. I promise it wont take long :D
 *
 *  1. Go to line 33 - 112 and create a new array with the keys you will use. One in Danish and one in English
 *  2. Now follow the comments in the file. Add value to default state line 203
 */

// const selectBoolsTrue = {
//   all: true,
//   general: true,
//   financial: true,
//   // keyFigures: true
//   // Next create a local variable line 225
// }

const defaultSelectBools = {
  all: false,
  general: false,
  financial: false,
  // keyFigures: false
  // Dont forget to add one with true to the one above :D
}

const SelectFieldsContent = ({ setSelectedFields, selectedFields, data, lang }) => {
  const [selectBools, setSelectBools] = React.useState(defaultSelectBools)

  const generalData = getDataByLang(lang, {
    dataEng: generalDataEng,
    dataDa: generalDataDa,
  })
  const financialData = getDataByLang(lang, {
    dataEng: financialDataEng,
    dataDa: financialDataDa,
  })

  // Create a local variable that will depend on the user language. Just use the function provided above.
  // Next line 233

  // const handleSelectAll = () => {
  //   if (!selectBools.all) {
  //     setSelectBools(selectBoolsTrue)
  //     setSelectedFields([...generalData, ...financialData])
  //     // Add the new section to the select all function :)
  //     // Next create a section element in the render method. Line 302
  //   } else {
  //     setSelectedFields([])
  //     setSelectBools(defaultSelectBools)
  //   }
  // }

  const handleSelectSection = (sectionSelected, sectionData, selection) => {
    if (!sectionSelected) {
      setSelectBools({
        ...selectBools,
        [selection]: true,
      })
      setSelectedFields([...new Set([...selectedFields, ...sectionData])])
    } else {
      setSelectedFields(selectedFields.filter((x) => !sectionData.includes(x)))
      setSelectBools({
        ...selectBools,
        [selection]: false,
      })
    }
  }

  const handleChecked = (name) => {
    if (selectedFields.includes(name)) {
      setSelectedFields(selectedFields.filter((x) => x !== name))
    } else {
      setSelectedFields([...selectedFields, name])
    }
  }

  return (
    <FormGroup>
      <Stack gap={5}>
        <Section
          title={<FormattedMessage id={intl.advancedSearch('general-data-section')} />}
          selectAllLabel={
            <FormattedMessage id={intl.advancedSearch('general-data-select-all')} />
          }
          data={generalData}
          checkAllString="all-general"
          handleChecked={handleChecked}
          handleSelectSection={() =>
            handleSelectSection(selectBools.general, generalData, 'general')
          }
          selectedFields={selectedFields}
          groupSelected={selectBools.general}
        />
        <Section
          title={<FormattedMessage id={intl.advancedSearch('financial-data-section')} />}
          selectAllLabel={
            <FormattedMessage id={intl.advancedSearch('financial-data-select-all')} />
          }
          data={financialData}
          handleChecked={handleChecked}
          handleSelectSection={() =>
            handleSelectSection(selectBools.financial, financialData, 'financial')
          }
          selectedFields={selectedFields}
          groupSelected={selectBools.financial}
        />
        {/* <Section
          title="Key Figure Data"
          selectAllLabel="Select all key figure Data"
          data={financialData}
          handleChecked={handleChecked}
          handleSelectSection={() =>
            handleSelectSection(
              selectBools.keyFigures, // The boolean that you created in the default state
              financialData, // The array with keys that you made first
              'keyFigures', // The key of the boolean variable in the default state
            )
          }
          selectedFields={selectedFields}
          groupSelected={selectBools.keyFigures} // Again the boolean that you created in the default state
        /> */}
      </Stack>
    </FormGroup>
  )
}
// You are done :D Hope you liked the ride on the BG train :D
// Oh yea and thanks for making the localizations for me :D
export default SelectFieldsContent
