import React, { useState } from 'react'
import { FraudIndicatorsPromotion } from '../FraudIndicatorsPromotion'
import { useAccountStatus } from 'services/queries'
import { FraudIndicatorsModal } from '../Modals/FraudIndicatorsModal'
import { getFeatureFlag } from 'feature-flags/getFeatureFlag'
import { FEATURE_FLAGS } from 'feature-flags/FeatureFlags'
import { env } from 'services/env'

export const FraudIndicatorsCTA = () => {
  const accountStatusQuery = useAccountStatus()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const userHasFraudIndicators =
    // @ts-ignore - this will be resolved once ENG2-1131 is merged in
    accountStatusQuery?.data?.rights?.global?.fraud_indicators

  if (userHasFraudIndicators) {
    return null
  }

  return (
    <>
      {(getFeatureFlag(FEATURE_FLAGS.FRAUD_INDICATORS) ||
        env.FRAUD_MVP_DEMO_ENV === 'true') && (
        <>
          <FraudIndicatorsPromotion onClick={() => setIsModalOpen(true)} />
          <FraudIndicatorsModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        </>
      )}
    </>
  )
}
