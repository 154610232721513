import { Company } from 'risika-api-response-types'
import { Address } from 'types/general'

/*
  TODO: we are using two different types for the same address field
  Company.Basics['address'] and Address
  this should be unified.
  https://linear.app/risika/issue/ENG2-1229/unify-types-for-address-in-generalcompanyinformation
*/

export const handleAddressField = (address: Company.Basics['address']) => {
  if (!address) return ''

  if (Object.keys(address).length) {
    const { coname, country, number, postdistrict, street, zipcode, city } = address
    const maybeConame = coname ? `c/o ${coname}, ` : ''
    const maybeStreetAndNumber = (() => {
      if (street != null && number != null) {
        return `${street} ${number}, `
      } else if (street != null) {
        return `${street}, `
      } else {
        return ''
      }
    })()
    const maybeCountry = country != null ? country : ''
    const maybeZipcodeAndPostdistrict = (() => {
      const area = postdistrict || city
      if (zipcode != null && area != null) {
        return `${zipcode} ${area}, `
      } else if (zipcode != null) {
        return `${zipcode}${maybeCountry ? ', ' : ''}`
      } else if (area != null) {
        return `${area}`
      } else {
        return maybeCountry ? ' ' : ''
      }
    })()
    const finalAddress = `${maybeConame}${maybeStreetAndNumber}${maybeZipcodeAndPostdistrict}${maybeCountry}`
    if (finalAddress.endsWith(', ')) {
      return finalAddress.substring(0, finalAddress.length - 2)
    }
    return finalAddress
  }
}

export const generateMapLocation = (address: Address): string => {
  const { street, number, zipcode, city, postdistrict, municipality, country } = address

  const streetNumber = `${street} ${number}`
  const cityOrDistrict = `${city !== postdistrict ? `, ${city}` : ''}`
  const composedStreet = `${streetNumber}${cityOrDistrict}`
  const composedDistrict = postdistrict
    ? `${zipcode} ${postdistrict} ${
        municipality && !postdistrict.includes(municipality) ? `(${municipality})` : ''
      }, ${country}`
    : null
  const addressLines = !street
    ? [null, composedDistrict]
    : [composedStreet, composedDistrict]
  return addressLines.join(' ')
}
