import { useEffect, useState, useMemo, useRef } from 'react'
import { store } from 'context/store'
import {
  Stack,
  Popover,
  Typography,
  Chip,
  Box,
  Paper,
  ClickAwayListener,
} from '@mui/material'
import { useTreeSelect, createNode } from 'pages/advanced-filters/scenes/NestedSelect'
import { useIndustryList } from 'services/queries'
import { Show } from 'components'
import { equals } from 'ramda'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import { smallButtonStyles, chipStyles } from '../../styles/nested-select'
import { ButtonLink, ButtonSecondary, InputField } from 'components-new'
import IndustryTypeCheckbox from 'components-new/elements/IndustryTypeView/IndustryTypeCheckbox'
import IndustryTypeExpander from 'components-new/elements/IndustryTypeView/IndustryTypeExpander'
import { footerStyles } from 'components-new/elements/IndustryTypeView/IndustryTypeView.styles'
// import { addSelectAllToIndustryTypeData } from '../../../enterprise-credit-policy/widgets/PolicyBuilder/widgets/ActionPanel/widgets/DetailedComponentView/Actions/Actions.controller'

export const parseIndustryTree = (tree, overrides) => {
  const filters = store.getState().risika?.advancedFilters?.filters
  const treeFilter = filters?.filter((x) => x.filter === 'tree-industry')?.[0]
  const nodes = {}
  const parseTreeRec = (tree, parentId = null) => {
    for (const id in tree) {
      const item = tree[id]

      let existingNode = null
      if (id === parentId) {
        existingNode = nodes[id]
      }
      const newNode = createNode({
        id,
        label: existingNode?.label ?? `(${id}) ${item.title ?? ''}`,
        value: id,
        count: item.count,
        level: item.level,
        state: treeFilter?.value?.includes(id) ? 'checked' : 'unchecked',
        isRoot: item.level === 1,
        isLeaf: equals(item.group, {}),
        children: Object.keys(item.group),
        title: item.title,
        parent: existingNode?.parent ?? parentId,
        ...overrides,
      })
      Object.assign(nodes, newNode)

      if (Object.keys(item.group).length !== 0) {
        parseTreeRec(item.group, id)
      }
    }
  }
  parseTreeRec(tree)
  return nodes
}

// This function has a duplicate in Actions.controller.ts . There is a problem importing it from there because there are other dependencies in that file, that have not been resolved by the time it is imported here.
export const addSelectAllToIndustryTypeData = (data, messages) => {
  const roots = Object.keys(data).filter((key) => isNaN(+key))
  const ALL = 'ALL'
  const SELECT_ALL = {
    children: [...roots],
    count: 0,
    id: ALL,
    isExpanded: false,
    isHidden: false,
    isLeaf: false,
    isRoot: true,
    // label: 'Select all',
    label: messages[intl.advancedSearch('select-all')],
    level: 0,
    parent: null,
    state: 'unchecked',
    value: ALL,
  }
  const modified = { ALL: { ...SELECT_ALL }, ...data }

  roots.forEach((root) => {
    modified[root].parent = SELECT_ALL.value
  })
  modified[ALL] = SELECT_ALL
  return modified
}

const ChipComponent = ({ data, isLabelsShown, setIsLabelsShown }) => {
  if (!data.length) {
    return (
      <Typography sx={{ color: 'grey.600' }} variant="body1">
        <FormattedMessage id={intl.advancedFilters('no-industries-selected')} />
      </Typography>
    )
  }

  return (
    <Stack>
      <ButtonSecondary
        disabled={!data?.length}
        flexGrow={1}
        flexShrink={0}
        sx={{ ...smallButtonStyles }}
        onClick={() => {
          setIsLabelsShown(!isLabelsShown)
        }}
      >
        {isLabelsShown ? (
          <FormattedMessage
            id={intl.advancedFilters('hide-all')}
            values={{ count: data.length }}
          />
        ) : (
          <FormattedMessage
            id={intl.advancedFilters('show-all')}
            values={{ count: data.length }}
          />
        )}
      </ButtonSecondary>
      {isLabelsShown && (
        <Stack
          maxHeight={300}
          direction="row"
          width="100%"
          pb={2}
          mt={2}
          flexWrap="wrap"
          sx={{ overflowX: 'auto', overflowY: 'scroll' }}
        >
          <Show when={isLabelsShown}>
            {data.map((label) => (
              <Chip sx={chipStyles} key={label} label={label} variant="outlined" />
            ))}
          </Show>
        </Stack>
      )}
    </Stack>
  )
}

export default function NestedIndustrySelect({ country, onChange, filter }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [currentSelectedValues, setCurrentSelectedValues] = useState([])
  const [isLabelsShown, setIsLabelsShown] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const [isFirstRender, setIsFirstRender] = useState(true)
  const [prevTextValues, setPrevTextValues] = useState([])
  const [prevValues, setPrevValues] = useState([])
  const [shouldReInitiateState, setShouldReInitiateState] = useState(false)

  const containerRef = useRef(null)

  const { messages } = useIntl()

  const industryList = useIndustryList(country.toUpperCase())

  // Prevent the data from being re-parsed on every render, because it's expensive
  const data = useMemo(() => {
    if (!industryList?.data) {
      return null
    }
    return addSelectAllToIndustryTypeData(parseIndustryTree(industryList.data), messages)
  }, [industryList.data, filter]) // We want the data to be re-parsed when the filter changes

  useEffect(() => {
    setShouldReInitiateState(true)
    setIsFirstRender(true)
  }, [filter])

  const {
    ViewIndustryTypePolicyBuilder,
    search,
    reset,
    selectedValues,
    selectedTextValues,
    setState,
  } = useTreeSelect(data, {
    components: {
      Checkbox: IndustryTypeCheckbox,
      Expander: IndustryTypeExpander,
    },
    shouldReInitiateState,
    setShouldReInitiateState,
    spacing: 32,
  })

  const handleApply = () => {
    if (currentSelectedValues.toString() !== selectedValues.toString()) {
      setCurrentSelectedValues(selectedValues)
      onChange?.(selectedValues)
      setPrevValues(selectedValues)
      setPrevTextValues(selectedTextValues)
      setIsFirstRender(false)
    }
    setAnchorEl(null)
  }

  const handleScroll = () => {
    setAnchorEl(containerRef.current)
    setIsLabelsShown(false)
  }

  function handleOpen() {
    const parent = document.getElementById('main-content-custom')
    parent.addEventListener('scrollend', handleScroll, { once: true })

    // Distance of containerRef.current from the bottom of the viewport of the parent
    const distanceFromBottom =
      parent.clientHeight - containerRef.current.offsetTop + parent.scrollTop + 120

    if (distanceFromBottom < 600) {
      parent.scrollBy(0, 600 - distanceFromBottom)
    } else {
      setAnchorEl(containerRef.current)
      setIsLabelsShown(false)
      parent.removeEventListener('scrollend', handleScroll)
    }
  }

  function handleClose() {
    setAnchorEl(null)
    if (!isFirstRender) {
      setState(prevTextValues, prevValues)
    } else {
      setPrevValues(selectedValues)
      setPrevTextValues(selectedTextValues)
      setIsFirstRender(false)
    }
  }

  const handleClearAll = () => {
    reset()
    setPrevValues([])
    setPrevTextValues([])
    setCurrentSelectedValues([])
    onChange?.([])
    setAnchorEl(null)
  }

  const isOpen = Boolean(anchorEl)

  const handleSearch = (val) => {
    setSearchValue(val)
    search(val)
  }

  return (
    <Stack ref={containerRef}>
      {anchorEl ? null : (
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems:
              currentSelectedValues.length && isLabelsShown ? 'start' : 'center',
            justifyContent: 'center',
            border: (theme) => `${theme.palette.grey[200]} solid 1px`,
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
            backgroundColor: 'common.white',
            minHeight: 44,
            paddingX: '22px',
            paddingTop: isLabelsShown ? 2 : 0,
          }}
        >
          <ButtonSecondary
            sx={{
              ...smallButtonStyles,
              marginRight: '20px',
            }}
            size="small"
            onClick={handleOpen}
          >
            {<FormattedMessage id={intl.creditPolicy('select')} />}
          </ButtonSecondary>
          <ChipComponent
            data={selectedTextValues}
            isLabelsShown={isLabelsShown}
            setIsLabelsShown={setIsLabelsShown}
          />
        </Stack>
      )}
      <Popover
        PaperProps={{ sx: { backgroundColor: 'transparent' } }}
        open={isOpen}
        anchorEl={anchorEl}
        elevation={0}
        onClose={handleClose}
        sx={{ top: -4, left: -4 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Stack
            width={300}
            gap={1}
            m={1}
            justify="space-between"
            sx={{ backgroundColor: 'transparent' }}
          >
            <InputField
              value={searchValue}
              handleChange={handleSearch}
              extraStyles={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            />
            <Paper component={Stack} elevation={2} height={500}>
              <Box
                overflow="scroll"
                p={4}
                sx={{ backgroundColor: 'common.white', borderRadius: 1 }}
              >
                <ViewIndustryTypePolicyBuilder />
              </Box>
              <Stack sx={footerStyles}>
                <ButtonLink
                  data-cy="search-multi-select-clear-all"
                  onClick={handleClearAll}
                >
                  <FormattedMessage
                    id={intl.creditPolicyNew('search-multi-select-clear-all')}
                  />
                </ButtonLink>
                <ButtonSecondary
                  data-cy="search-multi-select-apply"
                  sx={{ color: '#FFF !important' }}
                  onClick={handleApply}
                >
                  <FormattedMessage
                    id={intl.creditPolicyNew('search-multi-select-apply')}
                  />
                </ButtonSecondary>
              </Stack>
            </Paper>
          </Stack>
        </ClickAwayListener>
      </Popover>
    </Stack>
  )
}
