import { Tooltip, Stack, Skeleton } from '@mui/material'
import { Button } from 'components'
import JsPDF from 'jspdf'
import 'jspdf-autotable'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faFileExcel, faLock } from '@fortawesome/free-solid-svg-icons'

import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'

import { handleComponentRenderRestrictions } from 'services/helpers'

import { useCreditCheck } from 'pages/credit-check/context'
import {
  useFinancialNumbers,
  useFinancialNumbersConsolidated,
  useCompanyBasics,
  useAccountStatus,
} from 'services/queries'

import { colors } from 'configs'

import moment from 'moment'
import { downloadAs } from 'services/export'
import { take } from 'ramda'

const ExportFinancialFigures = ({ isConsolidated, appendCurrencyURL }) => {
  const reactIntl = useIntl()
  const accountStatusQuery = useAccountStatus()

  // const financialNumbersQuery = useFinancialNumbers(localId, {}, appendCurrencyURL)

  const { localId } = useCreditCheck()
  const companyBasicsQuery = useCompanyBasics(localId)
  const financialNumbersQuery = useFinancialNumbers(localId, {}, appendCurrencyURL)
  const financialNumbersConsolidatedQuery = useFinancialNumbersConsolidated(localId)

  if (
    companyBasicsQuery.isFetching ||
    financialNumbersQuery.isFetching ||
    financialNumbersConsolidatedQuery.isFetching
  ) {
    return (
      <Stack spacing={1}>
        <Skeleton variant="rounded" width="170px" height={40} animation="wave" />
        <Skeleton variant="rounded" width="170px" height={40} animation="wave" />
      </Stack>
    )
  }

  const data = {
    financialNumbers: !isConsolidated
      ? financialNumbersQuery.data
      : financialNumbersConsolidatedQuery.data,
    ...companyBasicsQuery.data,
  }
  const disabled = !handleComponentRenderRestrictions({
    component: 'export_key_figures',
    plan: accountStatusQuery.data?.subscription_plan,
  })

  const snakeToCamel = (str) =>
    str.replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', '')
    )

  const order = [
    'revenue',
    'gross_result',
    'staff_expenses',
    'ebitda',
    'depreciation',
    'ebit',
    'interest_income',
    'interest_expenses',
    'other_net_financial_income',
    'ordinary_profit',
    'tax_expenses',
    'profit_loss',
    'intangible_assets',
    'property_plant_and_equipment',
    'noncurrent_financial_assets',
    'noncurrent_assets',
    'inventories',
    'other_short_term_receivables',
    'short_term_receivables_from_sales_and_services',
    'cash',
    'current_financial_assets',
    'current_assets',
    'assets',
    'contributed_capital',
    'retained_earnings',
    'reserves',
    'dividend',
    'equity',
    'provisions',
    'long_term_debt',
    'short_term_debt_to_banks',
    'short_term_trade_payables',
    'short_term_tax_payables',
    'other_short_term_debt',
    'short_term_debt',
    'debt',
    'liabilities_and_equity',
  ]

  const getFileName = (companyName) => {
    return `${reactIntl.messages[intl.keyFinancialFigures('title')]} for ${companyName
      ?.replace('/', '')
      ?.replace('?', '')
      ?.replace('*', '')
      ?.replace('[', '')
      ?.replace(']', '')}`
  }

  const financialNumbers = take(4, data.financialNumbers ?? [])
  const handlePeriodsForKey = (key) => {
    const periodObject = {}
    financialNumbers?.forEach((numbers) => {
      Object.assign(periodObject, {
        [`${moment(numbers.period.start).format('DD.MM.YYYY')} - ${moment(
          numbers.period.end
        ).format('DD.MM.YYYY')}`]: reactIntl.formatNumber(numbers[key]),
      })
    })
    return periodObject
  }

  const handleClickExcell = () => {
    const download = downloadAs('excel')
    const body = order.map((key) => ({
      '': reactIntl.messages[intl.keyFinancialFigures(snakeToCamel(key))],
      ...handlePeriodsForKey(key),
    }))

    download(getFileName(data?.company_name), body)
  }

  const handleClickPDF = () => {
    const head = financialNumbers.map(
      (x) =>
        `${moment(x.period.start).format('DD.MM.YYYY')} - ${moment(x.period.end).format(
          'DD.MM.YYYY'
        )}`
    )

    const body = order.map((key) => [
      reactIntl.messages[intl.keyFinancialFigures(snakeToCamel(key))],
      ...financialNumbers.map((x) => reactIntl.formatNumber(x[key])),
    ])

    const doc = new JsPDF()

    doc.autoTable({
      head: [['', ...head]],
      body: body,
      headStyles: { fillColor: colors?.risika },
    })

    doc.save(getFileName(data.company_name))
  }

  return (
    <Tooltip
      placement="top"
      title={
        disabled ? (
          <FormattedMessage id={intl.restriction('create-new-list-content')} />
        ) : (
          ''
        )
      }
    >
      <Stack>
        <Button
          onClick={handleClickExcell}
          disabled={disabled}
          style={{ flex: 1, marginRight: 'auto' }}
          icon={
            <FontAwesomeIcon
              style={{ marginLeft: '1.5rem' }}
              icon={disabled ? faLock : faFileExcel}
            />
          }
        >
          <FormattedMessage id={intl.keyFinancialFigures('export-excel')} />
        </Button>
        <Button
          onClick={handleClickPDF}
          disabled={disabled}
          style={{ flex: 1, marginRight: 'auto' }}
          icon={
            <FontAwesomeIcon
              style={{ marginLeft: '1.5rem' }}
              icon={disabled ? faLock : faFilePdf}
            />
          }
        >
          <FormattedMessage id={intl.keyFinancialFigures('export-pdf')} />
        </Button>
      </Stack>
    </Tooltip>
  )
}

export default ExportFinancialFigures
