export const env = {
  RISIKA_API_VERSION: process.env.REACT_APP_RISIKA_API_VERSION,
  RISIKA_API_BASE_URL: process.env.REACT_APP_RISIKA_API_BASE_URL,
  BACKEND_API_BASE_URL: process.env.REACT_APP_BACKEND_API_BASE_URL,
  RISIKA_API_ACCESS_TOKEN: process.env.REACT_APP_RISIKA_API_ACCESS_TOKEN,
  AUTH_REFRESH_TOKEN: process.env.REACT_APP_AUTH_REFRESH_TOKEN,
  AUTH_ACCESS_TOKEN: process.env.REACT_APP_AUTH_ACCESS_TOKEN,
  GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  PROFF_REPORT_API_BASE_URL: process.env.REACT_APP_PROFF_REPORT_API_BASE_URL,

  AIR_TABLE_BASE_ID: process.env.REACT_APP_AIR_TABLE_BASE_ID,
  AIR_TABLE_TABLE_NAME: process.env.REACT_APP_AIR_TABLE_TABLE_NAME,
  AIR_TABLE_AUTH_TOKEN: process.env.REACT_APP_AIR_TABLE_AUTH_TOKEN,
  FRAUD_MVP_DEMO_ENV: process.env.REACT_APP_FRAUD_MVP_DEMO_ENV,
}
